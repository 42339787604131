#c-employeesTimeTable{

  .c-list__header{
    .filter__wrapper{
      min-width: auto;
      width: auto;

      .o-label{
        width: 70px;
      }
    }

    .o-input-search{
      width: 230px;
    }
    
    
  }

  #timeTableType-select{
    .o-label{
      display: none;
    }
  }
  
  .timeTable__changeDate{
    &__wrapper{
      display: flex;
      margin: 0 30px;
    }
    &__date{
      font-family: $main-font;
      font-size: remy(18);
      font-weight: 500;
      color: #3D3D3D;
      margin:0 5px;
    }
    &--prev, &--next{
      font-family: $main-font;
      font-size: remy(18);
      font-weight: bold;
      line-height: 0.8;
      background: none;
      border: none;
      cursor: pointer;
      text-align: center;
      outline: none;
    }
    &--prev{

    }

    &--next{

    }


  }
}