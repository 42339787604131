.c-person-msg{
    position: relative;
    margin: 0 10px;


    .ico-msg{
        font-size: 21px;
    }

    .o-dropdown__wrapper{
        width: 330px;
        transform: translateX(-50%);
        top: 30px;
    }

    .person-msg__list{
        .person-msg__item{
            border-bottom: 1px solid rgba(158,143,190,.57);
            padding: 10px;
            font-weight: 500;
            cursor: pointer;
            flex-wrap: wrap;
            position: relative;

            &:hover{
                background-color: #FAFAFA;
            }
        }

        .notification__titile{
            font-size: remy(14);
            width: 100%;
        }

        .notifictaion__msg{
            font-size: remy(13);
            font-weight: normal;
            margin-top: 5px;
        }

        .notifictaion__time{
            font-size: remy(12);
            font-weight: normal;
            margin-top: 5px;
            position: absolute;
            right: 10px;
            top: 26px;
        }

        .notification__unread{
            background-color: #ede9fd;
        }
    }

    .new-message{
        position: absolute;
        right: 12px;
        background-color: $main-color;
        width: 22px;
        height: 22px;
        top: 6px;
        font-size: remy($content-small);
    }
}
