.timeTable__content__wrapper{

  .c-timeTable__grid {
    .timeTable__grid-item {
      .item__block {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        border-radius: 4px;
        width: 90%;
        height: 85%;
        align-items: center;
        line-height: 1.2;
        overflow: hidden;

        &--free {
          background-color: #BEF1BB;
          color: #458545;
        }

        &--work {
          color: #D33F55;
          background-color: #FCC5D0;;
        }

        .block__handle{
          position: relative;
          width: 11px;
          flex: 0 0 11px;
          height: 100%;
          cursor: url('https://www.google.com/intl/en_ALL/mapfiles/openhand.cur'), all-scroll;
          cursor: -webkit-grab;
          cursor: -moz-grab;
          cursor: -o-grab;
          cursor: -ms-grab;
          cursor: grab;

          &::before{
            content: '.';
            position: absolute;
            left: 3px;
            font-size: 20px;
            line-height: 7px;
            color: black;
            text-shadow :0 4px black, 0 8px black, 5px 0 black, 5px 4px black, 5px 8px black;
            opacity: 0.4;
          }

          &:active{
            cursor: url('https://www.google.com/intl/en_ALL/mapfiles/closedhand.cur'), all-scroll;
            cursor: -webkit-grabbing;
            cursor: -moz-grabbing;
            cursor: -o-grabbing;
            cursor: -ms-grabbing;
            cursor: grabbing;
          }


        }

        .block__text__wrapper{
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          flex-grow: 1;
        }
      }
    }
  }
}