.c-form-popup{
    position: relative;
    width: 100%;
    padding: 0 40px;

    .c-input__wrapper{
        display: flex;
        flex-wrap: wrap;
    }

    .o-label{
        width: 40%;
        font-size: remy($aside-content-regular);
        color: $label-color;
        font-weight: 500;
        padding-top: 10px;
        text-align: left;
    }

    .o-input{
        width: 60%;
        color: $input-text-color;

        &::placeholder{
            color: $placeholder-color;
        }
    }

    .c-select__wrapper{
        .o-label.is-hide{
            display: block;
        }

    }
    .c-select__list__wrapper{
        min-width: 60%;
        left: 100%;
        top: auto;
        bottom: 20px;
        transform: translateX(-100%) translateY(100%);
    }
    .c-input-search__wrapper::after{
        right: 20px;
    }

    label.error{
        width: 100%;
        text-align: right;
    }
}