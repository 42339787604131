.l-ticket-scanner, #ticketScanner{

  .c-main-container{
    display: flex;

    @media(max-width: $mobile) {
      display: block;
    }
  }

  .buttons__wrapper{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    button{
      margin: 10px;
      order: 2;
    }

    p{
      order: 1;
      width: 100%;
      padding: 15px;
      text-align: center;
    }

    @media(max-width: $mobile){
      border-top: 1px solid $background-menu;
      justify-content: space-between;
      flex-wrap: nowrap;
      min-height: 35px;

      .o-button{
        background-color: transparent;
        color: $text-color;
        width: 100%;
        border-radius: 0 0 0 20px;
        margin: 0;

        &:first-child {
          border-right: 1px solid $background-menu;
        }

      }
    }
  }

  #ticketScanner {
    .c-input__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      //margin-top: 35px;

      .o-input {
        width: 85%;
        max-width: 900px;

        @media(max-width: $mobile) {
          position: fixed;
          top: 83px;
          margin-top: 0;
        }
      }
      @media(max-width: $mobile) {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
      }
    }

    #qr-video{
    width: 600px;
    height: 400px;
    border: 1px solid $border-light;

      @media(max-width: $mobile) {
        width: 100%;
        height: 250px !important;
      }
    }
}

.scanner__wrapper{
  position: relative;
  display: block;
  border: 1px solid var(--font-color, $text-color);
  border-radius: 4px;
  width: 90%;
  max-width: 350px;
  margin: 0 auto;
  margin-top: 35px;

  @media(max-width: $mobile) {
    margin: 10px auto;
    width: 90%;
    height: 50%;
  }

}

}

#ticketScanner{

  .c-content__wrapper{
    @media(max-width: $mobile){
    padding: 0;

      .c-content{
      height: auto;

      }
    }
  }

@media(max-width: $mobile){
flex-direction: column;
max-height: 100%;
padding: 0;
border: 0.5px solid $border-light;
box-sizing: border-box;
box-shadow: $light-box-shadow;
border-radius: 9px;
margin: 10px;
margin-top: 18px;
}
}

.mobile-check-ticket{
height: 50px;
width: 100%;
background-color: $background-menu;
position: fixed;
top: 82px;

  @media(min-width: $mobile){
    display: none;
  }
}

.reservation--msg{

  color: $error-color;
  display: flex;
  justify-content: center;
  margin: 10px 0;
  font-size: 14px;
  height: 15px;

  @media(max-width: $mobile){
    width: 100%;
    text-align: center;
    padding-top: 66px;
    font-size: 12px;

  }

}
