.legend{
  .chart-legend{
    margin-top: 20px;
    display: flex;
    margin-left: 50px;

    li{
      margin-right: 70px;

      .legendValue{
        display: flex;
        align-items: center;

        .legend-pin{
          width: 20px;
          height: 20px;
          border-radius: 2px;
          margin-right: 12px;
        }
        .legend-label{
          font-size: 14px;
          color: $text-color;
        }
      }
      @media(max-width: $tablet){
        margin-right: 20px;
      }
    }
    @media(max-width: $tablet){
      flex-wrap: wrap;
      margin-top:0;
    }
  }
}