.progress-circle {
  width: 80px;
  height: 80px;
  position: absolute;
  right: 5%;
  bottom: 5%;

  .progress {
    width: inherit;
    height: inherit;

    .track, .fill {
      fill: rgba(0, 0, 0, 0);
      stroke-width: 7;
      transform: rotate(90deg) translate(0px, -80px);
    }

    .track {
      stroke: $background-menu;
    }

    .fill {
      stroke: $main-color;
      stroke-dasharray: 219.99078369140625;
      stroke-dashoffset: -219.99078369140625;
      transition: stroke-dashoffset 1s;
    }

    .value {
      fill: $main-color;
      text-anchor: middle;
      font-size: 21px;
      line-height: 28px;
    }
  }

  @media(max-width: $tablet){
    width: 50px;
    height: 50px;
  }

  @media(min-width: $tablet+1) and (max-width: $desktop-l){
    width: 60px;
    height: 60px;
    bottom: 13px;
  }
}