.aside{

    &__wrapper{
        position: fixed;
        z-index: 999;
        top: 0;
        right: -550px;
        width: 550px;
        background: white;
        border-left: 1px solid #CBCBCB;
        height: 100Vh;
        box-shadow: 2px 3px 23px 0 #C9C3D3;
        transition: .2s ease-out;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;

        &--big{
            width: 50%;
            min-width: 960px;
            align-content: flex-start;

            .aside__content{
                height: 80%;
                max-height: 80%;
            }
        }

        @media (max-width: $mobile) {
            position: relative;
            height: auto;
            z-index: 998;
            //width: 100%;
            width: calc(100% - 20px);
            top: 10px;
            left: 10px;
            margin-bottom: 20px;
            border-radius: 9px;
        }
    }

    &__header{
        border-bottom: 1px solid #D9D7E0;
        padding: 30px 40px;
        width: 100%;

        .o-backButton{
            position: relative;
            color: $text-color;
            color: var(--font-color, $text-color);
            font-size: remy($content-small);
            font-weight: 500;
            padding-left: 25px;

            .ico{
                width: 20px;
                position: absolute;
                left: 0;
                top: 0;
                transform: rotate(180deg);

                &:before{
                    width: 11px;
                }
            }

            &:hover{
                color: $main-color;
                color: var(--main-color, $main-color);
            }
        }

        .aside__title{
            font-size: remy($aside-header);
            color: $text-color;
            color:  var(--font-color, $text-color);
            margin-top: 25px;
            cursor: default;

            @media(max-width: $mobile){
                margin-top: 0;
                font-size: 16px;
            }
        }

        @media(max-width: $mobile){
            padding: 20px 27px;
        }
    }

    &__content{
        padding: 30px 35px;
        margin: 0 5px;
        width: 100%;
        height: 70%;
        max-height: 70%;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;

        .add-parkForm{
            width: 60%;
            left: 100%;
            position: relative;
            transform: translateX(-100%);
        }

       .addParkForm > .parkForm__wrapper{
            border-top: 0;
            padding-top: 0;

            & ~ .parkForm__wrapper{
                border-top: 1px solid #D9D7E0;
                padding-top: 15px;
            }
        }

        .aside__title{
            position: relative;
            display: inline-block;
            font-size: remy($aside-header-small);
            font-weight: 500;
            color: $text-color;
            color:  var(--font-color, $text-color);
            margin: 25px 0;
            padding-right: 10px;
            z-index: 200;
            background-color: white;
        }
        .u-separator{
            top: 32px;
        }

        span.error{
            width: 100%;
            text-align: right;
        }
      .error__wrapper::after{
        top: 12px;
      }

        .ticket__wrapper{
            flex-direction: column;
            align-items: center;

            img{
                //position: relative;
                //left: 50%;
                //transform: translateX(-50%);
                max-width: 200px;
                max-height: 200px;
            }
            span{
                color: $text-color;
            }
        }

        @media (max-width: $mobile){
            padding: 11px 27px;
            margin: 0;
        }


    }

    &__footer{
        height: 100px;
        width: 100%;
        padding: 30px 40px;

        .button__wrapper{
            justify-content: flex-end;

            @media (max-width: $mobile){
                width: 100%;
                min-height: 35px;
                border-top: 1px solid $background-menu;
            }

        }
        .o-button{
            margin-left: 25px;

            &--light {
                @media (max-width: $mobile) {
                    border-right: 1px solid $background-menu !important;
                    border-radius: 0;
                }
            }

            @media (max-width: $mobile){
                width: 100%;
                margin: 0;
                border: 0;
                min-height: 35px;
                font-size: 13px;
                color: #685E80;
                background-color: transparent;
            }

        }

        @media (max-width: $mobile){
            padding: 0;
            display: flex;
            align-items: flex-end;
            height: unset;
        }
    }


    //&-mobile{
    //    width: 100%;
    //
    //    &__header{
    //        border-bottom: 0.5px solid #E7E3EE;
    //        padding: 20px 27px;
    //
    //        .m-header{
    //            font-size: 16px;
    //            line-height: 24px;
    //            color: #685E81;
    //        }
    //    }
    //    &__body{
    //        .c-input__wrapper{
    //            display: flex;
    //            flex-direction: column;
    //            margin: 10px 27px;
    //            padding: 0;
    //
    //            label{
    //                margin: 10px 0;
    //            }
    //        }
    //    }
    //}
}

.aside__nav{
    position: relative;
    margin-top: 30px;
    margin-bottom: -30px;

    &-item{
        display: inline-block;
        .o-button{
            font-size: remy($aside-header-small);
            padding-left: 0;
            padding-right: 0;

            &:focus{
                text-decoration: none;
            }

        }
        &:not(:last-child){
            margin-right: 60px;

        }
    }

    span.link__border{
        position: absolute;
        left: 0;
        bottom: -1px;
        height: 3px;
        width: 38px;
        background-color: $main-color;
        background-color: var(--main-color, $main-color);
        transition: .2s ease-in-out;
    }
}

.table__legend{
    align-items: center;

    label{
        padding-top: 0;

    }

    .item{
        display: block;
        border-radius: 8px;
        width: 90px;
        height: 18px;

        &--free{
            background-color: #98D794;
        }
        &--low{
            background-color: #F9B661;
        }
        &--full{
            background-color: #e1dfe6;
        }
        &--semifree{
            background-color: #f9ea68;
        }
        &--warning{
            background-color: $error-color;
        }
        &--block{
            background-color: #000;
        }

        &--checked{
            background-color: $main-color;
            background-color: var(--main-color, $main-color);
        }

    }
}

.mobile-hide{
    @media(max-width: $mobile){
        display: none !important;
    }
}

.desktop-hide{
    @media(max-width: $desktop){
        display: none !important;
    }
}

.tablet-hide{
    @media (min-width: $mobile) and (max-width: $desktop) {
        display: none !important;
    }
}

.show-desktop{
    @media(min-width: $mobile){
        display: none !important;
    }
}