#shopList {
    .c-list__grid {
        &--col-seven {
            .c-list__grid-item {
                &.grid-item {
                    &--1 {
                        width: 16%;
                    }

                    &--2 {
                        width: 12%;
                    }

                    &--3 {
                        width: 12%;
                    }

                    &--4 {
                        width: 20%;
                    }

                    &--5 {
                        width: 20%;
                    }

                    &--6 {
                        width: 10%;
                    }

                    &--7 {
                        max-width: 150px;
                    }
                }
            }
        }
    }
    .c-list__content-item__wrapper{
        &.lvl2{
            .c-list__grid--col-seven{
                .grid-item--1{
                    width: 14%;
                }
                .grid-item--2{
                    width: 12%;
                }
                .grid-item--3{
                    width: 12%;
                }
                .grid-item--4{
                    width: 20.5%;
                }
                .grid-item--5{
                    width: 20.2%;
                }
                .grid-item--6{
                    width: 10.2%;
                }
            }
        }
    }
}

