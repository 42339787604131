.chart-container {

  .team-block {
    @media (max-width: $mobile) {
      flex-direction: column;
      padding: 0 10px;

      .l-block--1-3{
        width: 100%;

        .c-content__wrapper{
          height: auto;

          .c-content{
            width: auto !important;

            .content__header{
              flex-direction: column;
              align-items: flex-start;
              margin-bottom: 0;
            }
            .chart-list {
              padding-left: 15px;
            }
          }
        }
      }
      .l-block--2-3{
        width: 100%;

        .d-flex{
          width: calc(100% - 20px);
          margin-left: 10px;
        }
      }


    }
    @media (min-width: $mobile+1) and (max-width: $desktop) {
      margin-bottom: 10px;
      flex-direction: column;
      .l-block--1-3{
        width: 100%;

        .c-content{
          margin: 0;
          padding: 10px;
        }
      }
      .l-block--2-3{
        width: 100%;

        .c-content{
          margin: 0;
          padding: 10px;
        }
      }
    }
  }

  .worker-block {
    @media (max-width: $desktop) {
      flex-direction: column;

      .l-block:nth-child(1) .c-content__wrapper{
        margin-top: 0;
      }
    }
    @media(max-width: $mobile){
      padding: 10px 10px 0;
    }
  }

  .team-block-chart {
    @media (min-width: $desktop+1) and (max-width: $desktop-l){
      flex-direction: row-reverse;
        .l-block--1-4{
          width: 25% !important;
        }
      .l-block--3-4x{
        .l-block{
          height: 100%;

          .c-content{
            height: 100%;
          }
        }
        .c-content__wrapper{
          //height: 297px !important;
        }
      }
    }
    @media (min-width: $desktop-l){
      .l-block--3-4x{
        .l-block{
          height: 100%;

          .c-content{
            height: 100%;
          }
        }
      }
    }
    @media (max-width: $desktop){
      flex-direction: column-reverse;

      .l-block--1-4{
        width: 100% !important;

        .c-customers-count{
          margin-bottom: 10px;
        }
      }
      .l-block--3-4x{
        width: 100%;

        .c-content__wrapper{
          margin-top: 0;

          .c-content{
            margin: 0;
          }
        }
      }
    }
    @media(max-width: $mobile){
      padding: 10px 10px 0;

      .team-chart{
        padding: unset;
      }
    }
    @media(min-width: $mobile) and (max-width: $desktop){
      .l-block--1-4{
        flex-direction: row;
        .c-customers-count:first-child{
          margin-right: 10px;
        }
      }
    }
  }

  .client-block{
    @media (max-width: $desktop) {
      .c-content {
        overflow-x: auto;
        overflow-y: hidden;
      }
      .chart__wrapper {
        width: 1100px !important;
      }
    }
    @media(max-width: $mobile){
      padding: 0 10px;
    }
  }

  .mainPage-chart{
    @media (max-width: $desktop) {
      .c-content{
        overflow-x: auto;
        overflow-y: hidden;

        .chart__wrapper{
          width: 1100px !important;
        }
      }
    }

    @media(min-width: $mobile+1) and (max-width: $desktop){
      .c-content{
        margin: 0;
      }
    }
  }

  .zone-chart{
    @media (max-width: $desktop) {
      .c-content{
        overflow-x: auto;
        overflow-y: hidden;

        .chart__wrapper{
          width: 1100px !important;
        }
      }
    }
    @media (max-width: $mobile) {
      padding: 0 10px;
    }
  }

  .user-chart{
    @media (max-width: $tablet) {
      .c-content{
        overflow-x: auto;
        overflow-y: hidden;

        .chart__wrapper{
          width: 1100px !important;
        }
      }
    }
    @media(min-width: $mobile+1) and(max-width: $desktop){
      .chartLib-block{
        margin: 0;
      }
    }
    @media(max-width: $mobile){
      padding: 0 10px;
    }

  }

  .team-chart{
    @media (max-width: $desktop) {
      .c-content__wrapper{
        height: unset !important;
      }
      .c-content{
        overflow-x: auto;
        overflow-y: hidden;

        .chart__wrapper{
          width: 1100px !important;
        }
      }
    }
    @media (max-width: $mobile){
      padding: 0 10px;
    }
  }

  .mainPage-chart{
    @media (max-width: $mobile) {
      .c-content{
        overflow-x: auto;
        overflow-y: hidden;
        margin: 0 10px;

        .chart__wrapper{
          width: 1100px !important;
        }
      }
    }
  }

  .user-block {
    @media (max-width: $mobile) {
      flex-direction: column;

      .left-side, .right-side{
        width: 100%;
      }

      .left-side{
        margin-top: 10px;
        .chart-space{
          padding: 0 10px;
        }
        .c-customers-count{
          margin: 10px;
          width: calc(100% - 20px);
        }
        .l-block{
          .c-content__wrapper{
            margin-top: 0;
            height: auto!important;

            .content__header{
              margin-bottom: 0;
            }
          }
        }

      }

      .right-side{
        .c-content__wrapper{
          height: auto!important;
          margin-top: 0;

          .content__header{
            margin-bottom: 0;
          }
        }
      }
    }
    @media (min-width: $mobile+1) and (max-width: $desktop){
      flex-direction: column;
      .left-side{
        width: 100%;
        margin-right: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;

        .tiles-space{
          height: 200px;
          .c-customers-count{
            height: 100%;

            .c-content{
              height: 100%;
            }
          }
        }
        .chart-space{
          .c-content__wrapper{
            height: 180px !important;
            margin-top: 0;
          }
          .chartLib-block{
            margin-top: 0;
            margin-bottom: 0;

            .chart__wrapper{
              margin-bottom: 50px;
            }
          }
        }

      }
      .right-side{
        width: 100%;

        .c-content__wrapper{
          height: unset !important;
          margin-top: 0;
        }
      }
    }
    @media (min-width: $desktop+1) and (max-width: $desktop-l){
      .right-side{
        .c-content__wrapper{
          height: 402px !important;
        }
      }
      .left-side{
        .c-content{
          padding: 0;
        }
      }
    }
    @media (min-width: $desktop-l){
      .left-side{
        .c-content{
          padding: 0;
        }
      }
    }
  }

}