.c-notification__wrapper{
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  z-index: 9999;
  flex-direction: row-reverse;

  .notification-box{
    display: flex;
    align-items: center;
    width: 250px;
    min-height: 70px;
    box-shadow: 0 3px 14px 0 rgba(158,143,190,.57);
    border: 1px solid #cbcbcb;
    border-left-width: 10px;
    border-radius: 9px;
    padding: 10px;
    transition: .45s ease-in-out;
    z-index: 9999;
    transform: translateX(100%);
    opacity: 0;
    background-color: $background-content;
    color: $text-color;
    font-weight: bold;

    &.error{
      border-left-color: $error-color;
      color: $error-color;
    }
    &.success{
      border-left-color: $success-color;
    }
    &.info{
      border-left-color: #2B81AF;
    }

  }
}