.c-input__wrapper{
    margin-bottom: 5px;
    padding-bottom: 20px;
    //height: 70px;
}

.adds__wrapper{
    .c-input__wrapper{
        height: 100%;
    }
}