#fastOrder{
  .c-list__header{
    padding-top: 25px;

    @media(max-width: 1440px){
      padding: 10px 20px;
    }
  }

  .visited-park, .promotion-park{
    padding: 0 25px 25px;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;

    @media(min-width: 850px) and (max-width: 1000px){
      grid-template-columns: 33% 33% 33%;
    }
    @media(min-width: 650px) and (max-width: 850px){
      grid-template-columns: 50% 50%;
    }
    @media(max-width: 650px){
      grid-template-columns: 100%;
    }
  }

  .header-option{
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #b4afc1;

    .option-block{
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 10px 0;
      cursor: pointer;

      .option-text{
        font-size: 16px;
      }

      &.is-active{
        background-color: var(--main-color,rgba(111,54,129,.99));
        .option-text{
          color: var(--menu-color,#fff);
        }
      }

      @media(max-width: 768px){
        padding: 7px 0;

        .option-text{
          font-size: 14px;
        }
      }
    }
  }

  .c-list__content-item__wrapper{

    .c-list__content-item{
      flex-direction: column;
      max-height: 100%;
      justify-content: center;
      align-items: center;

      &:hover{
        background-color: #fff;
      }

      .c-list__grid-item{
        width: 100%;
        padding-bottom: 0;
        justify-content: center;
        align-items: center;
        border-bottom: none;
        margin-bottom: 5px;

        &.grid-item--1{
          height: 150px;
          border-radius: 25px;
          border: 1px solid $border-light;

          .content-picture{
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
          }

          .no-photo{
            justify-content: center;
            font-size: 18px;

            &:before{
              color: $border-light;
            }
          }
          @media(max-width: 768px){
            height: 130px;
          }

        }

        .content-title{
          font-size: 14px;
          height: 16px;
        }
      }

      @media(max-width: 768px){
        padding-top: 0;

        .c-list__grid-item{
          &.grid-item--1{
            border-radius: unset;
            border: none;

            >div{
              border-radius: 9px;
            }
          }

          p{
            left: unset;
          }

          &:before{
            content: '';
          }
        }
      }
    }
  }
}