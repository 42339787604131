.thumbnail__modal__wrapper{
  position: absolute;
  width: 600px;
  height: 400px;
  bottom: 0;
  right: 0;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 100;
  transition: .3s ease-in-out;
  cursor: pointer;

  &.toggle-hide:hover{
    transform: translateX(-20px);
  }
}