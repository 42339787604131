#reservationCurrentList {
  .c-list__grid{

    .grid-item--8 {

      @media (max-width: $mobile){
        margin-top: 0;

        &:before{
          content: "Status";
        }
      }

      .small-status {
        font-size: 0;

        @media (max-width: $mobile){
          font-size: unset;
          height: auto;
          width: auto;
          padding: 2px 7px;
          color: #FFF;
        }
      }
    }

    .grid-item--9 {

      @media (max-width: $mobile){
        padding-bottom: 0;
        padding-top: 10px;
      }

      .c-content-item-options__wrapper{
        justify-content: center;
        font-size: 0;
      }
    }
  }

  .finishOrder{
    .paid{
      cursor: default;
    }
  }
}