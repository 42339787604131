
/*----------------

 flatpickr

------------- */
.flatpickr-calendar {
    right: 0;
    background: transparent;
    opacity: 0;
    display: none;
    text-align: center;
    visibility: hidden;
    padding: 0;
    -webkit-animation: none;
            animation: none;
    direction: ltr;
    border: 0;
    font-size: 11px;
    line-height: 24px;
    border-radius: 5px;
    position: absolute;
    width: 230px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -ms-touch-action: manipulation;
        touch-action: manipulation;
    background: #fff;
    -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0,0,0,0.08);
            box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0,0,0,0.08);
  }
  .flatpickr-calendar.open,
  .flatpickr-calendar.inline {
    opacity: 1;
    max-height: 640px;
    visibility: visible;
  }
  .flatpickr-calendar.open {
    display: inline-block;
    z-index: 99999;
  }
  .flatpickr-calendar.animate.open {
    -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
            animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .flatpickr-calendar.inline {
    display: block;
    position: relative;
    top: 2px;
  }
  .flatpickr-calendar.static {
    position: absolute;
    top: calc(100% + 2px);
  }
  .flatpickr-calendar.static.open {
    z-index: 1001;
    display: block;
  }
  .flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
    -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
            box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  }
  .flatpickr-calendar .hasWeeks .dayContainer,
  .flatpickr-calendar .hasTime .dayContainer {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .flatpickr-calendar .hasWeeks .dayContainer {
    border-left: 0;
  }
  .flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
    height: 40px;
    border-top: 1px solid #e6e6e6;
  }
  .flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
    height: auto;
  }
  .flatpickr-calendar:before,
  .flatpickr-calendar:after {
    position: absolute;
    display: none;
    pointer-events: none;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    left: 22px;
  }
  .flatpickr-calendar.rightMost:before,
  .flatpickr-calendar.rightMost:after {
    left: auto;
    right: 22px;
  }
  .flatpickr-calendar:before {
    border-width: 5px;
    margin: 0 -5px;
  }
  .flatpickr-calendar:after {
    border-width: 4px;
    margin: 0 -4px;
  }
  .flatpickr-calendar.arrowTop:before,
  .flatpickr-calendar.arrowTop:after {
    bottom: 100%;
  }
  .flatpickr-calendar.arrowTop:before {
    border-bottom-color: #e6e6e6;
  }
  .flatpickr-calendar.arrowTop:after {
    border-bottom-color: #fff;
  }
  .flatpickr-calendar.arrowBottom:before,
  .flatpickr-calendar.arrowBottom:after {
    top: 100%;
  }
  .flatpickr-calendar.arrowBottom:before {
    border-top-color: #e6e6e6;
  }
  .flatpickr-calendar.arrowBottom:after {
    border-top-color: #fff;
  }
  .flatpickr-calendar:focus {
    outline: 0;
  }
  .flatpickr-wrapper {
    position: relative;
    display: inline-block;
    //z-index: 2;
  }
  .flatpickr-months {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .flatpickr-months .flatpickr-month {
    background: transparent;
    color: rgba(0,0,0,0.9);
    fill: rgba(0,0,0,0.9);
    height: 28px;
    line-height: 1;
    text-align: center;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    overflow: hidden;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .flatpickr-months .flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month {
    text-decoration: none;
    cursor: pointer;
    position: absolute;
    top: 0px;
    line-height: 16px;
    height: 28px;
    padding: 10px;
    z-index: 3;
    color: rgba(0,0,0,0.9);
    fill: rgba(0,0,0,0.9);
  }
  .flatpickr-months .flatpickr-prev-month.disabled,
  .flatpickr-months .flatpickr-next-month.disabled {
    display: none;
  }
  .flatpickr-months .flatpickr-prev-month i,
  .flatpickr-months .flatpickr-next-month i {
    position: relative;
  }
  .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
    left: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
  }
  /*
        /*rtl:begin:ignore*/
  /*
        /*rtl:end:ignore*/
  .flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
  .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
    right: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
  }
  /*
        /*rtl:begin:ignore*/
  /*
        /*rtl:end:ignore*/
  .flatpickr-months .flatpickr-prev-month:hover,
  .flatpickr-months .flatpickr-next-month:hover {
    color: #959ea9;
  }
  .flatpickr-months .flatpickr-prev-month:hover svg,
  .flatpickr-months .flatpickr-next-month:hover svg {
    fill: #f64747;
  }
  .flatpickr-months .flatpickr-prev-month svg,
  .flatpickr-months .flatpickr-next-month svg {
    width: 14px;
    height: 14px;
  }
  .flatpickr-months .flatpickr-prev-month svg path,
  .flatpickr-months .flatpickr-next-month svg path {
    -webkit-transition: fill 0.1s;
    transition: fill 0.1s;
    fill: inherit;
  }
  .numInputWrapper {
    position: relative;
    height: auto;
  }
  .numInputWrapper input,
  .numInputWrapper span {
    display: inline-block;
  }
  .numInputWrapper input {
    width: 100%;
  }
  .numInputWrapper input::-ms-clear {
    display: none;
  }
  .numInputWrapper span {
    position: absolute;
    right: 0;
    width: 14px;
    padding: 0 4px 0 2px;
    height: 50%;
    line-height: 50%;
    opacity: 0;
    cursor: pointer;
    border: 1px solid rgba(57,57,57,0.15);
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .numInputWrapper span:hover {
    background: rgba(0,0,0,0.1);
  }
  .numInputWrapper span:active {
    background: rgba(0,0,0,0.2);
  }
  .numInputWrapper span:after {
    display: block;
    content: "";
    position: absolute;
  }
  .numInputWrapper span.arrowUp {
    top: 0;
    border-bottom: 0;
  }
  .numInputWrapper span.arrowUp:after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid rgba(57,57,57,0.6);
    top: 26%;
  }
  .numInputWrapper span.arrowDown {
    top: 50%;
  }
  .numInputWrapper span.arrowDown:after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid rgba(57,57,57,0.6);
    top: 40%;
  }
  .numInputWrapper span svg {
    width: inherit;
    height: auto;
  }
  .numInputWrapper span svg path {
    fill: rgba(0,0,0,0.5);
  }
  .numInputWrapper:hover {
    background: rgba(0,0,0,0.05);
  }
  .numInputWrapper:hover span {
    opacity: 1;
  }
  .flatpickr-current-month {
    font-size: 135%;
    line-height: inherit;
    font-weight: 300;
    color: inherit;
    position: absolute;
    width: 75%;
    left: 12.5%;
    padding: 6.16px 0 0 0;
    line-height: 1;
    height: 28px;
    display: inline-block;
    text-align: center;
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
  }
  .flatpickr-current-month span.cur-month {
    font-family: inherit;
    font-weight: 500;
    color: inherit;
    display: inline-block;
    margin-left: 0.5ch;
    padding: 0;
  }
  .flatpickr-current-month span.cur-month:hover {
    background: rgba(0,0,0,0.05);
  }
  .flatpickr-current-month .numInputWrapper {
    width: 6ch;
    width: 7ch\0;
    display: inline-block;
  }
  .flatpickr-current-month .numInputWrapper span.arrowUp:after {
    border-bottom-color: rgba(0,0,0,0.9);
  }
  .flatpickr-current-month .numInputWrapper span.arrowDown:after {
    border-top-color: rgba(0,0,0,0.9);
  }
  .flatpickr-current-month input.cur-year {
    background: transparent;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    color: inherit;
    cursor: text;
    padding: 0 0 0 0.5ch;
    margin: 0;
    display: inline-block;
    font-size: inherit;
    font-family: inherit;
    font-weight: 300;
    line-height: inherit;
    height: auto;
    border: 0;
    border-radius: 0;
    vertical-align: initial;
  }
  .flatpickr-current-month input.cur-year:focus {
    outline: 0;
  }
  .flatpickr-current-month input.cur-year[disabled],
  .flatpickr-current-month input.cur-year[disabled]:hover {
    font-size: 100%;
    color: rgba(0,0,0,0.5);
    background: transparent;
    pointer-events: none;
  }
  .flatpickr-weekdays {
    background: transparent;
    text-align: center;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    height: 28px;
  }
  .flatpickr-weekdays .flatpickr-weekdaycontainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  span.flatpickr-weekday {
    cursor: default;
    font-size: 90%;
    background: transparent;
    color: rgba(0,0,0,0.54);
    line-height: 1;
    margin: 0;
    text-align: center;
    display: block;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
    font-weight: bolder;
  }
  .dayContainer,
  .flatpickr-weeks {
    padding: 1px 0 0 0;
  }
  .flatpickr-days {
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 230px;
  }
  .flatpickr-days:focus {
    outline: 0;
  }
  .dayContainer {
    padding: 0;
    outline: 0;
    text-align: left;
    width: 230px;
    min-width: 230px;
    max-width: 230px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: inline-block;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }
  .dayContainer + .dayContainer {
    -webkit-box-shadow: -1px 0 0 #e6e6e6;
            box-shadow: -1px 0 0 #e6e6e6;
  }
  .flatpickr-day {
    background: none;
    border: 1px solid transparent;
    border-radius: 150px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    color: #393939;
    cursor: pointer;
    font-weight: 400;
    width: 14.2857143%;
    -webkit-flex-basis: 14.2857143%;
        -ms-flex-preferred-size: 14.2857143%;
            flex-basis: 14.2857143%;
    max-width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 0;
    display: inline-block;
    position: relative;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    margin: 3px 0;
  }
  .flatpickr-day.inRange,
  .flatpickr-day.prevMonthDay.inRange,
  .flatpickr-day.nextMonthDay.inRange,
  .flatpickr-day.today.inRange,
  .flatpickr-day.prevMonthDay.today.inRange,
  .flatpickr-day.nextMonthDay.today.inRange,
  .flatpickr-day:hover,
  .flatpickr-day.prevMonthDay:hover,
  .flatpickr-day:focus,
  .flatpickr-day.prevMonthDay:focus,
  .flatpickr-day.nextMonthDay:focus {
    cursor: pointer;
    outline: 0;
    background: #e6e6e6;
    border-color: #e6e6e6;
  }
  .flatpickr-day{
    &.item{
      border-radius: 7px;
      width: 100%;
      height: 100%;

      &--free{
        background-color: #98D794;
        border-color: #98D794;
      }
      &--low{
        background-color: #F9B661;
        border-color: #F9B661;
      }
      &--full{
      }
      &--semifree{
        background-color: #f9ea68;
        border-color: #f9ea68;
      }
      &--warning{
        background-color: #fd354b;
        border-color: #fd354b;
        color: white;
      }
      &--block{
        background-color: #212121;
        border-color: #212121;
        color: white;
      }
    }
  }
  .flatpickr-day.today {
    border-color: #959ea9;
  }
  .flatpickr-day.today:hover,
  .flatpickr-day.today:focus {
    border-color: #959ea9;
    background: #959ea9;
    color: #fff;
  }
  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    background: $main-color;
    background: var(--main-color, $main-color);
    -webkit-box-shadow: none;
            box-shadow: none;
    color: #fff;
    border-color: $main-color;
    border-color: var(--main-color, $main-color);
  }
  .flatpickr-day.selected.startRange,
  .flatpickr-day.startRange.startRange,
  .flatpickr-day.endRange.startRange {
    border-radius: 50px 0 0 50px;
  }
  .flatpickr-day.selected.endRange,
  .flatpickr-day.startRange.endRange,
  .flatpickr-day.endRange.endRange {
    border-radius: 0 50px 50px 0;
  }
  .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
  .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
  .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
    -webkit-box-shadow: -10px 0 0 $main-color;
            box-shadow: -10px 0 0 $main-color;
    -webkit-box-shadow: -10px 0 0 var(--main-color, $main-color);
    box-shadow: -10px 0 0 var(--main-color, $main-color);
  }
  .flatpickr-day.selected.startRange.endRange,
  .flatpickr-day.startRange.startRange.endRange,
  .flatpickr-day.endRange.startRange.endRange {
    border-radius: 50px;
  }
  .flatpickr-day.inRange {
    border-radius: 0;
    -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
            box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  }
  .flatpickr-day.disabled,
  .flatpickr-day.disabled:hover,
  .flatpickr-day.prevMonthDay,
  .flatpickr-day.nextMonthDay,
  .flatpickr-day.notAllowed,
  .flatpickr-day.notAllowed.prevMonthDay,
  .flatpickr-day.notAllowed.nextMonthDay {
    color: rgba(57,57,57,0.3);
    background: transparent;
    border-color: transparent;
    cursor: default;
  }
  .flatpickr-day.disabled,
  .flatpickr-day.disabled:hover {
    cursor: not-allowed;
    color: rgba(57,57,57,0.3);
    opacity: 1 !important;
  }
  .flatpickr-day.week.selected {
    border-radius: 0;
    -webkit-box-shadow: -5px 0 0 $main-color, 5px 0 0 $main-color;
            box-shadow: -5px 0 0 $main-color, 5px 0 0 $main-color;
    -webkit-box-shadow: -5px 0 0 var(--main-color, $main-color), 5px 0 0 var(--main-color, $main-color);
    box-shadow: -5px 0 0 var(--main-color, $main-color), 5px 0 0 var(--main-color, $main-color);
  }
  .flatpickr-day.hidden {
    visibility: hidden;
  }
  .rangeMode .flatpickr-day {
    margin-top: 1px;
  }
  .flatpickr-weekwrapper {
    display: inline-block;
    float: left;
  }
  .flatpickr-weekwrapper .flatpickr-weeks {
    padding: 0 12px;
    -webkit-box-shadow: 1px 0 0 #e6e6e6;
            box-shadow: 1px 0 0 #e6e6e6;
  }
  .flatpickr-weekwrapper .flatpickr-weekday {
    float: none;
    width: 100%;
    line-height: 28px;
  }
  .flatpickr-weekwrapper span.flatpickr-day,
  .flatpickr-weekwrapper span.flatpickr-day:hover {
    display: block;
    width: 100%;
    max-width: none;
    color: rgba(57,57,57,0.3);
    background: transparent;
    cursor: default;
    border: none;
  }
  .flatpickr-innerContainer {
    display: block;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    overflow: hidden;
  }
  .flatpickr-rContainer {
    display: inline-block;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    font-size: 12px;
  }
  .flatpickr-time {
    text-align: center;
    outline: 0;
    display: block;
    height: 0;
    line-height: 40px;
    max-height: 40px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .flatpickr-time:after {
    content: "";
    display: table;
    clear: both;
  }
  .flatpickr-time .numInputWrapper {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
    width: 40%;
    height: 40px;
    float: left;
  }
  .flatpickr-time .numInputWrapper span.arrowUp:after {
    border-bottom-color: #393939;
  }
  .flatpickr-time .numInputWrapper span.arrowDown:after {
    border-top-color: #393939;
  }
  .flatpickr-time.hasSeconds .numInputWrapper {
    width: 26%;
  }
  .flatpickr-time.time24hr .numInputWrapper {
    width: 49%;
  }
  .flatpickr-time input {
    background: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
    border: 0;
    border-radius: 0;
    text-align: center;
    margin: 0;
    padding: 0;
    height: inherit;
    line-height: inherit;
    color: #393939;
    font-size: 14px;
    position: relative;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .flatpickr-time input.flatpickr-hour {
    font-weight: bold;
  }
  .flatpickr-time input.flatpickr-minute,
  .flatpickr-time input.flatpickr-second {
    font-weight: 400;
  }
  .flatpickr-time input:focus {
    outline: 0;
    border: 0;
  }
  .flatpickr-time .flatpickr-time-separator,
  .flatpickr-time .flatpickr-am-pm {
    height: inherit;
    display: inline-block;
    float: left;
    line-height: inherit;
    color: #393939;
    font-weight: bold;
    width: 2%;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-align-self: center;
        -ms-flex-item-align: center;
            align-self: center;
  }
  .flatpickr-time .flatpickr-am-pm {
    outline: 0;
    width: 18%;
    cursor: pointer;
    text-align: center;
    font-weight: 400;
  }
  .flatpickr-time input:hover,
  .flatpickr-time .flatpickr-am-pm:hover,
  .flatpickr-time input:focus,
  .flatpickr-time .flatpickr-am-pm:focus {
    background: #f3f3f3;
  }
  .flatpickr-input[readonly] {
    cursor: pointer;
  }
  @-webkit-keyframes fpFadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -20px, 0);
              transform: translate3d(0, -20px, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  @keyframes fpFadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -20px, 0);
              transform: translate3d(0, -20px, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
/*----------------

 flatpickr end

------------- */




/*----------------

 noUIslider

------------- */

/*! nouislider - 12.0.0 - 9/14/2018 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
 .noUi-target,
 .noUi-target * {
   -webkit-touch-callout: none;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
   -webkit-user-select: none;
   -ms-touch-action: none;
   touch-action: none;
   -ms-user-select: none;
   -moz-user-select: none;
   user-select: none;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
 }
 .noUi-target {
   position: relative;
   direction: ltr;
 }
 .noUi-base,
 .noUi-connects {
   width: 100%;
   height: 100%;
   position: relative;
   z-index: 1;
 }
 /* Wrapper for all connect elements.
  */
 .noUi-connects {
   overflow: hidden;
   z-index: 0;
 }
 .noUi-connect,
 .noUi-origin {
   will-change: transform;
   position: absolute;
   z-index: 1;
   top: 0;
   left: 0;
   height: 100%;
   width: 100%;
   -ms-transform-origin: 0 0;
   -webkit-transform-origin: 0 0;
   transform-origin: 0 0;
 }
 /* Offset direction
  */
 html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
   left: auto;
   right: 0;
 }
 /* Give origins 0 height/width so they don't interfere with clicking the
  * connect elements.
  */
 .noUi-vertical .noUi-origin {
   width: 0;
 }
 .noUi-horizontal .noUi-origin {
   height: 0;
 }
 .noUi-handle {
   position: absolute;
 }
 .noUi-state-tap .noUi-connect,
 .noUi-state-tap .noUi-origin {
   -webkit-transition: transform 0.3s;
   transition: transform 0.3s;
 }
 .noUi-state-drag * {
   cursor: inherit !important;
 }
 /* Slider size and handle placement;
  */
 .noUi-horizontal {
   height: 18px;
 }
 .noUi-horizontal .noUi-handle {
   width: 34px;
   height: 28px;
   left: -17px;
   top: -6px;
 }
 .noUi-vertical {
   width: 18px;
 }
 .noUi-vertical .noUi-handle {
   width: 28px;
   height: 34px;
   left: -6px;
   top: -17px;
 }
 html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
   right: -17px;
   left: auto;
 }
 /* Styling;
  * Giving the connect element a border radius causes issues with using transform: scale
  */
 .noUi-target {
   background: #FAFAFA;
   border-radius: 4px;
   border: 1px solid #D3D3D3;
   box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
 }
 .noUi-connects {
   border-radius: 3px;
 }
 .noUi-connect {
   background: #3FB8AF;
 }
 /* Handles and cursors;
  */
 .noUi-draggable {
   cursor: ew-resize;
 }
 .noUi-vertical .noUi-draggable {
   cursor: ns-resize;
 }
 .noUi-handle {
   border: 1px solid #D9D9D9;
   border-radius: 3px;
   background: #FFF;
   cursor: default;
   box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
 }
 .noUi-active {
   box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
 }
 /* Handle stripes;
  */
 .noUi-handle:before,
 .noUi-handle:after {
   content: "";
   display: block;
   position: absolute;
   height: 14px;
   width: 1px;
   background: #E8E7E6;
   left: 14px;
   top: 6px;
 }
 .noUi-handle:after {
   left: 17px;
 }
 .noUi-vertical .noUi-handle:before,
 .noUi-vertical .noUi-handle:after {
   width: 14px;
   height: 1px;
   left: 6px;
   top: 14px;
 }
 .noUi-vertical .noUi-handle:after {
   top: 17px;
 }
 /* Disabled state;
  */
 [disabled] .noUi-connect {
   background: #B8B8B8;
 }
 [disabled].noUi-target,
 [disabled].noUi-handle,
 [disabled] .noUi-handle {
   cursor: not-allowed;
 }
 /* Base;
  *
  */
 .noUi-pips,
 .noUi-pips * {
   -moz-box-sizing: border-box;
   box-sizing: border-box;
 }
 .noUi-pips {
   position: absolute;
   color: #999;
 }
 /* Values;
  *
  */
 .noUi-value {
   position: absolute;
   white-space: nowrap;
   text-align: center;
 }
 .noUi-value-sub {
   color: #ccc;
   font-size: 10px;
 }
 /* Markings;
  *
  */
 .noUi-marker {
   position: absolute;
   background: #CCC;
 }
 .noUi-marker-sub {
   background: #AAA;
 }
 .noUi-marker-large {
   background: #AAA;
 }
 /* Horizontal layout;
  *
  */
 .noUi-pips-horizontal {
   padding: 10px 0;
   height: 80px;
   top: 100%;
   left: 0;
   width: 100%;
 }
 .noUi-value-horizontal {
   -webkit-transform: translate(-50%, 50%);
   transform: translate(-50%, 50%);
 }
 .noUi-rtl .noUi-value-horizontal {
   -webkit-transform: translate(50%, 50%);
   transform: translate(50%, 50%);
 }
 .noUi-marker-horizontal.noUi-marker {
   margin-left: -1px;
   width: 2px;
   height: 5px;
 }
 .noUi-marker-horizontal.noUi-marker-sub {
   height: 10px;
 }
 .noUi-marker-horizontal.noUi-marker-large {
   height: 15px;
 }
 /* Vertical layout;
  *
  */
 .noUi-pips-vertical {
   padding: 0 10px;
   height: 100%;
   top: 0;
   left: 100%;
 }
 .noUi-value-vertical {
   -webkit-transform: translate(0, -50%);
   transform: translate(0, -50%, 0);
   padding-left: 25px;
 }
 .noUi-rtl .noUi-value-vertical {
   -webkit-transform: translate(0, 50%);
   transform: translate(0, 50%);
 }
 .noUi-marker-vertical.noUi-marker {
   width: 5px;
   height: 2px;
   margin-top: -1px;
 }
 .noUi-marker-vertical.noUi-marker-sub {
   width: 10px;
 }
 .noUi-marker-vertical.noUi-marker-large {
   width: 15px;
 }
 .noUi-tooltip {
   display: block;
   position: absolute;
   border: 1px solid #D9D9D9;
   border-radius: 3px;
   background: #fff;
   color: #000;
   padding: 5px;
   text-align: center;
   white-space: nowrap;
 }
 .noUi-horizontal .noUi-tooltip {
   -webkit-transform: translate(-50%, 0);
   transform: translate(-50%, 0);
   left: 50%;
   bottom: 120%;
 }
 .noUi-vertical .noUi-tooltip {
   -webkit-transform: translate(0, -50%);
   transform: translate(0, -50%);
   top: 50%;
   right: 120%;
 }





/*----------------

 noUIslider end

------------- */




/*----------------

CKEDITOR

------------- */
.cke_chrome{
  border: 1px solid rgba(104,94,129,.6) !important;
  border-radius: 4px;
  width: 100% !important;
  margin-top: 10px !important;
  overflow: hidden;
}
