.orderInfo {
  color: $text-color;
  color:  var(--font-color, $text-color);

  .c-list__header {
    border-bottom: none;

    @media print {
      background-color: #FFF;
    }
  }

  .c-list__header-options__wrapper {
    position: relative;
    justify-content: center;

    @media (min-width: $mobile) and (max-width: $desktop) {
      justify-content: space-around;
    }
  }

  .closeInfo {
    position: absolute;
    right: 0;
    cursor: pointer;
    font-size: 52px;
    font-weight: 300;
    transform: rotate(45deg);

    @media print {
      display: none;
    }
  }

  .print {
    position: absolute;
    right: 80px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: auto;
    height: auto;
    border-radius: unset;
    background: none;
    margin: 0;

    @media(max-width: $mobile){
      display: none;
    }

    @media print {
      display: none;
    }

    .ico-print {
      display: flex;
      width: 20px;
      height: 20px;
      margin-left: 5px;
      background-image: url(../images/print.svg);
      background-size: contain;
    }

    @media (min-width: $mobile) and (max-width: $desktop) {
      position: relative;
      right: 0;
    }
  }

  .title {
    font-size: remy($aside-header);
    line-height: 28px;
  }

  .ticket__wrapper{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;

    img{
      max-width: 200px;
      max-height: 200px;

      //@media print {
      //  max-width: 120px;
      //  max-height: 120px;
      //}
    }
    span{
      color: $text-color;
    }
  }

  .c-list__content {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;

    &-block {
      width: 50%;

      @media(max-width: $mobile){
        width: 100%;
      }

      &.right-block {

        border-left: 1px solid #B4AFC1;

        .item-title {
          width: 40%;

          @media(max-width: $mobile){
            width: 50%;
          }

          @media print {
            width: 50%;
          }
        }

        .item-content {
          width: 60%;

          @media(max-width: $mobile){
            width: 50%;
          }

          @media (min-width: $mobile) and (max-width: $desktop) {
            width: 50%;
          }

          @media print {
            width: 50%;
          }
        }

        @media print {
          border-left: none;
        }

      }
    }

    @media print{
      flex-direction: column;
      align-items: center;
    }

  }

  .c-input__wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 15px;

    .item-title {
      width: 60%;
      padding-right: 15px;
      text-align: right;
      font-weight: 500;

      @media(max-width: $mobile){
        width: 50%;
        padding-left: 15px;
        line-height: 18px;
      }

      @media (min-width: $mobile) and (max-width: $desktop) {
        width: 50%;
      }

      @media print {
        width: 50%;
      }
    }

    .item-content {
      width: 40%;
      padding-left: 15px;

      @media(max-width: $mobile){
        width: 50%;
      }

      @media print {
        width: 50%;
      }
    }

    @media print {
      padding-bottom: 0;
    }
  }

  .c-button-footer{
    margin-right: 30px;
    margin-bottom: 20px;

    .mark-unread{
      @extend .o-button;
      width: auto;

      @media print {
        display: none;
      }
    }
  }
}

@media print {
  @page {
    size: portrait;
    margin: 0;
  }
}
