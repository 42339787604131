.c-select__range__wrapper{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 350px;
    //height: 50px;
    background-color: $background-content;
    box-shadow: 0 1px 4px 0 rgba(158,143,190,0.57);
    border-radius: 5px;
    padding: 10px;
    z-index: 999;
    top: 100%;
    //left: 0;
    right: 0;
    margin-top: 10px;

    .range-slider__wrapper{
        width: 40%;
        flex-grow: 1;
        margin-right: 20px;

        @media (max-width: $mobile) {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .range-inputs__wrapper{
        width: 40%;

        justify-content: flex-end;
        display: flex;
        align-items: center;

        input{
            width: 31px;
            height: 23px;
            outline: none;
            border: 0.5px solid rgba($text-color, .6);
            border-radius: 4px;
            text-align: center;
            font-size: remy($button-regular);
            color: $text-color;
            color:  var(--font-color, $text-color);
            margin: 0 5px;

            &:focus,&:active, &:hover{
                border: 0.5px solid $text-color;
                border: 0.5px solid var(--font-color, $text-color);
            }
        }

        .rangeVal{
            margin-right: 10px;
        }

    }

    .range-button__wrapper{
        width: 40px;
        display: flex;
        justify-content: flex-end;

        .o-button{
            width: 23px;
            height: 23px;
            padding: 0;
            margin-left: 0;
            //background-image: ico-generator("checkemployee", "rgba(255,255,255)");
            background-image: ico-generator("done", "rgba(255,255,255)");
            background-position: center;
            background-size: 20px;
            background-repeat: no-repeat;
        }
    }
    
    @media (max-width: $mobile) {
        flex-wrap: wrap;
        max-width: 100%;
        justify-content: flex-start;
    }
}
.noUi-connect{
    background: $main-color;
    background: var(--main-color, $main-color);

    &, .noUi-origin{

    }
}

.noUi-horizontal{
    height: 5px;
    box-shadow: none;
    background-color: #9D9D9D;
}
.noUi-handle{
    background-color: white;
    border: 2px solid $main-color;
    border: 2px solid var(--main-color, $main-color);
    border-radius: 50%;

}
.noUi-horizontal .noUi-handle{
    width: 14px;
    height: 14px;
    box-shadow: none;
    outline: none;
    cursor: pointer;

    &:focus{
        background-color: $main-color;
        background-color: var(--main-color, $main-color);
    }

    &::before, &::after{
        display: none;
    }
}
html:not([dir=rtl]) .noUi-horizontal .noUi-handle{
    right: -10px;
}