.o-button--show-more{
    background-color: transparent;
    padding: 0;
    width: 12px;
    height: 13px;
    margin: 0;

    .ico{
        transition: transform .2s  ease-out;
        transform: rotate(-90deg);
        margin-right: 0;
        width: 100%;
        height: 100%;

        &::before{
            color: $text-color;
        }

        @media(max-width: $mobile){
            transform: rotate(0);
        }

    }
    &.disabled{
        pointer-events: none;
    }

    &:hover{
        background-color: transparent;
        .ico{
            transform: rotate(0);
        }
    }
    &:focus{
        background-color: transparent;
    }

}