.c-content-menu{
    display: flex;
    position: relative;
    //padding-left: 9px;

    .current-time{
        position: absolute;
        top: 35px;
        left: 4px;
        color: var(--main-color,rgba(104,94,129,.99));
        font-size: 14px;
        font-weight: 600;

        @media (max-width: $desktop) {
            font-size: 12px;
            top: 25px;
            left: 21px;
        }

        @media (max-width: $mobile) {
            position: fixed;
            top: 66px;
        }
    }

    .current-people{
        position: absolute;
        top: 35px;
        left: 160px;
        color: var(--main-color,rgba(104,94,129,.99));
        font-size: 14px;
        font-weight: 600;

        @media (max-width: $desktop) {
            font-size: 12px;
            top: 25px;
            left: 142px;
        }

        @media (max-width: $mobile) {
            position: fixed;
            top: 66px;
        }
    }

    &__wrapper{
        width: 100%;
        //margin-top: 5px;
        //margin-bottom: 10px;
        display: flex;
        align-items: center;
        min-height: 21px;

        @media(max-width: $desktop){
            margin: 0;
        }
        @media(max-width: $mobile){
            background-color: $background-menu;
            position: fixed;
            top: 42px;
            z-index: 999;
            min-height: 31px;
        }

        @media print {
            display: none;
        }
    }

    &__item{
        margin: 0 8px 0 0;

        a{
            font-size: remy($header-bigger);
            font-weight: 300;
            color: var(--font-color, $text-color);

            &.is-active{
                font-weight: normal;
                color: var(--main-color, $text-color);

                @media(max-width: $desktop){
                    font-weight: bold;
                    color: $main-color;
                }
            }

            @media(max-width: $desktop){
                line-height: 21px;
                font-weight: normal;
                white-space: nowrap;
            }

            @media(max-width: $mobile){
                font-size: 13px;
            }

            @media(min-width: $mobile) and (max-width: $desktop){
                font-size: 14px;
            }

        }

        &.bold{
            a{
                //color: $successSwitch-color;

                @media (max-width: $desktop){
                    color: $text-color;
                }
            }
        }

        @media(max-width: $desktop){
            margin: 0;
        }
    }

    @media(min-width: $mobile+1) and (max-width: $desktop){
        background: $background-main;
    }

    @media(max-width: $desktop){
        padding-left: 16px;
        padding-bottom: 30px;
        overflow-y: hidden;
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media(max-width: $mobile){
        background: $background-menu;
        padding-bottom: 19px;
    }
}

.quick-reservation{
    position: absolute;
    top: -40px;
    right: 4px;
    padding: 5px 10px;

    @media (max-width: $desktop) {
        font-size: 14px;
    }

    @media (max-width: $mobile) {
        right: 0;
        position: fixed;
        z-index: 1111;
        bottom: 0;
        top: unset;
        width: 100%;
        border-radius: 0;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}