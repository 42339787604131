.o-button-sort{
    background-color: transparent;
    display: inline-block;
    border: 0;
    width: 9px;
    height: 9px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../images/arrow.svg);
    cursor: pointer;
    padding: 0;

    &--up{
        transform: rotate(180deg);
    }

    &:hover,
    &:focus{
        background-image: url(../images/arrow-active.svg);
        border: 0;
    }

    &__wrapper{
        display: inline-flex;
        flex-wrap: wrap;
        //justify-content: center;
        align-content: space-between;
        position: relative;
        width: 12px;
        height: 17px;
        margin: 0 5px;
    }
}