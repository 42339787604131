#faultList{
    .c-list__grid{
        &--col-seven{
            .c-list__grid-item{
                &.grid-item{
                    &--1{
                        width: 15%;
                    }
                    &--2{
                        width: 15%;
                    }
                    &--3{
                      width: 5%;
                    }
                    &--4{
                        width: 12%;
                    }
                    &--5{
                        width: 15%;
                    }
                    &--6{
                        width: 5%;
                    }
                }
            }
        }
    }
}