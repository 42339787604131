.c-chat{
    &__wrapper{
        background-color: $background-content;
        box-shadow: 0 3px 14px 0 rgba(158,143,190,.57);
        border: 1px solid #cbcbcb;
        margin-top: 50px;
        display: flex;
        width: 100%;
        border-radius: 9px;
        overflow: hidden;

        .availability{
            width: 10px;
            height: 10px;
            display: block;
            position: absolute;
            left: 30px;
            top: 14px;
            border-radius: 50px;
        }

        .active{
            background-color: $success-color;
        }
    }

    &__usersList{
        width: 30%;
        background-color: $background-menu;
        background-color: var(--menu-color, $background-menu);
        border-right: 1px solid #cbcbcb;
    }

    &__messages{
        width: 70%;

        &-options__wrapper{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;

            .buttons__wrapper{
                display: flex;
            }

            .o-button--danger{
                margin-left: 25px;
            }

            .o-button--option{
                position: relative;
                margin-left: 13px;

                .ico{
                    margin-right: 0;
                    width: 14px;
                    height: 14px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    &:before {
                        width: 100%;
                        font-size: 18px;
                    }
                }
                .ico{
                    &:before {
                        color: $text-color;
                        color: var(--font-color, $text-color);
                    }
                }
                &:hover, &:focus{
                    .ico{
                        &:before {
                            color: white;
                            color: var(--menu-color, white);
                        }
                    }
                }
            }

            .o-button--add{
                background-color: $main-color;
                position: relative;
                height: 25px;
                width: 25px;
                top: 5px;
                margin-right: 15px;

                .ico-plus{
                    margin-right: 0;
                    width: 12px;
                    height: 13px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);

                    &:before{
                        font-size: 10px;
                        color: white;
                        color: var(--menu-color, white);
                    }
                }
            }

            .o-dropdown__wrapper{
                top: 90px;
                width: 280px;
                transform: translateX(-41%);
            }
        }
    }

    &__content{
        position: relative;
        height: 100%;
        transition: .3s ease-in-out;

        &__wrapper{
            padding: 10px 0;
            height: 600px;
            overflow-y: auto;

            .c-list__grid--col-three{
                padding: 5px 30px;

                .grid-item--1{
                    width: 40%;
                    min-width: auto;
                }
                .grid-item--2{
                    width: 40%;
                    min-width: auto;
                    justify-content: center;
                }
                .grid-item--3{
                    width: 20%;
                }

                .o-button--add{
                    background-color: $main-color;
                    position: relative;
                    height: 25px;
                    width: 25px;
                    min-width: 25px;

                    .ico{
                        margin-right: 0;
                        width: 12px;
                        height: 13px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);

                        &:before {
                            font-size: 10px;
                            width: 100%;
                        }
                    }
                }
            }
        }

        .option-title{
            font-size: remy($header-regular);
            cursor: pointer;
        }

        .is-active{
            color: $main-color;
            font-weight: 600;
        }
    }

    &__header{
        border-bottom: 1px solid #b4afc1;
        padding: 5px 30px;
        padding-bottom: 5px;
        height: 120px;

        &-options__wrapper{
            display: flex;
            justify-content: space-between;
        }

        .c-list__grid--col-three{
            padding-left: 10px;
            padding-right: 10px;

            .grid-item--1{
                width: 33%;
                min-width: auto;
            }
            .grid-item--2{
                width: 33%;
                min-width: auto;
                justify-content: center;
            }
            .grid-item--3{
                width: 33%;
            }
        }

    }

    &__filters__wrapper{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex: 1 1 auto;

        .filters{
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .filter__wrapper{
                width: 100%;
                min-width: 300px;
                display: flex;
                align-items: center;

                .c-input-search__wrapper{
                    width: 100%;
                    margin: 25px 0;

                    .o-input-search{
                        background-color: $background-input;
                        color: $text-light-color;

                        &::placeholder{
                            color: $text-light-color;
                            font-weight: 300;
                        }
                    }
                }
            }
        }
    }

    &__person__wrapper{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex: 1 1 auto;

        .availability{
            top: 54px;
        }

        .content-title{
            font-size: remy($header-regular);
            font-weight: 500;
            padding-left: 25px;
        }
    }

    &__usersgroups{
        margin-top: 10px;

        &__item{
            position: relative;
            padding: 12px 12px 12px 55px;
            cursor: pointer;

            &.is-active{
                background: linear-gradient(rgba($main-color,0.05) 100%, rgba($main-color,0) 100%);
                border-left: 3px solid $main-color;
                border-left: 3px solid var(--main-color, $main-color);
                position: relative;
                z-index: 150;
                padding-left: 52px;

                & > .c-chat__usersgroups__link{

                    .name {
                        color: $main-color;
                        color: var(--main-color, $main-color);
                        font-weight: 500;
                    }

                    .c-chat__usersgroups__descript{
                        color: $text-color;
                        color:  var(--font-color, $text-color);
                    }

                    .availability{
                        left: 27px;
                    }
                }
            }
        }

        &__link{
            opacity: 1;
            max-height: 41px;
            display: block;

            .name{
                font-size: remy($header-regular);
                font-weight: 500;
            }
        }

        &__descript{
            font-size: remy($content-small);
            font-weight: 400;
            display: flex;
            margin-top: 5px;
        }

        &__date{
            position: absolute;
            right: 33px;
            opacity: 0.7;
        }

        .new-message{
            position: absolute;
            right: 31px;
            background-color: $main-color;
            width: 22px;
            height: 22px;
            min-width: 22px;
            top: 6px;
            font-size: remy($content-small);
        }
    }

    &__messages-content{
        position: relative;
        margin: 20px;

        .messages-left{
            display: flex;
            margin: 5px 0;
            margin-bottom: 25px;

            &__content{
                max-width: 60%;

                &--text{
                    padding: 15px 20px;
                    border-radius: 22px;
                    width: 100%;
                    margin: 7px 0;
                    background-color: $background-menu;
                    margin-bottom: 4px;

                    .message-text-send{
                        line-height: 1.3;
                    }
                }

                &--date{
                    position: absolute;
                    width: 100%;
                    right: 0;
                    text-align: left;
                    font-size: remy($content-small);
                    opacity: 0.8;
                    padding-left: 10px;
                }
            }
        }

        .messages-right{
            display: flex;
            margin: 5px 0;
            justify-content: flex-end;
            margin-bottom: 25px;

            &__content{
                max-width: 60%;

                &--text{
                    padding: 15px 20px;
                    border-radius: 22px;
                    width: 100%;
                    margin: 7px 0;
                    background-color: $main-color;
                    color: $text-light-color;
                    margin-bottom: 4px;

                    .message-text-send{
                        line-height: 1.3;
                    }
                }

                &--date{
                    position: absolute;
                    width: 100%;
                    right: 0;
                    text-align: right;
                    font-size: remy($content-small);
                    opacity: 0.8;
                    padding-right: 10px;
                }
            }
        }
    }

    &__send_message{
        bottom: 0;
        width: 100%;
        padding: 15px 40px 15px 20px;
        border-top: 1px solid #b4afc1;

        &-options__wrapper{
            display: flex;
            justify-content: space-between;

            .send-message__content{
                width: 80%;
                display: flex;
            }

            .o-button--option{
                position: relative;

                .ico{
                    margin-right: 0;
                    width: 14px;
                    height: 14px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    &:before {
                        width: 100%;
                        font-size: 14px;
                    }
                }
                .ico{
                    &:before {
                        color: $text-color;
                        color: var(--font-color, $text-color);
                    }
                }
                &:hover, &:focus{
                    .ico{
                        &:before {
                            color: white;
                            color: var(--menu-color, white);
                        }
                    }
                }
            }

            .text-message{
                border: none;
                margin-left: 20px;
                width: 80%;

                &:hover, &:focus{
                    outline: none;
                }
            }
        }

        &-button{
            position: relative;
            cursor: pointer;
            align-items: flex-end;

            .ico-send{
                font-size: 25px;
            }
        }
    }
}