[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
  display: block;
}

[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

[data-tooltip]:before {
  position: absolute;
  top: 150%;
  left: 0px;
  padding: 7px;
  border-radius: 3px;
  background-color: #fff;
  color: $text-color;
  content: attr(data-tooltip);
  box-shadow: $light-box-shadow;
  font-weight: normal;
}

//[data-tooltip]:after {
//  content: '';
//  position: absolute;
//  width: 20px;
//  /* background-color: red; */
//  background-image: url(../images/Triangle.svg);
//  height: 20px;
//  background-repeat: no-repeat;
//}


[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
  width: auto;
  font-size: 14px;
  line-height: 14px;
  text-transform: initial;
}