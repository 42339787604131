.o-input--switch{
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;

    &+.o-label{
        position: relative;
        background-color: #BEBEBE;
        height: 18px;
        width: 43px;
        border-radius: 14px;
        transition: .35s ease-out;
        cursor: pointer !important;

        &::after{
            content: '';
            position: absolute;
            background-color: white;
            box-shadow: 0 0 1px 0 rgba(0,0,0,0.12), 0 1px 1px 0 rgba(0,0,0,0.24);
            border-radius: 50%;
            left: 2px;
            top: 50%;
            transform: translateY(-50%);
            width: 16px;
            height: 16px;
            transition: .35s ease-out;
        }

    }

    &:checked{
        &+.o-label{
            background-color: $successSwitch-color;
            &::after{
                left: calc(100% - 18px ) ;

            }

        }
    }
}