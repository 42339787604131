.o-status{
    color: $text-light-color;
    font-weight: 500;
    border-radius: 12px;
    padding: 4px 6px;
    min-width: 120px;
    text-align: center;
    margin-right: 10px;

    &__box {
        display: flex;
        margin-right: 5px;

        @media(max-width: $mobile){
            position: absolute;
            left: 50%;
            height: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .small-status {
            height: 35px;
            width: 35px;
            border-radius: 25px;
        }
    }

    //&--reserved, &--new, &--realized{
    //    background-color: $warning-color;
    //}
    //&--free, &--accepted, &--close{
    //    background-color: $success-color;
    //}
    &-nieoplacona{
        background-color: $error-color-stat;
    }
    &-oplacona{
        background-color: $warning-color-stats;
    }
    &-wtrakcie, &-potwierdzona{
        background-color: $info-color-stats;
    }
    &-zrealizowana{
        background-color: $success-color-stat;
    }
}
@media only screen and (max-width: $desktop-l) {
    .o-status {
        min-width: 60px;
    }
}