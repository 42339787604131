#employeeList{

  .filters{
    .filter__wrapper{
      min-width: auto;
      width: auto;

      .c-input__wrapper{
        padding-left: 10px;

        label{
          width: 100%;
        }
      }

      .o-input-search{
        width: auto;
      }
    }
  }

  @media(max-width: $mobile) {

    .filters{
      flex-wrap: unset;
      margin-bottom: 0;

      .filter__wrapper:nth-child(1){
        width: 100%;
        min-width: auto;

        .c-input-search__wrapper{
          width: 100%;

          input{
            width: 100%;
          }
        }
      }
    }

    .ico-warning, .ico-warning-orange{
      position: absolute;
      left: 67%;
    }



  }

  @media(max-width: $mobile){
    .c-list__grid--col-eight{
      .grid-item{
        &--1{
          &:before{
            content: 'Imię i nazwisko';
          }
        }
        &--2{
          &:before{
            content: 'Rodzaj umowy';
          }
        }
        &--3{
          &:before{
            content: 'Początek umowy';
          }
        }
        &--4{
          &:before{
            content: 'Koniec umowy';
          }
        }
        &--5{
          &:before{
            content: 'Czas pracy';
          }
        }
        &--6{
          &:before{
            content: 'Godziny w tygodniu';
          }
        }
        &--7{
          &:before{
            content: 'Klient';
          }
        }
      }
    }
  }

  @media(min-width: $mobile+1) and (max-width: $desktop){

    .c-list__header-info {
      margin-bottom: 0;
    }

    .c-list__content__wrapper {
      overflow: auto;
      .c-list__content-header{
        width: 1200px;
        padding-right: 10px;
      }
      .c-list__content{
        width: 1200px;
      }

      #content-block{
        overflow: auto;
        width: 1200px;
        position: relative;
      }
    }
  }

}