.o-main-nav__wrapper{
    margin-top: 30px;
}


.o-main-nav__item, .o-main-nav__logOut{
  position: relative;
  font-size: remy($main-menu-regular);

  .ico{
      width:25px;
      height:25px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-left: -35px;
      background-size: 19px 25px;
      background-position: center;
      font-size: 18px;

      &-future{
        font-size: 24px;
      }
      &-parki{
        font-size: 15px;
      }
      &-inwestorzy{
        font-size: 16px;
      }
    }

  .new-message{
      position: absolute;
      right: -40px;
      background-color: $main-color;
      width: 22px;
      height: 22px;
      min-width: 22px;
      top: 10px;
      font-size: remy($content-small);

    @media(max-width: $mobile){
      right: 46px;
    }
  }


    &.is-active{
        & > .o-main-nav__link{
            color: $main-color;
            color: var(--main-color, $main-color);
            font-weight: 500;
            background: linear-gradient(to right, rgba($main-color,0.05) 0%, rgba($main-color,0) 100%);
          background: linear-gradient(to right, rgba($main-color,0.05) 0%, rgba($main-color,0) 100%);
            border-left: 2px solid $main-color;
          border-left: 2px solid var(--main-color, $main-color);
            position: relative;
            z-index: 150;
        }
      .ico{
        &-future{
          &:before {
            color: $main-color;
            color: var(--main-color, $main-color);
            }
          }

        &-inwestorzy{
          &:before {
            color: $main-color;
            color: var(--main-color, $main-color);
          }
        }
        &-pracownicy{
          &:before {
            color: $main-color;
            color: var(--main-color, $main-color);
          }
        }
        &-skoczki{
          &:before {
            color: $main-color;
            color: var(--main-color, $main-color);
          }
        }
        &-parki{
          &:before {
            color: $main-color;
            color: var(--main-color, $main-color);
          }
        }
        &-strefy{
          &:before {
            color: $main-color;
            color: var(--main-color, $main-color);
          }
        }
        &-rezerwacje{
          &:before {
            color: $main-color;
            color: var(--main-color, $main-color);
          }
        }
        &-rozliczenia{
          &:before {
            color: $main-color;
            color: var(--main-color, $main-color);
          }
        }
        &-uprawnienia{
          &:before {
            color: $main-color;
            color: var(--main-color, $main-color);
          }
        }
        &-zarzadzanieRezerwacjami{
          &:before {
            color: $main-color;
            color: var(--main-color, $main-color);
          }
        }
        &-artykuly{
          &:before {
            color: $main-color;
            color: var(--main-color, $main-color);
          }
        }
        &-czat{
          &:before {
            color: $main-color;
            color: var(--main-color, $main-color);
          }
        }
        &-listaZakupowa{
          &:before {
            color: $main-color;
            color: var(--main-color, $main-color);
          }
        }
        &-serwis{
          &:before {
            color: $main-color;
            color: var(--main-color, $main-color);
          }
        }
        &-sprawdzanieBiletow{
          &:before {
            color: $main-color;
            color: var(--main-color, $main-color);
          }
        }
        &-jezyki{
          &:before {
            color: $main-color;
            color: var(--main-color, $main-color);
          }
        }
      }

    }

    &--submenu{
        position: relative;
        z-index: 150;

        .ico{
          top: 20px;
        }

        &.is-active{
            &::after{
                color: $main-color;
                color: var(--main-color, $main-color);
                left: 170px;

            }
        }
        .is-active{
            & > .o-main-nav__link{
                color: $main-color;
                color: var(--main-color, $main-color);
                font-weight: 500;
                background: transparent;
                border-left: 0;
                padding-left: 10px;
            }
        }
    }
}

.o-main-nav__logOut{
  @media(min-width: $mobile+1){
    display: none;
  }
}

.o-main-nav__link{
    display: block;
    padding: 15px 10px 13px 50px;

    &:hover,
    &:active,
    &:focus{
            color: $main-color;
            color: var(--main-color, $main-color);
            font-weight: 500;
            outline: none;
    }
}

.checkbox-agreement{
  position: fixed;
  bottom: 20px;
  left: 18px;

  li{
    margin-bottom: 10px;

    a{
      color: $main-color;
      color: var(--main-color, $main-color);
      font-weight: 500;
    }
  }
}