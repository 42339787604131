.c-form-aside{
    position: relative;
    width: 100%;

    .c-input__wrapper{
        display: flex;
        flex-wrap: wrap;

        textarea{
            width: 60%;
            color: #3d3d3d;
            border: .5px solid rgba(104,94,129,.6);
            border-radius: 4px;
            padding: 8px 10px;
            font-size: .8125rem;
            font-weight: 500;
            font-family: Roboto;
            max-width: 60%;
            height: 150px;

            &:focus,&:active{
                outline:none;
            }

            @media(max-width: $mobile){
                max-width: 100%;
                width: 100%;
            }
        }

        @media(max-width: $mobile){
            flex-direction: column;
            width: 100%;
            padding: 0;
        }
    }
    .o-label, .input-label{
        width: 40%;
        font-size: remy($aside-content-regular);
        color: $label-color;
        font-weight: 500;
        padding-top: 10px;
        cursor: default;

        @media(max-width: $mobile){
            width: 100%;
            padding-bottom: 8px;
        }
    }
    .o-input{
        width: 60%;
        color: $input-text-color;
        max-width: 100%;

        &::placeholder{
            color: $placeholder-color;
        }

        @media(max-width: $mobile){
            width: 100%;
        }
    }
    .address-inputs__group{
        display: flex;
        width: 60%;
        flex-wrap: wrap;
        justify-content: space-between;

        .c-input__wrapper{
            width: 47%;
        }
        .o-input{
            width: 100%;
        }

    }

    .input-date__wrapper{
        width: 60%;
        justify-content: space-between;
        .c-input__wrapper:nth-child(1), .c-input__wrapper:nth-child(3){
            width: 25%;
        }
        .c-input__wrapper:nth-child(2){
            width: 41%;
        }

        .o-input{
            width: 100%;
            height: 32px;
        }
        .c-select__list__wrapper{
           min-width: 100%;
        }
    }


    .c-input--switch__wrapper{
        align-items: center;

        .o-input{
            width: 0;
        }
        .o-label{
            width: 43px;
            padding-top: 0;
        }
        .input-address__label{
            padding-top: 0;
        }

        .accept-change{
            margin-left: 10px;

            @media(max-width: $mobile){
                position: absolute;
                right: 0;
                top: 28px;
            }
        }

        @media(max-width: $mobile){
            flex-direction: unset;
            flex-wrap: unset;
        }
    }
    .c-input-file__wrapper{
        .o-button{
            width: 60%;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    &.modulesForm{
        .c-input--switch__wrapper{
            display: flex;
            justify-content: space-between;
        }
    }

    .c-select__wrapper{
        .o-label.is-hide{
            display: block;
        }

    }
    .c-select__list__wrapper{
        min-width: 60%;
        left: 100%;
        top: auto;
		bottom: 20px;
		transform: translateX(-100%) translateY(100%);

        @media(max-width: $mobile){
            width: 100%;
            transform: none;
            left: unset;
            top: 100%;
        }
    }
    .c-input-search__wrapper::after{
        right: 20px;
    }

    &--permissions{
        .c-input--switch__wrapper{
            justify-content: space-between;
            padding-bottom: 5px;
            padding-top: 5px;
            margin-top: 5px;
            margin-bottom: 5px;

            &:hover{
                background-color: rgba(#EFF4F7, .5);
            }

            .input-label{
                width: auto;
                padding-top: 0;
            }
        }
    }

    .c-input-file__wrapper{
        align-items: center;

        .file__output__wrapper{
            width: 40%;
        }
        .o-input{
            width: 60%;
        }
        .avatar-output{
          position: relative;
            border-radius: 50%;
            width: 65px;
            height: 65px;
            border: 2px solid $main-color;
            border: 2px solid var(--main-color, $main-color);
            margin-bottom: 8px;
            margin-left: 0;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        .image-output{
            width: 100%;
            height: 80px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;
        }

        .o-button{


        }

        label{

        }
    }

  label.error{
    width: 100%;
    text-align: right;
  }
}

.removeImage{
    position: absolute;
    width: 16px;
    height: 16px;
    border: 1px solid $main-color;
    border: 2px solid var(--main-color, $main-color);
    border-radius: 50%;
    background-image: ico-generator("plus", $main-color);
    background-image: ico-generator("plus", var(--main-color, $main-color));
    background-size: 70%;
    background-position: center;
    background-repeat: no-repeat;
    transform: rotate(45deg);
    right: 10px;
    top: 10px;
    background-color: white;
    cursor: pointer;
}

.croppie-block{
    text-align: center;

    .cancel-avatar{
        width: 123px;
        margin-right: 10px;
        margin-bottom: 30px;
    }
}