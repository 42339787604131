.l-full-page{
    width: 100%;
    .c-content__wrapper{
        padding: 30px;
    }
    .c-content-header__wrapper{
        position: relative;
    }
    .content-header{
        background-color: $background-content;
        padding-right: 15px;
        position: relative;
        z-index: 200;
        display: inline-block;
        font-size: remy($aside-header-small);
        font-weight: 500;
        color: $content-header-color;
    }
}