#holidayList{
  .c-list__grid{
      &--col-six{
          .c-list__grid-item{
              &.grid-item{
                  &--1{
                    width: 20%;
                  }
                  &--2{
                    width: 20%;
                  }
                  &--4{
                    width: 15%;
                  }
                  &--5{
                    width: 10%;
                  }
              }
          }
      }
  }

  @media(max-width: $mobile) {

    .filters{
      flex-wrap: unset;

      .filter__wrapper:nth-child(1){
        width: 100%;
        min-width: auto;

        .c-input-search__wrapper{
          width: 100%;
        }
      }
    }

  }

  @media(min-width: $mobile+1) and (max-width: $desktop) {
    .c-list__content__wrapper{
      overflow: auto;

      .c-list__content-header, .c-list__content{
        width: 1100px;
      }
    }
  }

  @media(min-width: $desktop+1) and (max-width: $desktop-l){
    //.filters{
    //  .filter__wrapper{
    //    width: 220px;
    //
    //    .c-input-search__wrapper, .c-select__wrapper, c-input-calendar__wrapper{
    //      width: 100%;
    //
    //      .o-input{
    //        width: 100%;
    //      }
    //    }
    //  }
    //}
  }
}