// px size

/* font family */
$main-font: 'Roboto', sans-serif;

/* main menu */
$main-menu-regular: 15;
/*  */


/* main sizes */
$main-header-regular: 24;
/*  */


/* main headers */
$header-bigger: 18;
$header-regular: 14;
/*  */


/* aside */
$aside-header: 20;
$aside-header-small: 16;
$aside-content-regular: 13;
/*  */


/* content text */
$content-bigger: 16;
$content-regular: 13;
$content-small: 12;
/*  */


/*  */
$utils-small: 12;
$utils-regular: 12.36;
/*  */

/* buttons */
$button-regular: 14;
$button-anchore-regular: 13;
/*  */


/* inputs */
$input-regular: 14;
$select-regular: 14;
/*  */


/* informations */
$error-regular: 12;
/*  */



/* login form */
$login-header: 22;
$login-text: 14;
$login-placeholder: 13;
$login-placeholder--active: 10;
$login-input-text: 13;
$login-button-anchor: 14;
$login-button: 14;
/*  */


$icomoon-font-family: "future-fonts" !default;
$icomoon-font-path: "fonts" !default;

$icon-correctround-path1: "\e942";
$icon-correctround-path2: "\e94e";
$icon-cacnelRound-path1: "\e944";
$icon-cacnelRound-path2: "\e94f";
$icon-ico-calendar-empty: "\e93c";
$icon-send-button: "\e950";
$icon-percent: "\e94c";
$icon-werehouse: "\e94a";
$icon-service: "\e948";
$icon-remove: "\e946";
$icon-arrowBack: "\e93e";
$icon-lock: "\e93a";
$icon-ico-stop: "\e901";
$icon-ico-play: "\e903";
$icon-ico-edit: "\e905";
$icon-arrow: "\e907";
$icon-check-color: "\e909";
$icon-close-ico: "\e940";
$icon-ico-export: "\e94b";
$icon-ico-info: "\e90c";
$icon-ico-logout: "\e90e";
$icon-ico-msg: "\e910";
$icon-ico-notif: "\e912";
$icon-ico-plus-light: "\e914";
$icon-menu-artykuly: "\e94d";
$icon-menu-czat: "\e930";
$icon-menu-futureDesign: "\e932";
$icon-menu-inwestorzy: "\e934";
$icon-menu-jezyki: "\e936";
$icon-menu-listaZakupowa: "\e938";
$icon-menu-parki: "\e917";
$icon-menu-pracownicy: "\e919";
$icon-menu-rezerwacje: "\e91b";
$icon-menu-rozliczenia: "\e91d";
$icon-menu-serwis: "\e91f";
$icon-menu-skoczki: "\e921";
$icon-menu-sprawdzanieBiletow: "\e923";
$icon-menu-strefy: "\e925";
$icon-menu-uprawnienia: "\e927";
$icon-menu-zarzadzanieRezerwacjami: "\e929";
$icon-open-menu: "\e92b";
$icon-search-ico: "\e92d";
$icon-ico-settings: "\e900";
