.c-change-lang__wrapper{
    width: 50px;
    padding-bottom: 0;
    padding-top: 0;
    margin-bottom: 0;
    height: 100%;
    align-items: center;

    &::after{
      width: 10px;
      height: 10px;
      background-repeat: no-repeat;
        right: 8px;
        top: 35%;
    }

    &:hover{
        cursor:pointer;
    }

    .o-input{
        border: 0;
        font-size: remy($content-small);
        color: $text-color;

        &:disabled{
            background: transparent;
        }
    }
    .c-select__wrapper{
        padding-bottom: 0;
        margin-bottom: 0;



    }
    .c-select__list__wrapper{
        font-size: remy($content-small);
        top: 25px;
        padding: 0;
        left: -6px;
    }
    .c-select__list__item label::after{
        display: none;
    }

    #lang{
        font-size: 14px;
        margin-left: 10px;
    }

}