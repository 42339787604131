
.tippy-tooltip.light-theme {
    box-shadow: 0 -1px 4px 0 rgba(158,143,190,0.57);
    font-weight: 500;
    color: #685E81;
    border: 1px solid rgba(158,143,190,0.27);
    font-size: remy(12);
}

.tippy-tooltip.light-theme .tippy-backdrop{
    background-color: #fff;
}


/* Round arrow */
.tippy-tooltip.light-theme .tippy-roundarrow {
fill: #fff;
stroke: rgba(158,143,190,0.27);;
}