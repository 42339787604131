.o-open-btn{
    display: block;
    width:18px;
    height: 18px;
    background-color: transparent;
    border: 0;
    background-image: ico-generator(openMenu, #685E81);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    outline: none;
    position: absolute;
    left: 20px;
    top: 20px;
    transition: left .2s ease-out;
    z-index: 1001;
    margin: 0 30px 0 0;

    &:hover,
    &:focus{
        background-image: ico-generator(openMenu, #000000);
    }

    &.menu-hidden{
        position: fixed;
        top: 20px;
    }

    @media print {
        display: none;
    }

}
@media(max-width: $mobile) {
    .o-back-btn {
        position: fixed;
        z-index: 11111;
        display: none;
        width: 18px;
        height: 24px;
        background-color: transparent;
        border: 0;
        background-image: ico-generator(arrowBackMenu, #685E81);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        outline: none;
        left: 20px;
        top: 20px;
        transition: left .2s ease-out;
        margin: 0 30px 0 30px;

        //&:hover,
        //&:focus {
        //    background-image: ico-generator(arrowBackMenu, #000000);
        //}
    }
}
@media(min-width: $mobile+1) {
    .o-back-btn {
        display: none !important;
    }
}