.c-person-notification{
    margin: 0 10px;
    position: relative;

    .ico-notifications{
        font-size: 21px;
    }

    .o-dropdown__wrapper{
        width: 330px;
        transform: translateX(-50%);
        top: 30px;

        .showAll__button{
            display: block;
            font-weight: 500;
            text-align: center;
            width: 100%;
            font-style: remy(12);
        }
    }

    .person-notification__list{
        .person-notification__item{
            border-bottom: 1px solid rgba(158,143,190,.57);
            padding: 10px;
            font-weight: 500;
            cursor: pointer;
            flex-wrap: wrap;

            &:hover{
                background-color: #FAFAFA;
            }
        }

        .all-button{
            border-bottom: none;
        }

        .notification__titile{
            font-size: remy(14);
            width: 100%;
        }
        .notifictaion__time{
            font-size: remy(12);
            font-weight: normal;
            columns: #767676;
            margin-top: 5px;
        }

        .notification__unread{
            background-color: #ede9fd;
        }
    }
}

#notificationList{
    .notification__read{
        cursor: pointer;
        .notification__dot{
            background-color: $text-color;
            background-color: var(--font-color, $text-color);
        }
    }
    .notification__unread{
        background-color: #ede9fd;
        cursor: pointer;
        .notification__dot{
            background-color: transparent;
        }
    }

    .notification__dot{
        width: 8px;
        height: 8px;
        border: 1px solid $text-color;
        border: 1px solid var(--font-color, $text-color);
        border-radius: 50%;
        cursor: pointer;
        margin-right: 15px;

    }

    .c-content-item-options__wrapper{
        align-items: center;
    }
}