.o-button--option{
    width: 35px;
    height: 35px;
    min-width: 35px;
    border-radius: 50%;
    padding: 0;
    margin: 0 5px;
    background-color: $option-button;
    background-color: var(--menu-color, $option-button);

    &:hover,
    &:focus{
        background-color: $main-color;
        background-color: var(--main-color, $main-color);
    }

    &.paid {
        background-image: url(../images/dol-active.svg);

        &:hover, &:focus, &:active{
            background-color: unset;
        }

        &.no-click{
            cursor: default;
        }
    }

    &.nopaid {
        background-image: url(../images/dol.svg);

        &:hover, &:focus, &:active{
            background-color: unset;
        }

        &.no-click{
            cursor: default;
        }
    }

    &.reset {
        background-image: url(../images/reset.svg);

        @media (max-width: $mobile){
            background-image: none;
        }

        &:hover {
            background-image: url(../images/reset-hover.svg);

            @media (max-width: $mobile){
                background-image: none;
            }
        }
    }

    &.info {
        background-image: url(../images/info.svg);

        @media (max-width: $mobile){
            background-image: none;
        }

        &:hover {
            background-image: url(../images/info-hover.svg);
        }
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.4;
    }

}

