#reservationManagmentList{

  //.c-list__content__wrapper{
  //  margin-left: 20px;
  //}

  .ico-cancel2{
    background-color: #EF4141;
  }

  .c-list__filters__wrapper{

    .filters{
      flex-wrap: nowrap;
        .filter__wrapper{
          //width: 100%;
          min-width: auto;
            .c-input-search__wrapper{
              width: 97%;

              .o-input{
                width: 100%;
                min-width: 177px;
              }
            }
            .c-input__wrapper{
              width: 100%;
              margin-left: 6px;
                .o-label{
                  width: auto;
                }
                .o-input{
                  width: 100%;
                }

              //@media(min-width: 1500px){
              //  margin-left: 15px;
              //}
              //@media(min-width: 1500px){
              //  margin-left: 30px;
              //}
            }
          &.btn{
            width: auto;
          }
        }

    }

    @media (min-width: 1800px){
      .filter__wrapper{
        width: 20%;

        &:first-of-type{
          min-width: 200px;
          width: 15%;
        }
      }

      .o-input-search{
        width: 200px;
      }
    }

  }



  @media only screen and (max-width: 1600px) {
    .c-list__grid--col-eight{

      .grid-item--1{
        width: 14%;
        min-width: unset;
        flex: 1 1 14%;
      }

      .grid-item--2{
        width: 14%;
      }

      .grid-item--3{
        width: 10%;
      }

      .grid-item--4{
        width: 12%;
      }

      .grid-item--5{
        width: 14%;
      }

      .grid-item--6{
        width: 10%;
        flex: auto;
        min-width: 70px;
      }

      .grid-item--7{
        width: 10%;
        flex: auto;
      }

      .grid-item--8 {
        width: 16%;
        flex: 0 1 16%;
      }
    }

    .c-list__filters__wrapper{
      flex-wrap: wrap;
    }


  }


  @media only screen and (max-width: 1366px) {
    .c-list__grid--col-eight{

      .grid-item--1{
        min-width: 160px;
      }

      .grid-item--2{
        min-width: 135px;
      }

      .grid-item--3{
        min-width: 80px;
      }

      .grid-item--4{
        min-width: 105px;
      }

      .grid-item--5{
        min-width: 105px;
      }

      .grid-item--6{
        min-width: 95px;
      }

      .grid-item--7{
        min-width: 65px;
      }

      .grid-item--8 {
        min-width: 95px;
      }
    }


    .c-person-menu__wrapper{
      margin-bottom: 15px;
    }
  }

  @media (max-width: $mobile){
    .c-list__content__wrapper{
      margin-left: 0;
    }
  }

  @media(min-width: $mobile+1) and (max-width: $desktop){

    .c-list__header-info {
      margin-bottom: 0;
    }

    .filters{
      margin-bottom: 8px;
    }

    .c-list__content__wrapper {
      overflow: auto;
      margin-left: 0;
      .c-list__content-header{
        width: 1200px;
        padding-right: 10px;
      }
      .c-list__content{
        width: 1200px;
      }

      #content-block{
        overflow: auto;
        width: 1200px;
        position: relative;
      }
    }
  }
}

@media(min-width: 1200px){
  .filters{
    .filter__title, .filter__button{
      display: none;
    }
  }

  #mobile-filter{
    display: none;
  }
}

@media (max-width: $desktop){
  .mobile-filter{
    display: none;
    flex-direction: column;
    max-height: 100%;
    padding: 0;
    box-sizing: border-box;
    border-radius: 9px;
    //margin: 10px;

    .filter__wrapper{
      padding: 10px 27px 0 27px;

      .c-input__wrapper{
        padding: 0;
        margin-bottom: 10px;

        .flatpickr-wrapper{
          width: 100%;
          z-index: 899;
        }

        .o-input{
          margin-top: 10px;
        }

        .flatpickr-input--range{
          width: 100%;
        }
      }
      .c-input-calendar__wrapper::after{
        top: 22px;
      }
      .c-select__wrapper::after {
        top: 62%;
      }

    }

    .filter__title{
      font-size: 16px;
      line-height: 24px;
      color: $text-color;
      display: flex;
      justify-content: space-between;
      padding: 20px 27px;
      border-bottom: 1px solid $background-menu;

      .reset__filter{
        color: $main-color;
      }

    }

    .filter__footer{
      height: 100px;
      width: 100%;
      padding: 30px 40px;

      .button__wrapper{
        justify-content: flex-end;

        //@media (max-width: $mobile){
        width: 100%;
        min-height: 35px;
        border-top: 1px solid $background-menu;
        //}

      }
      .o-button{
        margin-left: 25px;

        &--light {
          //@media (max-width: $mobile) {
          border-right: 1px solid $background-menu !important;
          border-radius: 0;
          //}
        }

        //@media (max-width: $mobile){
        width: 100%;
        margin: 0;
        border: 0;
        min-height: 35px;
        font-size: 13px;
        color: #685E80;
        background-color: transparent;
        // }

      }

      // @media (max-width: $mobile){
      padding: 0;
      display: flex;
      align-items: flex-end;
      // }

    }
  }
}

@media(max-width: $mobile){
  .mobile-filter {
    border: 0.5px solid $border-light;
    box-shadow: $light-box-shadow;
    margin: 10px;
  }
}

@media(min-width: $mobile+1) and (max-width: $desktop){
  .mobile-filter {
    border: none;
    box-shadow: unset;
  }
}

@media(max-width: 1800px){
  #reservationManagmentList, #employeeList{
    .filters {
      .c-input__wrapper {
        > label {
          width: 30% !important;
        }

        > input {
          width: 70% !important;
          max-width: 100%;
        }
      }
    }
  }
}

@media(min-width: $mobile+1) and (max-width: $desktop-l){
  #reservationManagmentList, #employeeList {
    .filters {
      .calendar-icon {
        .flatpickr-wrapper {
          width: 70%;

          input {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile) {

  //.c-list__wrapper{
  //  overflow-y: unset ;
  //}
  .filters {
    .filter__wrapper {
      width: 100%;
    }
  }

  .c-input-search__wrapper{
    //max-width: 234px;
    max-width: 100% !important;
  }

  .c-list__grid{

    flex-direction: column;
    max-height: 100%;
    padding: 0;
    border: 0.5px solid $border-light;
    box-sizing: border-box;
    box-shadow: $light-box-shadow;
    border-radius: 9px;
    margin: 10px;


    .c-list__grid-item{
      border: none;
      padding-bottom: 9px;
      width: 100%;
      min-height: 20px;

      .content-title{
        position: absolute;
        left: 50%;
      }

      .o-status{
        position: absolute;
        left: 50%;
        height: 17px;
        display:flex;
        justify-content: center;
        align-items:center;
      }

      &:before{
        position: absolute;
        right: 50%;
        margin-right: 20px;
        color: $text-color;
      }
    }

    .grid-item--1{
      margin-top: 13px;

      .arrow--mobile{

        &.is-active{
          &:after{
            transform: rotate(180deg);
          }
        }

        &:after{
          content: $icon-arrow;
          position: absolute;
          width: 12px;
          height: 8px;
          top: 16px;
          right: 25px;
          transition: .4s ease-out;
          font-family: 'future-fonts';
          font-size: 9px;
          color: $text-color;
          color: var(--font-color, $text-color);
        }
      }

      &:before{
        content: "Email";
      }
    }

    .grid-item--2{

      &:before{
        content: "Data rezerwacji";
      }
    }

    .grid-item--3{
      &:before{
        content: "Park";
      }
    }

    .grid-item--4{
      display: none;

      &:before{
        content: "Liczba osób";
      }
    }

    .grid-item--5{
      display: none;

      &:before{
        content: "Użytkownik";
      }
    }

    .grid-item--6{
      display: none;

      &:before{
        content: "Strefy";
      }
    }

    .grid-item--7{
      display: none;

      .o-timer{
        position: absolute;
        left: 50%;

        &:before{
          content: "Czas";
          position: absolute;
          right: 140%;
        }
      }

      .o-status{
        &:before{
          content: "Status";
          position: absolute;
          right: 135%;
          color: $text-color;
        }
      }


    }

    .grid-item--8, .grid-item--10 {
      padding-bottom: 0;
      margin-top: 15px;

      .c-content-item-options__wrapper{
        justify-content: space-around;
        border-top: 1px solid $background-menu;

        .o-button{
          background-color: transparent;
          color: $text-color;
          width: 100%;
          border-radius: 0 0 0 20px;
          margin: 0;

          &[data-option="edit"]{
            border-right: 1px solid $background-menu;

            &:before{
              content: "Edytuj";
            }
          }

          &[data-option="remove"]{
            &:before{
              content: "Usuń";
            }
          }

          &[data-option="info"]{
            border-right: 1px solid $background-menu;
            line-height: 33px;
            background-image: unset;

            &:before{
              content: "Szczegóły";
            }
          }

          &[data-option="paid"]{
            border-right: 1px solid $background-menu;
            line-height: 33px;

            &:before{
              content: "Opłacona";
            }
          }

          &[data-option="timer-play"]{
            border-right: 1px solid $background-menu;

            &:before{
              content: "Rozpocznij";
            }

            &.change-title{
              &:before{
                content: "Zatrzymaj";
              }
            }
          }

          &[data-option="timer-reset"]{
            border-right: 1px solid $background-menu;

            &:before{
              content: "Resetuj";
            }
          }

          .ico{
            display: none;
          }

        }
      }
    }

    &.c-list__content-header{
      display: none;
    }

  }


  .c-person-menu__wrapper{
    margin-bottom: 15px;
  }
}

@media(max-width: $desktop){
  .c-input-search__wrapper{
    max-width: 300px;
  }
}
