.o-button{
    background-color: $main-color;
    background-color: var(--main-color, $main-color);
    color: $button-light-text;
    color: var(--menu-color, $button-light-text);
    font-size: remy($button-regular);
    border-radius: 4px;
    border: 0;
    padding: 7px 30px;
    cursor: pointer;
    text-decoration: none;
    line-height: 1.3;
    font-family: $main-font;

    &[data-option=timer-reset]{
        &:focus, &:active{
            opacity: unset;
            outline: unset;
        }
    }
    &[data-option=goToPay]{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:focus, &:hover, &:active{
        background: $button-hover;
        background: var(--main-color, $button-hover);
        opacity: .8;
        outline: none;
    }

    &--light{
        background-color: transparent;
        color: $main-color;
        color: var(--main-color, $main-color);
        border: 0.5px solid $main-color;
        border: 0.5px solid var(--main-color, $main-color);

        &:focus, &:hover, &:active{
            background-color: $main-color;
            background-color: var(--main-color, $main-color);
            color: $button-light-text;
            outline: none;
        }
    }

    &--ico{
        padding: 7px 10px;


    }

    &--aside{
        width: 60%;
    }

    .ico{
        width: 11px;
        height: 11px;
        margin-right: 6px;
        font-size: 11px;
        &::before{
            color: $button-light-text;
            color: var(--menu-color, $button-light-text);
        }
    }
}


.o-button-anchor{
    text-decoration: none;
    background: none;
    border: 0;
    padding: 5px;
    font-size: remy($button-anchore-regular);
    font-family: $main-font;
    color: $text-color;
    color: var(--font-color, $text-color);
    cursor: pointer;

    &:focus, &:hover, &:active{
        color: var(--main-color, $button-hover);
        outline: none;
        background-color: transparent;
    }
    &:focus{
        text-decoration: underline;
    }
}


.filter--button {
    background-color: $main-color;
    background-color: var(--main-color, $main-color);
    color: $button-light-text;
    color: var(--menu-color, $button-light-text);
    font-size: remy($button-regular);
    border-radius: 4px;
    border: 1px solid $main-color;
    padding: 7px 30px;
    cursor: pointer;
    text-decoration: none;
    line-height: 1.2;
    font-family: $main-font;
    display: inline-block;
    margin-left: 10px;

        &:focus, &:hover, &:active {
            background: $button-hover;
            background: var(--main-color, $button-hover);
            opacity: .8;
            outline: none;
        }

    @media(max-width: $desktop){
        display: none;
    }
}
