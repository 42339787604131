#warehouseList{
  @media (max-width: $mobile) {
    .grid-item {
      &--1 {
        &:before {
          content: "Nazwa";
        }
      }

      &--2 {
        &:before {
          content: "Stan";
        }
      }

      &--3 {
        &:before {
          content: "Data ostatniego zakupu";
        }
      }

      &--4 {
        &:before {
          content: "Strefa";
        }
      }
    }
  }
}