.l-block{
    flex-wrap: wrap;
    width: 100%;

    &--1-4{
        width: 25%;
    }
    &--2-4{
        width: 49%;
    }
    &--1-3{
        width: 40%;
    }
    &--2-3{
        width: 60%;
    }
    &--3-4{
        width: 73.3%;
    }
    &--3-4x{
        width: 75%;
    }

    @media (max-width: 1440px) {
        &--1-4{
            width: 25%;
        }
        &--2-4{
            width: 100%;
        }
        &--3-4{
            width: 100%;
        }
    }

    &--small{
        width: 32.3%;

        .u-flex{
            justify-content: flex-start;
            align-items: flex-end;
        }

        .block__number{
            font-size: remy(36);
            color: $text-color;
            color: var(--font-color, $text-color);
            line-height: 0.8;
        }
        .block__title{
            font-size: remy($header-regular);
            margin: 0 10px;
            flex-grow: 1;
        }

        .o-button{
            padding: 0;
        }

        .c-content__wrapper{
            min-height: auto;
        }
    }

    .c-content__wrapper{
        padding: 25px 30px;
    }
}


