#app{
  &.opacity-bg{
    .team-block-chart{
      width: calc(100% - 20px);
    }
  }
}

.chart-container {
  width: 100%;
  margin-top: 40px;

  .left-side {
    width: calc(30% - 20px);
    margin-right: 20px;
  }

  .right-side {
    width: 70%;
  }

  .contract-end{
    @media (max-width: $mobile) {
      height: auto;
      border: none !important;
      box-shadow: none;
      background-color: transparent !important;

      .content__header{
        margin-bottom: 0 !important;
      }

      .list-content-stat{
        max-height: 359px;

        .c-list__content-item{
          padding-left: 0;
        }
      }
    }
    @media(min-width: $mobile) and (max-width: $desktop-l) {
      .list-content-stat {
        .c-list__content-item {
          padding-left: 0;
        }
      }

      .c-list__content-header {
        padding: 10px 24px 10px 0px;
      }
    }

  }

  .chart-list {
    overflow-y: auto;
    max-height: 334px;
    padding-right: 20px;

    &-item {
      display: flex;
      //justify-content: space-between;
      border-bottom: 1px solid $select-color;
      padding: 16px 0px;
      font-size: 14px;
      color: $content-header-color;

      .chart-investorList {
        display: none;
        //overflow-y: auto;
        //max-height: 100px;

        .investorList-item {
          padding-top: 17px;
          padding-left: 20px;
        }
      }

      .list-name {
        white-space: nowrap;
        margin-left: 10px;
        font-size: 14px;
        color: $content-header-color;
        cursor: default;
      }

      .chart-list-date {
        width: 100%;
        text-align: right;
      }

      .item-space {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .is-open {
          .ico {
            transform: rotate(0deg);
          }
        }
      }
    }
  }

  .c-content__wrapper {
    margin-top: 10px;

    @media(max-width: $tablet) {
      padding: 0 !important;
      background-color: $background-main;
    }

    @media(min-width: $tablet+1) and (max-width: $desktop){
      box-shadow: 0px 3px 14px #DDD7E7;
      border: 1px solid $border-light;
      border-radius: 9px;
      margin: 10px 0;
    }

    @media(min-width: $tablet+1) and (max-width: $desktop-l){
      //padding: 15px 15px;
    }
  }



  .chartLib-block {
    .chartLib-header {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.5px;
      color: #685E81;
      margin-bottom: 30px;
      text-transform: uppercase;

      a{
        cursor: default;
      }

      @media(max-width: $tablet) {
        padding: 10px;
      }
    }

    @media(max-width: $tablet) {
      padding: 0;
      //height: 500px;
      border: 1px solid $border-light;
      border-radius: 9px;
      background-color: #fff;
    }
  }

  .list-content-stat {
    overflow-y: auto;
    max-height: 329px;
  }

  .c-list__grid--col-six {

    &.c-list__content-header {
      .option-title {
        text-transform: uppercase;
        color: $content-header-color;
        font-weight: bold;
        width: min-content;
      }
    }

    &.c-list__content-item {
      padding-top: 5px;
      background-color: #fff;

      @media(min-width: $desktop) and (max-width: $desktop-l){
        padding-right: 10px;
      }
      @media(max-width: $mobile){
        box-shadow: unset;
        margin: 10px 0;
      }
    }

    .c-list__grid-item {
      padding-bottom: 5px;

      .content-title {
        font-size: 14px;

        &.success {
          color: $success-color-stat;
        }

        &.warning {
          color: $warning-color-stats;
        }

        &.error {
          color: $error-color-stat;
        }

        @media(min-width: $desktop) and (max-width: $desktop-l){
          font-size: 12px;
        }
      }
    }

    .grid-item {
      &--1 {
        width: 20% !important;
        padding-left: 0 !important;
      }

      &--2 {
        width: 16% !important;
      }

      &--3 {
        width: 21% !important;
      }

      &--4 {
        width: 19% !important;
      }

      &--5 {
        width: 14% !important;
      }

      &--6 {
        //width: 10% !important;

        .o-button {
          width: 75px;
          height: 25px;
          padding: 0;
          font-size: 13px;
          font-weight: bold;

          &.disabled {
            pointer-events: none;
            opacity: 0.4;
          }
        }
        @media (max-width: $mobile) {
          width: 100% !important;
        }
        @media (min-width: $mobile+1) {
          width: 10% !important;
        }
      }
    }
  }

  @media (max-width: $desktop) {
    margin-top: 10px;
  }
}

.stats-header{
  font-size: 18px;
  line-height: 21px;
  color: #5C5470;
  margin-bottom: 24px;
}

//.test{
//  .chartWrapper {
//    position: relative;
//  }
//
//  .chartWrapper > canvas {
//    position: absolute;
//    left: 0;
//    top: 0;
//    pointer-events: none;
//  }
//
//  .chartAreaWrapper {
//    width: 600px;
//    overflow-x: scroll;
//  }
//
//}





