.c-customers-count{
    height: 130px;
    min-height: 130px;

    .c-content{
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .content__header{
        width: 100%;
    }

    .number{
        font-size: remy(48);
        font-weight: 500;

        &__last{
            font-weight: 900;
            font-size: remy(21);
            width: 100%;
            text-align: right;
        }
    }

    .chart__wrapper{
        width: 60%;
        height: 50px;
    }
}