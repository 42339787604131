.o-dropdown__button{
    cursor: pointer;
}

.o-dropdown__wrapper{
    position: absolute;
    display: none;
    box-shadow: 0 1px 4px 0 rgba(158,143,190,0.57);
    background-color: $background-content;
    border-radius: 5px;
    width: 100%;
    z-index: 999;
}