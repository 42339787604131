.c-list__grid{

    .c-list__grid-item{
        flex: 1 1 auto;
        display: flex;
        align-items: center;

        &.grid-item--status{
            max-width: 145px;
        }
        .c-content-item-options__wrapper{
            display: flex;
            width: 100%;
            text-align: center;
            justify-content: flex-end;

            .option-title{
                width: 100%;
            }
        }

    }

    &--col-five{
        .c-list__grid-item{
            &.grid-item{

                &--1{
                    width: 25%;
                    min-width: 160px;
                    flex-grow: 0;
                }
                @media only screen and (max-width: 1366px) {
                    &--1{
                        width: 22%;
                    }
                }
                &--2{
                    min-width: 130px;
                    width: 12%;
                }
                @media only screen and (max-width: 1366px) {
                    &--2{
                        width: 15%;
                    }
                }
                &--3{
                    min-width: 130px;
                    width: 15%;
                }
                &--4{
                    min-width: 130px;
                    width: 12%;
                }
                @media only screen and (max-width: 1440px) {
                    &--4{
                        width: 10%;
                        min-width: 100px;
                    }
                }
                &--5{
                    width: 15%;
                    max-width: 160px;
                    flex-grow: 0;
                    display: flex;
                    justify-content: flex-end;
                }
                @media only screen and (max-width: 1440px) {
                    &--5{
                        width: 17%;
                    }
                }
            }
        }

        @media(max-width: $mobile){
          flex-direction: column;
          max-height: 100%;
          padding: 0;
          border: 0.5px solid $border-light;
          box-sizing: border-box;
          box-shadow: $light-box-shadow;
          border-radius: 9px;
          margin: 10px;
          padding-top: 10px;

          &.c-list__content-header{
            display: none;
          }

          .c-list__grid-item {
            border: none;
            padding-bottom: 9px;
            width: 100% !important;
            min-height: 23px;

            .content-title {
              position: absolute;
              left: 50%;
            }

            .o-status {
              position: absolute;
              left: 50%;
              height: 17px;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            &:before {
              position: absolute;
              right: 50%;
              margin-right: 20px;
              color: $text-color;
            }

            &.grid-item{
              &--5 {
                padding-bottom: 0;
                margin-top: 15px;
                max-width: 100%;

                .c-content-item-options__wrapper{
                  justify-content: space-around;
                  border-top: 1px solid $background-menu;

                  .o-button{
                    background-color: transparent;
                    color: $text-color;
                    width: 100%;
                    border-radius: 0 0 0 20px;
                    margin: 0;

                    &[data-option="edit"]{
                      border-right: 1px solid $background-menu;

                      &:before{
                        content: "Edytuj";
                      }
                    }

                    &[data-option="remove"]{
                      &:before{
                        content: "Usuń";
                      }
                    }

                    .ico{
                      display: none;
                    }

                  }
                }
              }
            }
          }
      }
    }

    &--col-two{
        .c-list__grid-item{
            &.grid-item{

                &--1{
                    width: 25%;
                    min-width: 160px;
                    flex-grow: 1;
                }
                &--3{
                    width: 15%;
                    max-width: 145px;
                    display: flex;
                    justify-content: flex-end;
                    flex-grow: 0;
                }
            }
        }

      @media(max-width: $mobile){
        flex-direction: column;
        max-height: 100%;
        padding: 0;
        border: 0.5px solid $border-light;
        box-sizing: border-box;
        box-shadow: $light-box-shadow;
        border-radius: 9px;
        margin: 10px;
        padding-top: 10px;

        &.c-list__content-header{
          display: none;
        }

        .c-list__grid-item {
          border: none;
          padding-bottom: 9px;
          width: 100% !important;
          min-height: 23px;

          .content-title {
            position: absolute;
            left: 50%;
          }

          .o-status {
            position: absolute;
            left: 50%;
            height: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &:before {
            position: absolute;
            right: 50%;
            margin-right: 20px;
            color: $text-color;
          }

          &.grid-item{
            &--1{
              &:before{
                content: "Stanowisko";
              }
            }

            &--2 {
              padding-bottom: 0;
              margin-top: 15px;
              max-width: 100%;

              .c-content-item-options__wrapper{
                justify-content: space-around;
                border-top: 1px solid $background-menu;

                .o-button{
                  background-color: transparent;
                  color: $text-color;
                  width: 100%;
                  border-radius: 0 0 0 20px;
                  margin: 0;

                  &[data-option="edit"]{
                    border-right: 1px solid $background-menu;

                    &:before{
                      content: "Edytuj";
                    }
                  }

                  &[data-option="remove"]{
                    &:before{
                      content: "Usuń";
                    }
                  }

                  .ico{
                    display: none;
                  }

                }
              }
            }
          }
        }
      }
    }

    &--col-three{
        .c-list__grid-item{
            &.grid-item{

                &--1{
                    width: 25%;
                    min-width: 160px;
                    flex-grow: 0;
                }
                &--2{
                    flex-grow: 1;
                    min-width: 130px;
                    width: 36%;
                }
                &--3{
                    width: 15%;
                    max-width: 145px;
                    display: flex;
                    justify-content: flex-end;
                    flex-grow: 0;
                }
            }
        }

        @media(max-width: $mobile){
        flex-direction: column;
        max-height: 100%;
        padding: 0;
        border: 0.5px solid $border-light;
        box-sizing: border-box;
        box-shadow: $light-box-shadow;
        border-radius: 9px;
        margin: 10px;
        padding-top: 10px;

        &.c-list__content-header{
          display: none;
        }

        .c-list__grid-item {
          border: none;
          padding-bottom: 9px;
          width: 100% !important;
          min-height: 23px;

          .content-title {
            position: absolute;
            left: 50%;
          }

          .o-status {
            position: absolute;
            left: 50%;
            height: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &:before {
            position: absolute;
            right: 50%;
            margin-right: 20px;
            color: $text-color;
          }

          &.grid-item{
            &--3 {
              padding-bottom: 0;
              margin-top: 15px;
              max-width: 100%;

              .c-content-item-options__wrapper{
                justify-content: space-around;
                border-top: 1px solid $background-menu;

                .o-button{
                  background-color: transparent;
                  color: $text-color;
                  width: 100%;
                  border-radius: 0 0 0 20px;
                  margin: 0;

                  &[data-option="edit"]{
                    border-right: 1px solid $background-menu;

                    &:before{
                      content: "Edytuj";
                    }
                  }

                  &[data-option="remove"]{
                    &:before{
                      content: "Usuń";
                    }
                  }

                  .ico{
                    display: none;
                  }

                }
              }
            }
          }
        }
      }
    }

    &--col-four{
        .c-list__grid-item{
            &.grid-item{

                &--1{
                    width: 25%;
                    min-width: 160px;
                    flex-grow: 0;
                }
                &--2{
                    flex-grow: 1;
                    min-width: 130px;
                    width: 36%;
                }
                &--3{
                    flex-grow: 0;
                    width: 36%;
                }
                &--4{
                    width: 15%;
                    max-width: 145px;
                    display: flex;
                    justify-content: center;
                    flex-grow: 0;
                }
                @media only screen and (max-width: 1366px) {
                    &--4{
                        width: 17%;
                    }
                }
            }
        }

        @media(max-width: $mobile){
            flex-direction: column;
            max-height: 100%;
            padding: 0;
            border: 0.5px solid $border-light;
            box-sizing: border-box;
            box-shadow: $light-box-shadow;
            border-radius: 9px;
            margin: 10px;
            padding-top: 10px;

            &.c-list__content-header{
                display: none !important;
            }

            .c-list__grid-item {
                border: none;
                padding-bottom: 9px;
                width: 100% !important;
                min-height: 23px;

                .content-title {
                    position: absolute;
                    left: 50%;
                    width: 48%;
                  p{
                    color: $content-header-color;
                  }
                }

                .o-status {
                    position: absolute;
                    left: 50%;
                    height: 17px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &:before {
                    position: absolute;
                    right: 50%;
                    margin-right: 20px;
                    color: $text-color;
                }

                &.grid-item{
                    &--1{
                        &:before{
                            content: "Imię i nazwisko";
                        }
                    }
                    &--2{
                        a{
                            width: 100%;
                            height: 14px;
                        }
                        &:before{
                            content: "E-mail";
                        }
                    }
                    &--3{
                        &:before{
                            content: "Ostatnie logowanie";
                        }
                    }
                    &--4 {
                        padding-bottom: 0;
                        margin-top: 15px;
                        max-width: 100%;

                        .c-content-item-options__wrapper{
                            justify-content: space-around;
                            border-top: 1px solid $background-menu;

                            .o-button{
                                background-color: transparent;
                                color: $text-color;
                                width: 100%;
                                border-radius: 0 0 0 20px;
                                margin: 0;

                                &[data-option="edit"]{
                                    border-right: 1px solid $background-menu;

                                    &:before{
                                        content: "Edytuj";
                                    }
                                }

                                &[data-option="remove"]{
                                    &.o-button--acceptsZone{
                                      &:before{
                                        content: "";
                                      }
                                    }
                                    &:before{
                                        content: "Usuń";
                                    }
                                }

                                .ico{
                                    display: none;
                                }

                            }
                        }
                    }
                }
            }
        }
    }

    &--col-six{
        .c-list__grid-item{
            &.grid-item{

                &--1{
                    padding-left: 20px;
                    width: 15%;
                    min-width: 90px;
                    flex-grow: 0;
                }
                &--2{
                    width: 15%;
                    min-width: 90px;
                    flex-grow: 0;
                }
                &--3{
                    width: 10%;
                    min-width: 60px;
                }
                &--4{
                    width: 10%;
                    min-width: 90px;

                }
                &--5{
                    width: 20%;
                    flex-grow: 1;
                }
                &--6{
                    width: 20%;
                    flex-grow: 0;
                    max-width: 125px;
                }
            }
        }

      @media(max-width: $mobile){
        flex-direction: column;
        max-height: 100%;
        padding: 0;
        border: 0.5px solid $border-light;
        box-sizing: border-box;
        box-shadow: $light-box-shadow;
        border-radius: 9px;
        margin: 10px;
        padding-top: 10px;

        &.c-list__content-header{
          display: none;
        }

        .c-list__grid-item {
          border: none;
          padding-bottom: 9px;
          width: 100% !important;
          min-height: 23px;

          .content-title {
            position: absolute;
            left: 50%;
          }

          .o-status {
            position: absolute;
            left: 50%;
            height: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &:before {
            position: absolute;
            right: 50%;
            margin-right: 20px;
            color: $text-color;
          }

          &.grid-item{
            &--1{
              &:before{
                content: "Rodzaj urlopu";
              }
            }
            &--2{
              a{
                width: 100%;
                height: 14px;
              }
              &:before{
                content: "Dotyczy";
              }
            }
            &--3{
              &:before{
                content: "Ilość dni";
              }
            }
            &--4{
              &:before{
                content: "Pierwszy dzień";
              }
            }
            &--5{
              &:before{
                content: "Ostatni dzień";
              }
            }
            &--6 {
              padding-bottom: 0;
              margin-top: 15px;
              max-width: 100%;

              .c-content-item-options__wrapper{
                justify-content: space-around;
                border-top: 1px solid $background-menu;

                .o-button{
                  background-color: transparent;
                  color: $text-color;
                  width: 100%;
                  border-radius: 0 0 0 20px;
                  margin: 0;

                  &[data-option="edit"]{
                    border-right: 1px solid $background-menu;

                    &:before{
                      content: "Edytuj";
                    }
                  }

                  &[data-option="remove"]{
                    &:before{
                      content: "Usuń";
                    }
                  }

                  &[data-option="timer-play"]{
                    border-right: 1px solid $background-menu;

                    &:before{
                      content: "Rozpocznij";
                    }

                    &.change-title{
                      &:before{
                        content: "Zatrzymaj";
                      }
                    }
                  }

                  &[data-option="timer-reset"]{
                    border-right: 1px solid $background-menu;

                    &:before{
                      content: "Resetuj";
                    }
                  }

                  .ico{
                    display: none;
                  }

                }
              }
            }
          }
      }
    }
    }
    &--col-seven{
        .c-list__grid-item{
            &.grid-item{

                &--1{
                    padding-left: 10px;
                    width: 10%;
                    min-width: 100px;
                }
                &--2{
                    width: 10%;
                    min-width: 100px;
                }
                &--3{
                    width: 10%;
                    min-width: 100px;
                }
                &--4{
                    width: 10%;
                    min-width: 90px;
                    flex-grow: 0;
                }
                &--5{
                    width: 10%;
                    flex-grow: 0;
                }
                &--6{
                    width: 20%;
                }
                &--7{
                    width: 10%;
                    flex-grow: 0;
                    max-width: 125px;
                }
            }
        }

        @media(max-width: $mobile){
        flex-direction: column;
        max-height: 100%;
        padding: 0;
        border: 0.5px solid $border-light;
        box-sizing: border-box;
        box-shadow: $light-box-shadow;
        border-radius: 9px;
        margin: 10px;
        padding-top: 10px;

        &.c-list__content-header{
          display: none;
        }

        .c-list__grid-item {
          border: none;
          padding-bottom: 9px;
          width: 100% !important;
          min-height: 23px;

          .content-title {
            position: absolute;
            left: 50%;
          }

          .o-status {
            position: absolute;
            left: 50%;
            height: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &:before {
            position: absolute;
            right: 50%;
            margin-right: 20px;
            color: $text-color;
          }

          &.grid-item{
            &--7 {
              padding-bottom: 0;
              margin-top: 15px;
              max-width: 100% !important;

              .c-content-item-options__wrapper{
                justify-content: space-around;
                border-top: 1px solid $background-menu;

                .o-button{
                  background-color: transparent;
                  color: $text-color;
                  width: 100%;
                  border-radius: 0 0 0 20px;
                  margin: 0;

                  &[data-option="edit"]{
                    border-right: 1px solid $background-menu;

                    &:before{
                      content: "Edytuj";
                    }
                  }

                  &[data-option="remove"]{
                    &:before{
                      content: "Usuń";
                    }
                  }
                  &[data-option="permissions"]{
                    border-right: 1px solid $background-menu;
                    &:before{
                      content: "Uprawnienia";
                    }
                }

                  .ico{
                    display: none;
                  }

                }
              }
            }
          }
        }
      }
    }

    &--col-eight{

        .c-checkbox-wrapper{
            margin-right: 10px;
        }

        .c-list__grid-item{
            &.grid-item{

                &--1{
                    width: 20%;
                    min-width: 170px;
                    flex: 1 1 20%;
                    //padding-left: 20px;

                  @media(min-width: $mobile+1) and (max-width: $desktop){
                    padding-left: 0;
                  }
                }
                &--2{
                    width: 15%;
                    min-width: 100px;
                }
                &--3{
                    min-width: 100px;
                    width: 15%;
                }
                &--4{

                    min-width: 100px;
                    width: 15%;
                }
                &--5{
                    min-width: 100px;
                    width: 15%;
                }
                &--6{
                    min-width: 140px;
                    width: 15%;
                    flex: 0 0 15%;
                }
                &--7{
                    min-width: 100px;
                    width: 10%;
                    flex: 0 0 10%;
                }
                &--8{
                    width: 10%;
                    flex: 0 1 10%;
                    min-width: 135px;
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }

        @media(max-width: $mobile){
        flex-direction: column;
        max-height: 100%;
        padding: 0;
        border: 0.5px solid $border-light;
        box-sizing: border-box;
        box-shadow: $light-box-shadow;
        border-radius: 9px;
        margin: 10px;
        padding-top: 10px;

        &.c-list__content-header{
          display: none;
        }

        .c-list__grid-item {
          border: none;
          padding-bottom: 9px;
          width: 100% !important;
          min-height: 23px;

          .content-title {
            position: absolute;
            left: 50%;
          }

          .o-status {
            position: absolute;
            left: 50%;
            height: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &:before {
            position: absolute;
            right: 50%;
            margin-right: 20px;
            color: $text-color;
          }

          &.grid-item{
            &--8 {
              padding-bottom: 0;
              margin-top: 15px;
              max-width: 100% !important;

              .c-content-item-options__wrapper{
                justify-content: space-around;
                border-top: 1px solid $background-menu;

                .o-button{
                  background-color: transparent;
                  color: $text-color;
                  width: 100%;
                  border-radius: 0 0 0 20px;
                  margin: 0;

                  &[data-option="edit"]{
                    border-right: 1px solid $background-menu;

                    &:before{
                      content: "Edytuj";
                    }
                  }

                  &[data-option="remove"]{
                    &:before{
                      content: "Usuń";
                    }
                  }
                  &[data-option="permissions"]{
                    border-right: 1px solid $background-menu;
                    &:before{
                      content: "Uprawnienia";
                    }
                  }

                  .ico{
                    display: none;
                  }

                }
              }
            }
          }
        }
      }
    }

    &--col-ten{

      .c-checkbox-wrapper{
        margin-right: 10px;
      }

      .c-list__grid-item{
        &.grid-item{

          &--1{
            width: 12%;
            flex: 1 1 12%;

            @media(max-width: $desktop-l) {
              width: 13%;
              flex: 1 1 13%;
            }
          }
          &--2{
            width: 12%;
          }
          &--3{
            width: 10%;

            @media(max-width: $desktop-l) {
              width: 8%;
            }
          }
          &--4{
            width: 10%;
          }
          &--5{
            width: 10%;
          }
          &--6{
            width: 10%;
            flex: 0 0 10%;
          }
          &--7{
            width: 8%;
            flex: 0 0 8%;

            @media(max-width: $desktop-l) {
              width: 6%;
              flex: 0 0 6%;
            }
          }
          &--8{
            width: 8%;
            flex: 0 0 8%;
            justify-content: center;

            @media(max-width: $mobile) {
              display: none;
            }
          }
          &--9{
            width: 8%;
            flex: 0 0 8%;
            justify-content: center;

            @media(max-width: $mobile) {
              display: none;
            }
          }
          &--10{
            width: 12%;
            flex: 0 1 12%;
            display: flex;
            justify-content: flex-end;

            @media(max-width: $desktop-l) {
              width: 15%;
              flex: 0 1 15%;
            }
          }
        }
      }

      @media(max-width: $mobile){
        flex-direction: column;
        max-height: 100%;
        padding: 0;
        border: 0.5px solid $border-light;
        box-sizing: border-box;
        box-shadow: $light-box-shadow;
        border-radius: 9px;
        margin: 10px;
        padding-top: 10px;

        &.c-list__content-header{
          display: none;
        }

        .c-list__grid-item {
          border: none;
          padding-bottom: 9px;
          width: 100% !important;
          min-height: 23px;

          .content-title {
            position: absolute;
            left: 50%;
          }

          .o-status {
            position: absolute;
            left: 50%;
            height: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &:before {
            position: absolute;
            right: 50%;
            margin-right: 20px;
            color: $text-color;
          }

          &.grid-item{
            &--10 {
              padding-bottom: 0;
              margin-top: 15px;
              max-width: 100% !important;

              .c-content-item-options__wrapper{
                justify-content: space-around;
                border-top: 1px solid $background-menu;

                .o-button{
                  background-color: transparent;
                  color: $text-color;
                  width: 100%;
                  border-radius: 0 0 0 20px;
                  margin: 0;

                  &[data-option="edit"]{
                    border-right: 1px solid $background-menu;

                    &:before{
                      content: "Edytuj";
                    }
                  }

                  &[data-option="remove"]{
                    &:before{
                      content: "Usuń";
                    }
                  }
                  &[data-option="permissions"]{
                    border-right: 1px solid $background-menu;
                    &:before{
                      content: "Uprawnienia";
                    }
                  }

                  .ico{
                    display: none;
                  }

                }
              }
            }
          }
        }
      }
    }


    &--inside{
        &.c-list__grid--col-seven{
            .c-list__grid-item{
                &.grid-item{
                    &--1{
                        padding-left: 20px;
                        width: 20.2%;
                        min-width: 100px;
                        flex: 0 0 20.2%;
                    }
                    &--2{
                        min-width: 100px;
                        width: 10%;
                    }
                    &--3{

                        min-width: 100px;
                        width: 10%;
                    }
                    &--4{
                        min-width: 100px;
                        width: 10%;
                    }
                    &--5{
                        min-width: 100px;
                        width: 12%;
                    }
                    &--6{
                        min-width: 100px;
                        width: 10%;
                    }
                    &--7{
                        width: 20%;
                        flex: 0 0 20%;
                        max-width: 140px;

                    }
                }
            }
        }
    }

}
