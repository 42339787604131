.c-input-calendar__wrapper{
    position: relative;
    &.calendar-icon{
        .flatpickr-wrapper{
            z-index: 2;
        }
        &:after {
            content: '';
            position: absolute;
            width: 14px;
            height: 14px;
            background-image: ico-generator(calendarEmpty, $main-color);
            background-image: ico-generator(calendarEmpty, var(--main-color, $main-color));
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            right: 10px;
            //top: calc(50% - 20px);
            top: 8px;
            transform: translateY(50%);
            z-index: 1;
        }
    }
    @media(max-width: $mobile){
        &:after{
            top: calc(50% - 0px) !important;
        }
    }
}

.flatpickr-input-icon{
    position: absolute;
    width: 14px;
    height: 14px;
    background-image: ico-generator(calendarEmpty, $main-color);
    background-image: ico-generator(calendarEmpty, var(--main-color, $main-color));
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    right: 10px;
    //top: calc(50% - 20px);
    top: 7px;
    transform: translateY(50%);
    z-index: 1;
    cursor: pointer;
}
.flatpickr-input--range{
    padding-right: 30px;
}
@media only screen and (max-width: 1600px) {
    .flatpickr-input--range{
        //width: 125px;
    }
}
@media only screen and (max-width: 1366px) {
    .flatpickr-input--range{
        width: 125px;
    }
}