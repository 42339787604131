.u-flex{
    display: flex;

    &--column{
        flex-direction: column !important;
    }

    &--nw{
        flex-wrap: nowrap !important;
    }
}

.u-main-header{
    font-size: remy($main-header-regular);
}


/* icons */

.ico{
    width: 100%;
    height: 100%;
    display: inline-flex;
    font-family: 'future-fonts' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    text-align: center;
    align-items: center;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;


    &-msg{
        &:before {
            content: $icon-ico-msg;
            color: rgb(104,94,129);
        }
        &:hover{
            color: $main-color;
            color: var(--main-color, $main-color);
        }
    }
    &-notifications{
        &:before {
            content: $icon-ico-notif;
            color: rgb(104,94,129);
        }
        &:hover{
            color: $main-color;
            color: var(--main-color, $main-color);
        }

    }
    &-arrow{
        &:before {
            content: $icon-arrow;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
        &:hover{
            color: $main-color;
            color: var(--main-color, $main-color);
        }
    }
    &-settings{
        &:before {
            content: $icon-ico-settings;
            color: rgb(106,109,114);
        }
    }
    &-info{
        &:before {
            content: $icon-ico-info;
            color: rgb(106,109,114);
        }
    }
    &-logout{
        &:before {
            content: $icon-ico-logout;
            color: rgb(106,109,114);
        }
    }
    &-plus{
        &:before {
            content: $icon-ico-plus-light;
            color: rgb(255,255,255);
        }
    }
    &-export{
        &:before {
            content: $icon-ico-export;
            color: rgb(255,255,255);
        }
    }
    &-calendar{
        &:before {
            content: $icon-menu-rezerwacje;
            color: rgb(255,255,255);
        }
    }
    &-calendarEmpty{
        &:before {
            content: $icon-ico-calendar-empty;
            color: rgb(255,255,255);
        }
    }
    &-lock{
        &:before {
            content: $icon-lock;
            color: rgb(255,255,255);
        }
    }
    &-arrow-back{
        &:before {
            content: ico-generator(arrowBack, $main-color);
            //content: $icon-arrowBack;
            //color: $text-color;
        }
    }
    &-future{
        &:before {
            content: $icon-menu-futureDesign;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
    }
    &-inwestorzy{
        &:before {
            content: $icon-menu-inwestorzy;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
    }
    &-remove{
        &:before {
            content: $icon-remove;
            color: $text-color;
        }
    }
    &-pracownicy{
        &:before {
            content: $icon-menu-pracownicy;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
    }
    &-skoczki{
        &:before {
            content: $icon-menu-skoczki;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
    }
    &-parki{
        &:before {
            content: $icon-menu-parki;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
    }
    &-strefy{
        &:before {
            content: $icon-menu-strefy;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
    }
    &-rezerwacje{
        &:before {
            content: $icon-menu-rezerwacje;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
    }
    &-rozliczenia{
        &:before {
            content: $icon-menu-rozliczenia;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
    }
    &-uprawnienia{
        &:before {
            content: $icon-menu-uprawnienia;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
    }
    &-zarzadzanieRezerwacjami{
        &:before {
            content: $icon-menu-zarzadzanieRezerwacjami;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
    }
    &-artykuly{
        &:before {
            content: $icon-menu-artykuly;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
    }
    &-czat{
        &:before {
            content: $icon-menu-czat;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
    }
    &-listaZakupowa{
        &:before {
            content: $icon-menu-listaZakupowa;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
    }
    &-serwis{
        &:before {
            content: $icon-menu-serwis;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
    }
    &-sprawdzanieBiletow{
        &:before {
            content: $icon-menu-sprawdzanieBiletow;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
    }
    &-jezyki{
        &:before {
            content: $icon-menu-jezyki;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
    }

    &-play{
        &:before {
            content: $icon-ico-play;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
    }
    &-play{
        &:before {
            content: $icon-ico-play;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
    }
    &-stop{
        &:before {
            content: $icon-ico-stop;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
    }
    &-edit{
        &:before {
            content: $icon-ico-edit;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
    }
    &-cancel{
        &:before {
            content: $icon-close-ico;
            //content: $icon-ico-reset-red;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
    }
    &-cancelround{
        &:before {
            content: $icon-cacnelRound-path1;
            color: $error-color;
        }
    }
    &-correctround{
         &:before {
             content: $icon-correctround-path1;
             color: $success-color;
         }
     }

    &-warning{
        background-image: url(../images/warning-ico.svg);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    &-warning-orange{
        //background-image: url(../images/warning-ico.svg);
        background-image: ico-generator(warning, $warning-color);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    &-service{
        &:before {
            content: $icon-service;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
    }
    &-werehouse{
        &:before {
            content: $icon-werehouse;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
    }
    &-promotion{
        &:before {
            content: $icon-percent;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
    }

    &-search{
      &:before {
        content: $icon-search-ico;
        color: rgba(255,255,255, 0.99);
      }
    }

    &-send{
        &:before {
            content: $icon-send-button;
            color: $text-color;
            color: var(--font-color, $text-color);
        }
    }

}

.u-preview{
    width: 100%;
    text-align: right;
}
.c-form-aside .c-input-file__wrapper {

    a.u-preview{
        width: 100%;
        text-align: right;
    }
}


