.d-flex{
  display: flex;
}

.f-column{
  flex-direction: column;
}

.f-between{
  justify-content: space-between;
}

.flex-center{
  display:flex;
  justify-content: center;
  align-items:center;
}

.flex-start{
  @extend .flex-center;
  justify-content: flex-start;
}

.flex-space-between{
  @extend .flex-center;
  justify-content: space-between;
}

.flex-end{
  @extend .flex-center;
  justify-content: flex-end;
}