.filter__wrapper {
    >.c-input-search__wrapper {
        position: relative;
        margin: 10px 0;

        &::after {
            position: absolute;
            content: '';
            width: 16px;
            height: 16px;
            background-image: ico-generator(search, $main-color);
            background-image: ico-generator(search, var(--main-color, $main-color));
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            right: 10px;
            top: 50%;
            transform: translateY(-50%) rotate(0.001deg);
            cursor: pointer;
        }

        @media (max-width: $mobile) {
            width: 100%;
            margin: 0;
        }

        @media (min-width: $mobile) and (max-width: $desktop) {
            width: 100%;
            max-width: 300px;
        }

    }
}

#adminList{
    .c-input-search__wrapper{
        @media (max-width: $mobile){
            width: 100%;
        }
    }
}