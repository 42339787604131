.static-filter{
  display: flex;
  width: 100%;
  align-items: center;

  @media(max-width: $desktop) {
    input::placeholder {
      font-size: 10px;
    }
  }

  .statistic-date{

    input{
      width: 200px;
      background-color: #fff;

      @media(max-width: $tablet){
        width: 100%;
      }
    }
  }

  .filter--button{
    margin-top: 5px;
    margin-left: auto;
    height: 32px;
    max-height: 32px;
    margin-bottom: 20px;
  }

  .c-input__wrapper{
    margin-bottom: 0;
    margin-right: 14px;
  }

  @media(max-width: $mobile){
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 10px;
    padding: 0 10px;

    .c-input__wrapper{
      width: 100%;
      padding-bottom: 0;
      margin-right: 0;

      &.c-input-calendar__wrapper{
        .flatpickr-input{
          width: 100%;
        }
        &:after{
          top: 20%;
        }
      }
      &:after{
        top: 50%;
      }
    }

    .filter--button{
      display: block;
      grid-column: 2;
      margin-bottom: 0;
    }
  }

  @media(min-width: $mobile+1) and (max-width: $desktop){
    //margin: 0 10px;
    //width: calc(100% - 20px);
    width:100%;
  }

}