#areaList{

  .filters{
    flex-wrap: nowrap;

    .filter__wrapper{
      width: auto;
      min-width: auto;
      padding-left: 10px;

      .o-input-search{
        width: auto;
      }

    }
    .filter__wrapper{
      width: auto;
      margin-bottom: 0;

      .filter--button{
        //display: block;
      }
    }
  }

  .device-count{
    margin-left: 5px;
  }

  @media(max-width: $mobile) {
    .filters{
      .filter__wrapper:nth-child(1){
        width: 100%;
        .c-input-search__wrapper{
          width: 100%;

          input{
            width: 100%;
          }
        }
      }
      //.filter__wrapper:nth-child(2){
      //  width: 100%;
      //  margin-bottom: 15px;
      //}
    }

    .c-list__grid--col-four {
      .grid-item {
        &--1 {
          &:before {
            content: "Nazwa";
          }

          .o-button--show-more {
            width: 100%;
            height: 25px;
            z-index: 1;

            .ico{
              &:before{
                content: "";
              }
            }
          }
        }

        &--2 {
          display: none;

          &:before {
            content: "";
          }
        }

        &--3 {
          display: none;

          &:before {
            content: "";
          }
        }

        &--4 {
          &:before {
            content: "";
          }

          .o-button[data-option="add"] {
            border-right: 1px solid $background-menu;

            &:before {
              content: "Dodaj urządzenie";
            }
          }
        }
      }
    }

    .aside__content{
      .c-input-file__wrapper {
        .o-input {
          width: 100%;
          max-height: 32px;
          display: flex;
          align-items: center;
        }
      }
    }

    .c-list__grid--col-three {
      flex-direction: column;
      max-height: 100%;
      padding: 0;
      border: 0.5px solid $border-light;
      box-sizing: border-box;
      box-shadow: $light-box-shadow;
      border-radius: 9px;
      margin: 10px;
      padding-top: 10px;

      .c-list__grid-item {
        border: none;
        padding-bottom: 9px;
        width: 100% !important;
        min-height: 23px;

        .content-title {
          position: absolute;
          left: 50%;
        }

        &:before {
          position: absolute;
          right: 50%;
          margin-right: 20px;
          color: $text-color;
        }

        &.grid-item {
          &--1 {
            &:before {
              content: "Nazwa";
            }
          }
          &--2 {
            display: none;
          }
          &--3 {
            padding-bottom: 0;
            margin-top: 15px;
            max-width: 100%;

            .c-content-item-options__wrapper {
              justify-content: space-around;
              border-top: 1px solid $background-menu;

              .o-button {
                background-color: transparent;
                color: $text-color;
                width: 100%;
                border-radius: 0 0 0 20px;
                margin: 0;

                &[data-option="edit"] {
                  border-right: 1px solid $background-menu;

                  &:before {
                    content: "Edytuj";
                  }
                }

                &[data-option="remove"] {
                  &:before {
                    content: "Usuń";
                  }
                }

                .ico {
                  display: none;
                }

              }
            }
          }
        }
      }
      }
    .c-thumbnail__wrapper{
      border: 1px solid rgba(111,54,129,.99);
      position: absolute;
      top: -24px;
      left: 43%;
      border-radius: 50%;
      width: 51px;
      height: 51px;
      background-color: white;
    }
    .c-list__content-item__wrapper {
      margin-top: 30px;

      .c-list__content-item {
        padding-top: 45px;
      }
    }

    }
}