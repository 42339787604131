//Colors


/* Main theme */

$main-color: rgba(111, 54, 129, 0.99);

$text-color: rgba(104,94,129, 0.99);
$text-light-color: #fff;
$content-header-color: #3D3D3D;
$button-light-text: #ffffff;

$button-hover: rgba(111, 54, 129, 0.8);
$option-button:  rgba(104,94,129,0.2);

$placeholder-color: #8F8F8F;
$input-text-color: #3D3D3D;
$label-color: #6A6D72;
$select-color: rgb(246, 231, 250);
$search-background: #EAEFF4;
$background-unread: #e0e0e0;

$background-content: #FFFFFF;
$background-main: #F8FAFC;
$background-menu: #E7E3EE;


$error-color: #FD3030;
$remove-color: #DB384F;
$success-color: #57C553;
$successSwitch-color: #A0CF67;
$warning-color: #FD8609;

$error-color-stat: #e63756;
$success-color-stat: #25b33c;
$warning-color-stats: #E38E0D;
$info-color-stats: #434cf0;

$background-input: #795F83;

$border-light: #CBCBCB;

$button-delete-mobile: #FF8F8F;
$button-delete-text-mobile: #681919;


