.o-thumbnail{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 50%;

    &.no-photo{
        cursor: default;
    }
}

.lightBox{
    &__overlay{
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        text-align: center;
        top: 0;
        left: 0;
        z-index: 1000;
    }

    &__wrapper{
        background: white;
        width: auto;
        //height: auto;
        //height: 100%;
        padding: 20px;
        padding-top: 50px;
        text-align: center;
        display: inline-block;
        margin: 100px auto;
        max-width: 60%;
        max-height: 80%;
        margin-top: 25%;
        transform: translateY(-50%);
        position: relative;

        img{
            //height: -webkit-fill-available;
            object-fit: contain;
            height: 100%;
            width: auto;
        }

        @media(max-width: $desktop){
            top: 30%;
            height: auto;
        }
    }

    &__close{
        position: absolute;
        top: 15px;
        right: 20px;
        width: auto;
        height: auto;
        cursor: pointer;
    }
}

.popup{
    &__overlay{
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        text-align: center;
        top: 0;
        left: 0;
        z-index: 999;
    }

    &__wrapper{
        background: white;
        width: 550px;
        height: auto;
        margin: 100px auto;
        margin-top: 25%;
        transform: translateY(-50%);
        border-radius: 9px;
        overflow: hidden;
        position: relative;
        display: flex;
        box-shadow: 0 3px 14px 0 rgba(158,143,190,.57);
        border: 1px solid #cbcbcb;
    }

    &__content{
        position: relative;
        height: 100%;
        width: 100%;
        transition: .3s ease-in-out;

        &__wrapper{
            padding: 25px 0;
            overflow-y: auto;

            &-name{
                display: flex;
                flex-wrap: wrap;
                margin: 0 40px;
                position: relative;

                .header-name{
                    font-size: remy($header-bigger);
                    color: $text-color;
                    z-index: 200;
                    background-color: #fff;
                    padding-right: 15px;
                    position: relative;
                }
            }

            .group-header{
                margin: 30px 40px;

                .header-name{
                    font-size: remy($aside-header);
                    font-weight: 400;
                }
            }
        }
    }

    &__header{
        border-bottom: 1px solid #b4afc1;
        padding: 35px 40px 5px 40px;
        padding-bottom: 5px;
        height: 100px;

        &-options__wrapper{
            display: flex;
            justify-content: space-between;


        }
    }

    &__filters__wrapper{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex: 1 1 auto;

        &-name{
            width: 30%;
            display: flex;
            flex-wrap: wrap;

            .header-name{
                font-size: 20px;
                color: $text-color;
            }
        }

        .filters{
            width: 70%;
            display: flex;
            flex-wrap: wrap;

            .filter__wrapper{
                width: 100%;
                display: flex;
                align-items: center;

                .popup__search{
                    width: 100%;
                }
            }
        }
    }

    &__usersgroups{
        margin-top: 30px;

        &__item{
            position: relative;
            padding: 15px 12px 15px 40px;
            text-align: left;
            cursor: pointer;

            .name{
                font-size: remy($header-regular);
                font-weight: 500;
            }

            &.is-active{
                background: linear-gradient(rgba($main-color,0.05) 100%, rgba($main-color,0) 100%);
                position: relative;
                z-index: 150;

                .name {
                }
            }
        }
    }

    &__footer{
        position: relative;
        width: 100%;
        margin-top: 50px;
        padding-right: 40px;

        .button__wrapper{
            justify-content: flex-end;
        }

        .o-button{
            margin-left: 25px;
        }
    }

    &__close{
        position: absolute;
        top: 15px;
        right: 20px;
        width: auto;
        height: auto;
        cursor: pointer;
    }
}