.o-input-checkbox{
    opacity: 0;
    height: 0;
    width: 0;
    display: block;
    position: absolute;

    & + .o-label-checkbox{
        position: relative;
        display: inline-block;
        color: $main-color;
        color: var(--main-color, $main-color);;
        cursor: pointer;
        padding-left: 25px;
        font-size: remy($login-button-anchor);

        &::before{
            position: absolute;
            content:'';
            display: block;
            width: 14px;
            height: 14px;
            left: 0;
            border: 1px solid $main-color;
            border: 1px solid var(--main-color, $main-color);;
            border-radius: 2px;
            transition: .2s ease-out;
        }

        a{
            text-decoration: underline;
            color: $main-color;
            color: var(--main-color, $main-color);
            font-size: remy($login-button-anchor);
        }
    }



    &:checked + .o-label-checkbox{
        &::before{
            background-color: $main-color;
            background-color: var(--main-color, $main-color);;
            background-image: url(../images/check.svg);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
    //&:focus + .o-label-checkbox{
    //    text-decoration: underline;
    //}
}