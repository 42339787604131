#language-list{

  .filters{
    flex-wrap: unset;

    .filter__wrapper{
      width: auto;

      @media(max-width: $mobile) {
        width: 100%;
      }
    }
  }

  .c-list__content-header{
    display: flex;
  }

      .o-button-sort__wrapper {
        position: absolute;
        top: 8px;
      }

    .option-wrapper{
      justify-content: flex-end;
      text-align: center;
    }

    .grid-item--1{
      width: 90%;
      //padding-left: 15px;
    }
    @media only screen and (max-width: 1600px) {
      .grid-item--1{
        width: 86%;
      }
    }
    @media only screen and (max-width: 1366px) {
      .grid-item--1{
        width: 84%;
      }
    }
    @media only screen and (max-width: 1205px) {
      .grid-item--1{
        width: 70%;
      }
    }

    .grid-item--2{
      width: auto;
    }

    .lang-sort1{
      padding-top: 10px;
      position: relative;
    }

    .lang-sort2{
      padding-top: 10px;
      width: 30%;
      text-align: right;
      position: relative;
      padding-right: 15px;
    }

    .c-list__content-item{
      padding-left: 10px;

      @media(min-width: $mobile+1) and (max-width: $desktop){
        padding-left: 10px;
      }
    }

    .left-main-title{
      //padding-left: 24px;
      @media(min-width: $mobile+1) and (max-width: $desktop){
        padding-left: 0;
      }
    }
    
    .lvl2{
      display: none;
      
      .c-list__content-item{
        display: flex;
        margin-left: 0;
        cursor: pointer;
      }

      .grid-item--1{
        width: 88%;
        margin-left: 2%;
      }
      @media only screen and (max-width: 1600px) {
        .grid-item--1{
          width: 84%;
        }
      }
      @media only screen and (max-width: 1366px) {
        .grid-item--1{
          width: 82%;
        }
      }
      @media only screen and (max-width: 1205px) {
        .grid-item--1{
          width: 78%;
        }
      }

      .grid-item--2{
        width: auto;
      }
    }

    .button__wrapper{
      width: 100%;
      text-align: right;
      justify-content: flex-end;

      .o-button{
        margin-left: 15px;
      }

      #mobile-clicker{
        display:none;
      }
      @media(max-width:$mobile){
        width: 0;
      }
    }
  }

.aside__content{
    padding-top: 0;

    .button__wrapper{
      padding-bottom: 40px;

      .o-button{
        margin-right: 15px;
        padding: 7px 20px;
      }

      .o-input{
        width: 110px;
      }

      .new-language{
        display: none;
        margin-right: 10px;
      }

      .o-button--option{
        position: relative;
        padding: 0;
        margin-left: 0;

        .ico-plus{
          margin-right: 0;
          width: 14px;
          height: 14px;
          position: absolute;
          top: 50%;
          left: 50%;
          &::before{
            color:$text-color;
            color: var(--font-color,$text-color);
          }
        }

      }
      .o-button--option:hover{

        .ico-plus{
          &::before{
            color: white;
          }
        }
      }

      .button-cancel{
        background-color: #fff;
        margin-right: 0;
        width: 30px;
        display: none;

        .ico-cancelround{
          margin-right: 0;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          background-image: ico-generator(cancelround, $text-light-color);
        }
      }
      .button-cancel:hover{
        opacity: 0.7;
      }

      .button-correct{
        background-color: #fff;
        margin-right: 0;
        width: 30px;
        display: none;

        .ico-correctround{
          margin-right: 0;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          background-image: ico-generator(correctround, $text-light-color);
        }
      }
      .button-correct:hover{
        opacity: 0.7;
      }
    }
  }

@media(max-width: $mobile){
  #language-list {
    .c-list__content-header {
      display: none !important;
    }

    .c-list__content-item{
      .grid-item--1{
        .content-title{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 49%;
        }
      }
    }

    .c-list__grid--col-four {
      padding-left: 0;

        .c-list__grid-item{

          &.grid-item{
            &--1{
              &:before{
                content: "Język";
              }
              .o-button--show-more{
                top: 0px;
                position: absolute;
                width: 100%;
                height: 46px;

                .ico{
                  display: none;
                }
              }
            }
            &--2 {

              &:before{
                content: "";
              }

              padding-bottom: 0;
              margin-top: 15px;
              max-width: 100%;
              border-top: 1px solid $background-menu;

              //.c-content-item-options__wrapper{
              //  justify-content: space-around;
              //  border-top: 1px solid $background-menu;

                .o-button{
                  background-color: transparent;
                  color: $text-color;
                  width: 100%;
                  border-radius: 0 0 0 20px;
                  margin: 0;

                  &[data-option="edit"]{
                    border-right: 1px solid $background-menu;

                    &:before{
                      content: "Edytuj";
                    }
                  }

                  .ico{
                    display: none;
                  }

                }
              //}
            }
          }
        }
    }

    .lvl2{
      margin: 0 20px;
    }

    .c-list__grid--col-three{
      padding: 0;
      margin-left: 2% !important;

      .grid-item--1{
        &:before{
          content: "Słowo";
        }
      }

      .grid-item--2{
        &:before{
          content: "Data modyfikacji";
        }
      }
    }

    .button__wrapper{
      #mobile-language, #mobile-translate{
        display: none;
      }
      #mobile-clicker{
        z-index: 111;
        height: 45px;
        display: block;

        .ico{
          margin-top: 5px;
        }
      }
    }
  }
}

@media (min-width: $desktop+1){
  .c-content__wrapper{
    margin-top: 40px;
  }
}