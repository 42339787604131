#c-content__container{
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
}

.c-content__wrapper{
    position:relative;
    background-color: var(--widget-color,$background-content);
    box-shadow: 0px 3px 14px #DDD7E7;
    //box-shadow: 0 3px 14px 0 rgba(158,143,190,0.57);
    border: 1px solid #CBCBCB;
    border-radius: 9px;
    margin: 10px 0;
    width: 100%;
    min-height: 210px;

    @media print {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        margin: 0;
    }

    @media print and (orientation: portrait) {
        border: none;
        box-shadow: none;
    }

    @media print and (orientation: landscape) {
        border: none;
        box-shadow: none;
    }

    .c-content{
        position: relative;
        height: 100%;
        transition: .3s ease-in-out;
    }

    &--50{
        width: 50%;
    }
    &--25{
        width: 25%;
    }

    .content__header{
        font-size: remy($header-regular);
        color: $text-color;
        color:  var(--font-color, $text-color);
        font-weight: 500;
    }

    @media(max-width: $desktop){
        margin: 0;
        border: none;
        box-shadow: none;
    }

    @media(min-width: $mobile+1) and (max-width: $desktop){
        background-color: unset;
    }
}

