.c-main-nav__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    position: relative;
    background-color: $background-menu;
    background-color: var(--menu-color, $background-menu);
    padding: 15px 0;
    transition: .2s ease-out;
    transform: translateX(0);
    min-height: 100vh;
    width: 60px;
    overflow-x: hidden;
    z-index: 1000;
    flex-direction: column;

    .start-page__link{
      opacity: 0;
      min-width: 180px;
    }

    .o-main-nav__item{
        &.is-active{
            background-color: rgba($main-color, .1);
            background-color: rgba(var(--main-color, $main-color), .1);
        }
    }

    .o-main-nav__link{
      opacity: 0;
      max-height: 41px;
    }

    .c-main-nav__submenu{

    }

    @media(min-width: $mobile) {
      &.small__menu {
        .o-main-nav__item {

          .ico {
            margin-left: -30px;
          }

          .o-main-nav__link {
            opacity: 1;

            .nav-title {
              opacity: 0;
              transition: opacity 0.1s;
            }
          }
        }
      }
    }

    @media print {
      display: none;
    }

    &.is-active{
        transform: translateX(0);
        width: 270px;

        .o-main-nav__item{
          &.is-active{
              background-color: rgba($main-color, 0);
              background-color: rgba(var(--main-color, $main-color), 0);
          }
        }

        .o-main-nav__link{
          opacity: 1;

          .nav-title{
            opacity: 1;
            transition: opacity .5s;
          }
        }

        .start-page__link{
          opacity: 1;
        }

      @media(min-width: $mobile+1) and (max-width: $desktop){
        position: fixed;
      }
      @media(min-width: $mobile+1) and (max-width: $desktop-l){
        width: 240px;
      }
    }

    .start-page__link{
        height: 100px;
        width: 70%;
        margin-top: 35px;
        margin-left: 40px;
        position: relative;

      @media(min-width: $mobile+1) and (max-width: $desktop-l){
        width: 73%;
      }
    }

    @media(max-width: $mobile){
        position: fixed;
        width: 0;
    }

    @media(min-width: $mobile+1) and (max-width: $desktop){
      position: fixed;
    }
}

.c-main-nav__submenu{
    margin-left: 40px;
    margin-top: 5px;
    border-left: 1px solid $main-color;
    border-left: 1px solid var(--main-color, $main-color);
    display: none;

    &.is-hidden{
      display: none !important;
    }

    .o-main-nav__item{
        padding: 0 10px;
    }
    .o-main-nav__link{

        padding-left: 10px;
    }


}

@media(max-width: $mobile){
  .o-open-btn{
    position: fixed;
    z-index: 11111;
  }
  .c-main-nav__container{
    z-index: 1000;

    .start-page__link{
      margin-top: 0;
      margin-left: 50px;

      .o-main-logo{
        height: 80%;
      }
    }

    .o-main-nav__wrapper{
      margin-top: 20px;
      //overflow-y: auto;
      //overflow-x: hidden;
      //max-height: 500px;
      width: 100%;

      .button--option{
        right: 77px;
      }
      //
      //&::-webkit-scrollbar{
      //  display: none;
      //}
    }
  }
}