#usersList, #parksList{

  .filters{
    .filter__wrapper{
      width: auto;
      min-width: auto;

      >.c-input-search__wrapper {
        .o-input-search {
          width: auto;
        }
      }

      .c-input__wrapper{
        padding-left: 10px;

        >label{
          width: 100%;

          @media(min-width: $mobile+1) and (max-width: $desktop-l){
            width: min-content;
          }
        }
      }
    }
  }

  @media (max-width: $mobile){
    .filters{
      flex-wrap: unset;

      .filter__wrapper:nth-child(1){
        width: 100%;
        min-width: auto;

        .c-input-search__wrapper{
          width: 100%;

          input{
            width: 100%;

          }
        }
      }
    }

    //avatar na kafelku - rwd
    .c-list__content-item__wrapper{
      margin-top: 30px;

      .c-list__content-item{
        padding-top: 45px;

        .o-avatar, .o-thumbnail{
          border: 1px solid $main-color;
          position: absolute;
          top: -24px;
          left: 43%;
          border-radius: 50%;
          width: 51px;
          height: 51px;
          background-color: $background-content;
        }
        .c-thumbnail__wrapper{
          height: unset;
        }
      }
    }
    form{
      .c-input-file__wrapper{
        flex-direction: unset;
        margin-top: 9px;
      }

      .c-input-group{
        flex-direction: column;

        .input-date__wrapper{
          width: 100%;
          flex-direction: unset;

          .c-select__wrapper:after{
            top: 34%;
          }
        }
      }
    }
  }
  @media(min-width: $mobile+1) and (max-width: $desktop) {
    .c-list__content__wrapper{
      overflow: auto;

      .c-list__content-header, .c-list__content{
        width: 100%;
      }
    }
  }
}

#usersList{
  @media (max-width: $mobile) {
    .grid-item {
      &--1 {
        &:before {
          content: "Imię i nazwisko";
        }
      }

      &--2 {
        &:before {
          content: "Ilość wizyt";
        }
      }

      &--3 {

        a {
          width: 100%;
          height: 14px;
        }

        &:before {
          content: "Email";
        }
      }

      &--4 {
        &:before {
          content: "Ostatnia wizyta";
        }
      }
    }
  }
}

#parksList{
  @media (max-width: $mobile) {
    .grid-item {
      &--1 {
        &:before {
          content: "Nazwa";
        }
      }

      &--2 {
        &:before {
          content: "Lokalizacja";
        }
      }

      &--3 {
        &:before {
          content: "Osoba kontaktowa";
        }
      }

      &--4 {
        &:before {
          content: "Klient";
        }
      }
    }

    .c-form-aside {
      .c-input-file__wrapper {
        .o-input {
          width: 100%;
          max-height: 32px;
          display: flex;
          align-items: center;
        }
      }
      .c-input-group{
        .address-inputs__group{
          flex-direction: unset;
          width: 100%;
        }
      }
    }
  }
}