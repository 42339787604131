.grid-block-tiles {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  grid-gap: 20px;

  @media(max-width: $mobile) {
    grid-template-columns: repeat(2,1fr);
    padding: 10px 10px 0;
    grid-gap: 10px;
  }

  @media(min-width: $mobile+1) and (max-width: $tablet) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media(min-width: $mobile+1) and (max-width: $desktop){
    grid-gap: 10px;
  }

  &.three-blocks{
    grid-template-columns: repeat(3, 1fr);
  }

}
.chart-container {
  .c-customers-count {
    height: unset;
    min-height: auto;

    &.custom-tiles {
      height: 36%;

      .number-tiles {
        bottom: 26%;
        position: relative;
      }
    }

    .c-content {
      padding: 27px 35px;
      flex-direction: column;

      .content__header {
        margin-bottom: 0;
        cursor: default;

        a:hover {
          cursor: default;
        }

        .c-input-search__wrapper {
          margin: 0 !important;
        }

        @media(max-width: $tablet) {
          line-height: 16px;
          height: 32px;

        }
      }

      .number-tiles, .number-subtitle {
        font-weight: 300;
        color: $main-color;
        margin-top: 20px;

      }

      .number-tiles {
        font-size: 45px;
        position: unset;

        &:hover{
          cursor: default;
        }

        @media(max-width: $tablet) {
          font-size: 30px;
        }
        @media(min-width: $tablet+1) and (max-width: $desktop-l) {
          font-size: 37px;
        }
      }

      .number-subtitle {
        font-size: 27px;
        margin-left: 10px;
        align-items: flex-end;
        display: flex;

        @media(max-width: $tablet) {
          display: flex;
          align-items: flex-end;
          font-size: 24px;
        }
      }

      @media(max-width: $tablet) {
        padding: 10px;
        border: 1px solid $border-light;
        border-radius: 9px;
        height: auto;
        background-color: #fff;
      }

      @media(min-width: $tablet+1) and (max-width: $desktop) {
        padding: 0;
      }
    }

    @media(max-width: $tablet) {
      margin-top: 0;
      box-shadow: none !important;
      border: none !important;
    }
    @media(min-width: $tablet+1) and (max-width: $desktop) {
      box-shadow: none !important;
    }
  }
}
