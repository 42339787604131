.c-avatar-input{
  align-items: center;
}

.avatar-output{
  position: relative;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  border: 2px solid $main-color;
  border: 2px solid var(--main-color, $main-color);
  margin-bottom: 8px;
  margin-left: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}