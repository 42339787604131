.l-user-settings-page{
    .c-content__wrapper{
        flex-wrap: wrap;
    }
    .c-user-info, .c-user-sett{
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
    }
    .c-user-info{
        border-right: 1px solid #d9d7e0;
    }
    .c-user-sett{
        padding-left: 40px;
    }
    .c-content-header__wrapper{
        width: 75%;
        margin-bottom: 35px;
    }

    .form-settings{
        width: 75%;
        margin-block-end: 30px;

        .c-input__wrapper{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
        .o-label{
            width: 40%;
            min-width: 175px;

        }
        .o-input,.address-inputs__group{
            width: 60%;
        }
        .address-inputs__group{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .c-input__wrapper{
                width: 48%;
            }
            .o-input{
                width: 100%;
            }
        }

        .c-input-group{
            .input-label{
                width: 40%;
                min-width: 175px;
            }
        }

        .c-avatar-input{
            .file__output__wrapper{
                width: 40%;
                min-width: 175px;
            }

        }
    }

    @media only screen and (max-width: 1440px) {
        .form-settings{
            width: 90%;
        }
    }

    .c-list{
        width: 75%;

        .list-item{
            align-items: center;
            border-bottom: 1px solid #d9d7e0;
            margin: 15px 0;
            flex-wrap: wrap;
            span{
                font-size: remy(15);
            }

            .ajax-form{
                width: 100%;
                padding: 20px 0 20px 20px;
            }
            .c-input__wrapper{
                display: flex;
            }
            .o-label, .input-label{
                width: 40%;
                min-width: 175px;

            }
            .o-input,.address-inputs__group{
                width: 60%;
            }
            .address-inputs__group{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .c-input__wrapper{
                    width: 48%;
                }
                .o-input{
                    width: 100%;
                }
            }

            .c-input-group{
                .input-label{
                    width: 40%;
                    min-width: 175px;
                }
            }

            .c-input--switch__wrapper{
                justify-content: flex-start;
                .o-label{
                    min-width: 45px;
                    width: 45px;
                }
                .o-input {
                    width: 0;
                }
            }
        }
    }

    .send-button__wrapper{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding-top: 20px;
    }

    .section__title{
        font-size: remy($aside-content-regular);
        font-weight: bold;
        color: $label-color;
        width: 100%;
        margin: 15px 0;
    }

    .logo__settings__wrapper{
        width: 100%;

        label{
            cursor: pointer;
            color: $button-hover;
        }
    }
    .color__settings__wrapper{
        width: 100%;

        .c-select__wrapper{
            max-width: 150px;
        }
    }
    .logo__wrapper {
        width: 100%;
        padding: 20px 0;
        img{
            height: 68px;

        }
    }

    .colors__container{
        flex-wrap: wrap;
        max-width: 420px;
        margin-bottom: 25px;

        .color-item{
            background-color: grey;
            width: 26px;
            height: 26px;
            margin: 4px;
            cursor: pointer;
            border-radius: 4px;

            &.is-active{
                box-shadow: 0px 0px 8px 0px #2727a4, inset 0 0 0 1px rgba(0,0,0,0.6);
            }
        }
    }

    .opacity-range__wrapper{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-left: 5px;

        p{
            font-size: remy($aside-content-regular);
            margin-right: 10px;
        }

        .opacity-range{
            width: 90px;
        }
    }

    .emailexist{
        position: absolute;
        top: 36px;
        left: 40%;
    }
}