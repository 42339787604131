.timeTable__content__wrapper{
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  padding-bottom: 15px;

  .c-list__content-header{
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
  }

  .timeTable__content{
    padding-bottom: 5px;
  }


  .user__list__wrapper{
    width: 250px;
    min-width: 250px;

    .user__list__item{
      display: flex;
      align-items: center;
      min-height: 40px;
      border-right: 1px solid rgba(#685E81, .52);
      border-bottom: 1px solid rgba(#685E81, .52);
      padding-left: 10px;
    }
  }

  .timeTable__data__wrapper{
    display: flex;
    overflow-x: auto;
    flex-wrap: wrap;
    position: absolute;
    left: 250px;
    width: calc(100% - 250px);
    overflow-x: scroll;
  }

  .button-addEvent{
    background-color: rgba(104,94,129,0.2);
    width: 35px;
    height: 35px;
    padding: 0;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
      .ico{
        margin-right: 0;
        &::before{
          color: $text-color;
        }
      }

  }
}

.c-timeTable__grid{
  display: flex;

  &--background{
    .timeTable__grid-item{
      border-right: 1px solid rgba(#685E81, .52);
      border-bottom: 1px solid rgba(#685E81, .52);
    }
  }

  .timeTable__grid-item{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 60px;
    width: 60px;
    min-height: 40px;
    font-size: remy(12);
    font-weight: 500;
    color: #685E81;

    &--endPeriod{
      border-right: 1px solid rgba(#685E81, 1);
    }

    .drop__zone{
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      height: 100%;

      &--copy, &--past{
        width: 50%;

        &.active{
          background-color: $main-color;
        }
      }

    }
  }
}