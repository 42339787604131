@font-face {
    font-family: 'future-fonts';
    src:
            url('fonts/future-fonts.ttf?lclbwu') format('truetype'),
            url('fonts/future-fonts.woff?lclbwu') format('woff'),
            url('fonts/future-fonts.svg?lclbwu#future-fonts') format('svg');
    font-weight: normal;
    font-style: normal;
}

body{
    overflow-x: hidden;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
    width: 7px;
    height: 6px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $main-color;
    background-color: var(--main-color, $main-color);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}


a,p{
    font-size: remy($content-regular);
    color: $text-color;
    color:  var(--font-color, $text-color);
    text-decoration: none;
}
p{
    line-height: 1.2;

    @media (min-width: $mobile) and (max-width: $desktop) {
        font-size: 11px;
    }
}

#app{
    background-color: $background-main;
    min-height: 100vh;
    font-size: remy($content-regular);

    @media print {
        background-color: #FFF;
    }
}

.is-hide {
    display: none;
}

.mr-20{
    margin-right: 20px !important;
}
span.error{
    font-size: remy($error-regular);
    padding-left: 5px;
}

.error , .error2, .error-add{
    color: $error-color;
}
label{
    &.error-add{
        text-align: right;
        width: 100%;
        margin-top: 5px;
    }
}
.success{
    color: $success-color;
}

.lastPid{
    display: none !important;
}

.full-width{
    width: 100%;
}

*{
    outline: none;
}