.c-select__wrapper{
    &::after {
		position: absolute;
		display: flex;
		width: 12px;
		height: 8px;
		//top: 34%;
		top: 19px;
		right: 12px;
		transition: 0.4s ease-out;
		font-family: 'future-fonts';
		content: $icon-arrow;
		font-size: 9px;
		color: $text-color;
		color: var(--font-color, $text-color);
		transform-origin: center;
		background-image: none;

		@media (max-width: $mobile) {
			top: 66%;
		}

    }

    &.is-active {
		&::after {
			transform: rotate(180deg);
        }
    }


    .c-input-search__wrapper{
        position: absolute;
        width: 100%;
        left: 0;
        border-bottom: 1px solid #D8D6DE;
        padding: 7px 0;
		margin: 10px 0;

        .o-input-search{
            position: relative;
            width: 90%;
            left: 50%;
			transform: translateX(-50%);

			&:focus{
				outline: none;
			}
        }

        & ~ .c-select__list{
			margin-top: 50px;
			max-height: 100px;
        }
	}

	&--chart{
		width: 150px;
		position: absolute;
		right: 0;
		top: -15px;
	}

}

.c-select__list__wrapper {
	position: absolute;
	min-width: 100%;
	left: 0;
	top: 100%;
	border-radius: 4px;
	font-size: remy($login-placeholder);
	color: $main-color;
	color: var(--main-color, $main-color);
    background-color: $background-content;
    z-index: 900;
    padding: 0 5px;
    cursor: pointer;
    box-shadow: 0 3px 14px 0 #DDD7E7;
    height: 0;
    max-height: 170px;
    overflow: hidden;
    transition:  0.4s ease-out;

	&:hover {
		border-color: $main-color;
		border-color: var(--main-color, $main-color);

		@media(max-width: $mobile){
			border-color: unset;
		}
	}



	&.is-active {
        height: 200px;

		&::after {
			transform: rotate(180deg);
		}

	}

	.c-select__list {
		background-color: $background-content;
		height: auto;
		max-height: 150px;
		overflow-y: auto;
        margin-top: 7px;
        transition:  0.4s ease-out;

		&__item {
			display: flex;

			label {
                position: relative;
				padding: 6px 10px;
				border-radius: 4px;
				width: 100%;
				display: block;
				cursor: pointer;
				color: $text-color;
				color: var(--font-color, $text-color);
				font-size: remy($select-regular);

				@media(min-width: $desktop) {
					&:hover,
					&:active,
					&:focus {
						color: $main-color;
						color: var(--main-color, $main-color);
						font-weight: 600;
					}
				}

				@-moz-document url-prefix() {
					margin-left: -15px;
				}

                &::after{
                    position: absolute;
                    content: '';
                    right: 5px;
					top: 50%;
					transform: translateY(-50%);
                    display: block;
                    height: 9px;
                    width: 9px;
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                }

			}

			input {
				opacity: 0;
				display: block;
				height: 0;
				width: 0;
				margin: 0;

				&:active{
					& + label {
						color: $main-color;
						color: var(--main-color, $main-color);
						font-weight: 600;
					}
				}
				&:checked {
					& + label {
						color: $main-color;
						color: var(--main-color, $main-color);

                        &::after{
                            background-image: url(../images/check-color.svg);
                        }

					}
				}
			}
		}

		.add-new-position{
			display: none;
			padding: 4px 0;
			position: relative;

			.icon-add, .icon-add-btn{
				background-image: ico-generator(plus, $main-color);
				background-repeat: no-repeat;
				width: 15px;
				height: 15px;
				margin-left: 10px;
				font-size: 13px;
				top: 6px;
				position: absolute;
			}
			input{
				border: none;
				margin-left: 30px;
				font-size: 14px;
				color: $text-color;

				&.error{
					&::placeholder{
						color: red;
					}
				}

				&:focus{
					outline: none;
				}
			}
		}



	}

	.o-input-search{
        background-color: $search-background;
        border: 0;
        padding: 5px 10px;
        width: 100%;
        border-radius: 4px;

        &::placeholder{
            color: #8D8D8D;
        }
	}



}
.o-input__list {
    position: relative;
    width: 100%;

    + .o-label{
        top: 15px;
    }

    &:active,
    &:focus {
        + .o-label {
            top: 15px;
        }
	}
	&:disabled{
		background-color: transparent;
	}
}

.c-select--avatar{
	align-items: center;

	.c-select__list__item{
		display: flex;
		min-height: 40px;
		align-items: center;
		padding-left: 10px;

		label::after{
			top: 50%;
			transform: translateY(-50%);
		}

		&:hover{
			background-color: #EAEFF4;
		}
	}

	.avatars__container{
		height: 32px;
		display: flex;
		flex-wrap: wrap;

		&.no-border{
			border: none;
			height: auto;
			padding: 0 10px;
		}
	}

	.avatar__item{
		width: 30px;
		height: 30px;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}
	.c-select__list__wrapper{
		top: auto;
		bottom: 20px;
		transform: translateX(-100%) translateY(100%);
	}
}

.c-select__wrapper--multiselect{

	.o-input{
		display: flex;
		flex-wrap: wrap;
		min-height: 32px;
	}

	.no-border{
		border: none;
		height: auto;
		padding-left: 0;
	}

	.multiselect__item{
		border:1px solid $main-color;
		border:1px solid var(--main-color, $main-color);
		padding: 4px 10px;
		border-radius: 5px;
		margin: 4px;
	}

	.c-select__list__wrapper{
		top: auto;
		bottom: 20px;
		transform: translateX(-100%) translateY(100%);
	}

}