.investors-page{
    .chart__wrapper{
        margin-top: 10px;
        height: 90%;
    }
    .content__header{
        margin-bottom: 20px;
    }

    #investorsList{
        .lvl4{
            .c-list__grid--col-eight{
                .grid-item--6{
                    display: none;
                }
            }
            .c-list__grid--col-seven{
                .grid-item--6{
                    min-width: auto;
                }
            }

        }

      .c-list__filters__wrapper{
        flex-wrap: nowrap;
      }

      .filter--button{
        display: block;
      }

    }
}

.c-list__content__breadcrumbs{
  display: none;
}

@media(max-width: $mobile) {
  #investorsList {
    .c-input-search__wrapper{
      width: 100%;
    }
    .c-list__grid--col-five {
      .grid-item {
        &--1 {
          max-height: 23px;

          &:before {
            content: "Nazwa";
          }

          .o-button--show-more {
            height: 120px;
            width: 100%;
            z-index: 1;
            position: absolute;
            top: 0;
            left: 17px;

            &:active {
              background-color: transparent;
            }

            .ico {
              &:before {
                content: "";
              }
            }
          }
        }

        &--2 {
          &:before {
            content: "Osoba kontaktowa";
          }
        }

        &--3 {
          a {
            width: 100%;
            height: 14px;
          }

          &:before {
            content: "Email";
          }
        }

        &--4 {
          a {
            width: 100%;
            height: 14px;
          }

          &:before {
            content: "Telefon";
          }
        }

        &--5 {
          .o-button[data-option="add"] {
            border-right: 1px solid $background-menu;

            &:before {
              content: "Dodaj park";
            }
          }
        }
      }
    }

    .lvl3 {
      .c-list__grid--col-five, .c-list__grid--col-three, .c-list__grid--col-seven, .c-list__grid--col-four {
        margin-left: 2%;
        margin-right: 10px;

        .o-button--show-more {
          height: 34px;
          width: 100%;
          z-index: 1;
          position: absolute;
          top: 0;
          left: 17px;

          .ico {
            &:before {
              content: "";
            }
          }
        }
      }

      .c-list__grid--col-five, .c-list__grid--col-three {
        .grid-item--1 {
          max-height: 23px;

          &:before {
            content: "Nazwa";
          }
        }
        .grid-item--2 {
          padding-bottom: 0;
          .o-button--option{
            border-top: 1px solid #e7e3ee;
            background-color: transparent;
            width: 100%;
            height: 32px;
            border-radius: 0;
            margin: 0;

            .ico-plus{
              display: none;
            }
          }
          &:before {
            content: "Dodaj";
            margin-right: 0;
            margin-left: 50px;
          }
        }
      }


      .lvl4 {
        .c-list__grid--col-four {
          margin-left: 2%;
          margin-right: 10px;

          .grid-item {
            &--1 {
              &:before {
                content: "Nazwa";
              }
            }

            &--2 {
              display: none;
            }

            &--3 {
              display: none;
            }

            &--4 {
              .o-button {
                &[data-option="add"] {
                  border-right: 1px solid $background-menu;

                  &:before {
                    content: "Dodaj urządzenie";
                  }
                }
              }
            }
          }
        }

        .c-list__grid--col-seven {
          .grid-item {
            &--1 {
              &:before {
                content: "Imię i nazwisko";
              }
            }

            &--2 {
              &:before {
                content: "Umowa";
              }
            }

            &--3 {
              &:before {
                content: "Data rozpoczęcia";
              }
            }

            &--4 {
              &:before {
                content: "Data zakończenia";
              }
            }

            &--5 {
              &:before {
                content: "Czas pracy";
              }
            }

            &--6 {
              &:before {
                content: "Godziny w tygodniu";
              }
            }
          }
        }

        .c-list__grid--col-eight {
          margin-left: 2%;
          margin-right: 10px;

          .grid-item {
            &--1 {
              &:before {
                content: "Utworzono";
              }
            }

            &--2 {
              &:before {
                content: "Data rezerwacji";
              }
            }

            &--3 {
              &:before {
                content: "Godzina";
              }
            }

            &--4 {
              &:before {
                content: "Liczba osób";
              }
            }

            &--5 {
              &:before {
                content: "Użytkownik";
              }
            }

            &--6 {
              display: none;

              &:before {
                content: "Strefa";
              }
            }

            &--7 {
              &:before {
                content: "Status";
              }
            }
          }
        }
      }
    }

    .c-list__content__breadcrumbs {
      padding: 10px 20px;
      color: $text-color;
      font-weight: bold;
      line-height: 17px;

      .c-breadcrumbs-item {
        font-weight: normal;
      }
    }

    .c-form-aside {
      .c-input-file__wrapper {
        .o-input {
          width: 100%;
          max-height: 32px;
          display: flex;
          align-items: center;
        }
      }

      .c-input-group {
        flex-direction: column;

        .address-inputs__group {
          flex-direction: unset;
          width: 100%;
        }
      }
    }
    .lvl4 {
      .c-thumbnail__wrapper {
        border: 1px solid rgba(111, 54, 129, .99);
        position: absolute;
        top: -24px;
        left: 43%;
        border-radius: 50%;
        width: 51px;
        height: 51px;
        background-color: #fff;
      }

      &.lvl4-zone{
        .c-list__content-item__wrapper{
          margin-top: 30px;
        }

        .c-list__grid--col-four, .c-list__grid--col-three{
          padding-top: 45px;
        }

        .c-list__grid--col-three{
          .grid-item--2{
            display: none;
          }
        }

      }
    }
  }
}

@media(min-width: $mobile+1) and (max-width: 1000px) {
  .content-title[data-sortitem="email"]{
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (min-width: $mobile) and (max-width: $desktop) {
  #investorsList {
    .breadcrumbs-3 {
      .c-list__grid--col-eight {
        .grid-item {
          &--1 {
            width: 10%;
            min-width: 10%;
          }

          &--2 {
            width: 17%;
            min-width: 17%;
          }

          &--3 {
            width: 12%;
            min-width: 12%;
          }

          &--4 {
            width: 12%;
            min-width: 12% !important;
          }

          &--5 {
            width: 10%;
            min-width: 10%;
          }

          &--6 {
            width: 5%;
            min-width: 5%;
          }

          &--7 {
            width: 15%;
            min-width: 15%;
          }

          &--8 {
            width: 14%;
            min-width: 14%;
          }
        }

        &.reservationList{
          .grid-item {
            &--1 {
              width: 10%;
              min-width: 10%;
            }

            &--2 {
              width: 17%;
              min-width: 17%;
            }

            &--3 {
              width: 13%;
              min-width: 13%;
            }

            &--4 {
              width: 5%;
              min-width: 5% !important;
            }

            &--5 {
              width: 16%;
              min-width: 16%;
            }

            &--6 {
              width: 5%;
              min-width: 5%;
            }

            &--7 {
              width: 15%;
              min-width: 15%;

              .o-status {
                text-indent: -9999px;
                height: 15px;
                width: 15px;
                min-width: 15px;
                white-space: nowrap;
              }
            }

            &--8 {
              width: 14%;
              min-width: 14%;
            }
          }
        }
      }
    }
  }
}
