
// ============================================
// Base
// ============================================
@import "base/reset";

// ============================================
// Helpers
// ============================================
@import "helpers/mixins";
@import "./helpers/icons";

// ============================================
// Variables
// ============================================
@import "variables/breakpoints";
@import "variables/colors";
@import "variables/typography";
@import "variables/themes";
@import "variables/flex";

// ============================================
// Lib
// ============================================
@import "lib/lib";
@import "./lib/tooltip";


// ============================================
// Settings
// ============================================
@import "settings";

// ============================================
// Vendor
// ============================================




// ============================================
// Utils
// ============================================
@import "./utils/utils";
@import "./utils/spinner";
@import "./utils/separator";



// ============================================
// Objects
// ============================================
@import "./objects/buttons/buttons";
@import "./objects/inputs/inputs";
@import "./objects/typography";
@import "./objects/logos";

@import "./objects/main-nav/main-nav";

@import "./objects/person-menu/person-msg";
@import "./objects/person-menu/person-notification";
@import "./objects/person-menu/person-settings";

@import "./objects/dropdown/dropdown";

@import "./objects/status/status";

@import "./objects/avatar/avatar";

@import "./objects/thumbnail/thumbnail";


// ============================================
// Components
// ============================================
//form components
@import "./components/forms/login/login-inputs";
@import "./components/forms/input-wrapper";
@import "./components/forms/select-input/select-input";
@import "./components/forms/search-input/search-input";
@import "./components/forms/checkbox-input/_checkbox-input";
@import "./components/ulti-chart/ulti-chart";
@import "./components/forms/calendar-input/calendar-input";
@import "./components/forms/file-input/file-input";
@import "./components/forms/range-input/range-input";
@import "./components/forms/avatar-input/avatar-input";

//main-nav
@import "./components/main-nav/main-nav";

//person menu
@import "./components/person-menu/person-menu";
@import "./components/person-menu/lang-change/lang-change";

//content - more in this file
@import "./components/content/contents";


//aside
@import "./components/aside/aside";
@import "./components/forms/aside-form/aside-form";

//popup
@import "./components/forms/popup-form/popup-form";

//thumbnail
@import "./components/thumbnail/thumbnail";
@import "./components/thumbnail/thumbnail__modal";

@import "./components/notification/notification";


//employees Time Table
@import "./components/employeesTimeTable/employessTimeTable";

// ============================================
// Layout
// ============================================
@import "layouts/login-page";
@import "./layouts/reservation-lists";
@import "./layouts/reservation-currentLists";
@import "./layouts/general-layout";
@import "./layouts/blocks";
@import "./layouts/investors-page";
@import "./layouts/full-page";
@import "./layouts/user-settings";
@import "./layouts/reserv-page";
@import "./layouts/lang-page";
@import "./layouts/reserv-page-mobile";
@import "./layouts/timer";
@import "./layouts/ticket-scanner";
@import "./layouts/fastOrder";
@import "./layouts/orderInfo";

//// baguettebox
//@import '../../../front/node_modules/baguettebox.js/dist/baguetteBox.min.css';

//croppie
@import '../../../front/node_modules/croppie/croppie.css';

// ============================================
// Statistic
// ============================================
@import "components/stat/statistic";
@import "components/stat/tooltips";
@import "components/stat/legend";
@import "components/stat/progress-circle";
@import "components/stat/static-block";
@import "components/stat/static-filter";
@import "components/stat/tiles-block";