.c-main-container{
    flex-wrap: wrap;
    align-content: flex-start;
}

.l-general-layout{
    .c-main-container{
        flex: auto;
        padding: 20px 40px;
        justify-content: space-between;
        max-width: 100%;
        width: calc(100% - 270px);

        @media(max-width: $desktop){
            padding: 0;
        }
        @media(min-width: $mobile+1) and (max-width: $desktop){
            width: 0;
            margin-left: 60px;
        }
        @media(max-width: $mobile){
            z-index: 999;
        }
        @media(min-width: $desktop+1) and (max-width: $desktop-l){
            padding: 20px;
        }

    }

    .c-person-menu__wrapper{
        width: 100%;
        height: 42px;

        @media print {
            display: none;
        }
    }

    #c-content__container{
        width: 100%;

        @media only screen and (max-width: 1440px) {
            .l-block--1-4{
                width: 49%;
                order: 1;
            }

            .l-block--3-4{
                width: 100%;
                z-index: 111;
            }
        }

        @media(max-width: $mobile){
            padding-top: 73px;

            .c-welcome-msg__wrapper{
                display: none;
            }
            .o-content-block{
                width: 100%;
                padding: 10px;

                .c-customers-count{
                    border: 1px solid $border-light;
                }

                .c-clients-contractEnd{
                    border: 1px solid $border-light;
                    margin-top: 10px;
                }
                .c-content__wrapper{
                    border: 1px solid $border-light;
                }
                &.block-chart{
                    order: 2;
                }
                &.block-chart2{
                    order: 3;
                }
            }
            #logged-user{
                display: none;
            }
        }

        @media (min-width: $mobile+1) and (max-width: $desktop){
            padding: 0 20px;

            .c-customers-count{
                border: 1px solid $border-light;
                padding: 20px;
                background-color: $background-content;
                box-shadow: 0 3px 14px 0 rgba(158,143,190,.57);
            }
            .c-clients-contractEnd{
                border: 1px solid $border-light;
                margin-top: 10px;
                padding: 20px;
                background-color: $background-content;
                box-shadow: 0 3px 14px 0 rgba(158,143,190,.57);
            }
            .block-chart{
                width: 50%;
                order: 2;
                border: 1px solid $border-light;
                border-radius: 9px;
                background-color: $background-content;
                box-shadow: 0 3px 14px 0 rgba(158,143,190,.57);

                .c-user-count--chart{
                    padding: 20px 10px;
                }
            }

            #logged-user{
                order: 3;
                border: 1px solid $border-light;
                border-radius: 9px;
                margin-top: 10px;
                background-color: $background-content;
                box-shadow: 0 3px 14px 0 rgba(158,143,190,.57);

                .c-content__wrapper{
                    padding: 10px;
                }
            }

            .block-chart2{
                order: 4;
                width: 50%;
                border: 1px solid $border-light;
                border-radius: 9px;
                margin-top: 10px;
                background-color: $background-content;
                box-shadow: 0 3px 14px 0 rgba(158,143,190,.57);
                .c-content__wrapper{
                    padding: 10px;
                }
            }
        }
    }
    @media(max-width: $desktop){
        &.opacity-bg{
            &:before {
                width: 100%;
                height: 100%;
                position: fixed;
                content: "";
                top: 0;
                right: 0;
                z-index: 1000;
                background-color: rgba(0, 0, 0, 0.4);
            }
        }
    }

    @media (min-width: $desktop+1){
        .c-content-menu__wrapper{
            width: auto;
            order: 1;
        }

        .c-person-menu__wrapper{
            width: auto;
            order: 2;
           // position: absolute;
            right: 2%;
        }

        #c-content__container {
            order: 3;
        }
    }
}


