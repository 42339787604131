@import "./person-msg/person-msg";
@import "./person-notification/person-notification";
@import "./person-settings/person-settings";

.c-person-menu__wrapper{
    justify-content: flex-end;
    flex-wrap: wrap;

    .notification__number{
        font-family: $main-font;
        position: absolute;
        width: 19px;
        height: 19px;
        top: -10px;
        right: -8px;
        font-size: 10px;
        line-height: 1.6;
        font-weight: bold;
        border: 2px solid $background-content;
        color: $background-content;
        border-radius: 50%;
        background-color: $main-color;
        z-index: 999;
        text-align: center;
    }

    @media (max-width: $mobile){
        background-color: $background-menu;
        padding: 20px 10px 0 0;
        position: fixed;
        top: 0;
        z-index: 999;
    }

    @media (min-width: $mobile+1) and (max-width: $desktop){
        background-color: $background-main;
        padding-right: 20px;
        padding-top: 15px;
        width: auto !important;
    }
}
.c-person-menu{
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    color: $text-color;
    color: var(--font-color, $text-color);

    @media(max-width: $mobile){
        z-index: 999;
    }

}