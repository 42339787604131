.c-welcome-msg{
    &__wrapper{
        background-color: $background-content;
        box-shadow: 0 3px 14px 0 rgba(158,143,190,.57);
        border: 1px solid #cbcbcb;
        border-radius: 9px;
        margin-top: 50px;
        padding: 10px;
        width: 100%;
        display: flex;

        .welcome-msg{
            &__content{
                flex-wrap: wrap;
                width: 70%;
                align-items: flex-end;
                padding: 30px;
            }

            &__header{
            color: $text-color;
            font-weight: 500;
            margin-bottom: 17px;
            }
            &__text{
                color: $text-color;
                width: 80%;
                line-height: 2;
            }

            &__img{
                background-image: url(../images/welcomeMsgImg.svg);
                -webkit-background-size: contain;
                background-size: contain;
                background-position: center ;
                background-repeat: no-repeat;
                width: 30%;
            }
        }



        .o-button{
            border: 1px solid $text-light-color;

        }
    }

}