#reservation-page{
    width: 100%;
    max-width: 1440px;

    .disabled{
      opacity: 0.4;
      pointer-events: none;
    }

    .c-content-header__wrapper {
        position: relative;
        display: flex;
        width: 100%;
        margin-bottom: 20px;
        padding: 20px 2%;

      .timer{
        font-size: 12px;
        width: 100%;
        padding-top: 0;
        justify-content: flex-end;
        //padding-bottom: 20px;
        width: 100%;
        order: 1;

        &.color-danger{
          color: var(--font-color, $text-color);
        }
      }

      .login-panel{
        font-weight: 500;
        position: absolute;
        right: 48px;
        bottom: 20px;

        &.is-hide{
          display: none;
        }

        a{
          padding: 7px 11px;

          @media(max-width: $mobile){
            padding: 3px 10px;
          }
        }

        @media(max-width: $mobile){
          display: flex;
          width: 100%;
          justify-content: center;
          right: unset;
          bottom: -26px;
        }

        @media(min-width: $mobile+1) and (max-width: $desktop){
          span{
            display: none;
          }
        }
      }

    }
    .c-content__wrapper{
      align-items: flex-start;
      flex-wrap: wrap;

    }
    .user__options{
      width: 200px;
      flex-wrap: wrap;
      justify-content: flex-end;
      padding-right: 15px;
      align-content: space-between;
      font-size: remy($header-regular);
    }
    .login__wrapper{
      //display: none;
      .o-button{
        color: $main-color;
        color: var(--main-color, $main-color);
      }
      .o-button:first-child{
        position: relative;
        margin-right: 5px;
        font-size: remy($aside-header-small);

        &::after{
        }
      }

      .account-inactive{
        padding: 20px 5px;
      }
    }
    .language__wrapper{
      max-width: 140px;

      .c-input__wrapper{
        padding-bottom: 0;
      }

      .c-select__wrapper::after{
        top: 50%;
      }
    }

    .user__wrapper{
      display: none;
      position: relative;
      padding-right: 20px;
      color: $text-color;
      color: var(--font-color, $text-color);

      .ico{
        position: absolute;
        width: 11px;
        height: 11px;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .o-dropdown__wrapper{
      z-index: 999;
      padding: 10px 5px;
      min-width: 140px;
      left: 50%;
      transform: translateX(-50%);
      top: 18px;

      .o-button-anchor{
        font-size: remy($header-regular);

      }
      li{
        margin: 12px 0;
      }
    }

    .etaps__wrapper{
      flex-grow: 1;
      justify-content: center;
      align-content: flex-end;
      padding-top: 20px;
      //margin-left: 200px;
      width: 100%;
      order: 2;
    }

    .etaps{
      display: flex;
      position: relative;
      width: 90%;
      max-width: 800px;
      align-items: flex-end;

      .etaps__dot{
        position: absolute;
        width: 14px;
        height: 14px;
        background-color: white;
        border: 2px solid $main-color;
        border: 2px solid var(--main-color, $main-color);
        border-radius: 50%;
        transition: .6s ease-in-out, bottom 0s;
        z-index: 999;
        transform: translateX(-50%);
        bottom: 0px;

        &.moving{
          animation: dotAnimation;
          animation-duration: .6s;
          bottom: 4px;
        }
        @keyframes dotAnimation {
          0% {width: 14px;
              height: 14px;
              background-color: white;
            border-radius: 50%;
          }
          5% {
            height: 8px;
          }
          50% {width: 40px;
              height: 1px;
              background-color:$main-color;
              background-color: var(--main-color, $main-color);
              border-radius: 8px;

          }
          95% {
            height: 8px;
          }
          100% {width: 14px;
              height: 14px;
              background-color: white;
            border-radius: 50%;
          }
        }
      }
    }

  [class~='etap__item']{
    display: flex;
    justify-content: center;
    width: 25%;
    font-size: remy($content-bigger);
    color: $placeholder-color;
    position: relative;

    &::after{
      content: '';
      position: absolute;
      bottom: 6px;
      height: 1px ;
      width: 100%;
      background-color: $placeholder-color;
      z-index: 0;
    }

    &:first-child, &:nth-last-child(-n+2){
      &::after{
        width: 50%;
      }
    }
    &:first-child::after{
      right: 0;
    }
    &:nth-last-child(-n+2)::after{
      left: 0;
    }



    h2{
      position: relative;
      padding-bottom: 25px;
      z-index: 100;

      &:after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: white;
        border: 2px solid $placeholder-color;
        border-radius: 50%;
        width: 14px;
        height: 14px;
      }
    }


    &.is-active{
      font-weight: bold;
      color: $main-color;
      color: var(--main-color, $main-color);
    }
  }

    .u-separator{
        top: auto;
        bottom: 0;
        background-color: #b4afc1;
    }


  .history__wrapper{
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;

    .etap__wrapper{
      min-width: 100%;
      transition: 1.3s ease-in-out;
      transform: translateX(0);
    }

    .c-input__wrapper{
      height: 70px;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .parkSelection__wrapper{
    justify-content: center;
    width: 100%;
  }
  .park{
    &__item{
      width: 200px;
      margin: 40px ;
      flex-wrap: wrap;
      align-content: flex-start;

      .o-button{
        width: 100%;
      }
    }

    &__thumbnail{
      width: 100%;
      height: 200px;
      background-color: $placeholder-color;
      border-radius: 11px;

      div{
        height: inherit;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #fff;
        background-position: center;
      }
    }

    &__name{
      font-size: remy($content-bigger);
      color: $text-color;
      color: var(--font-color, $text-color);
      text-align: center;
      margin: 10px 0;
      line-height: 1.6;
      width: 100%;

      h4{
        height: 25px
      }
    }

    &__promotion{
      margin-top: 20px;
      text-align: center;
      width: 100%;

      h4{
        font-size: remy($content-bigger);
        color: $text-color;
        color: var(--font-color, $text-color);
        font-weight: 500;
      }

      &__name{
        font-size: remy($header-regular);
        color: $text-color;
        color: var(--font-color, $text-color);
        margin: 8px 0;
      }

    }
  }

  .reservation-system__wrapper{
    width: 100%;
    padding: 0 20px 20px 20px;
    justify-content: space-between;
    flex-wrap: wrap;

    .error-message-zone{
      width: 100%;
      font-size: 16px;
      text-align: center;
      color: $error-color;
      padding-bottom: 20px;
      min-height: 36px;

      @media (max-width: $mobile) {
        padding-top: 20px;
        padding-bottom: 0;
        min-height: auto;
      }
    }

    .transparent-block{
      @media( min-width: 1200px){
        background-color: transparent;
        width: 100%;
        height: 30px;
      }
    }

    .section__wrapper, .entry-option__wrapper,{
      margin: 0 2%;
    }

    .section__wrapper:last-child{
      display: flex;
      flex-wrap: wrap;
      width: 650px;

      @media(min-width: $mobile+1) and (max-width: $desktop){
        max-width: 675px;
      }
    }

    .o-label-checkbox{
      color: $text-color;
      color: var(--font-color, $text-color);

      &::before{
        border-radius: 50%;
        width: 18px;
        height: 18px;
        top:50%;
        transform: translateY(-50%);
        box-shadow: inset 0 0 0 10px white;
        border-color: $text-color;
        border-color: var(--font-color, $text-color);
      }
    }
    .o-input-checkbox:checked+.o-label-checkbox::before{
      background-image: none;
      box-shadow: inset 0 0 0 4px white;
      border-color: $main-color;
      border-color: var(--main-color, $main-color);
    }
  }

  .section__title{
    font-size: remy($header-bigger);
    color:  $text-color;
    color: var(--font-color, $text-color);
    margin-bottom: 20px;

  }

  .o-textarea{
    color: #3d3d3d;
    border: .5px solid rgba(104,94,129,.6);
    border-radius: 4px;
    padding: 8px 10px;
    font-size: .8125rem;
    color:  $text-color;
    color: var(--main-color, $text-color);
    font-weight: 500;
    font-family: Roboto;
    margin: 0px;
    height: 92px;
    width: 100%;
    resize: vertical;
  }
  .o-textarea:focus{
    outline: 0;
  }

  .entry-option__wrapper{
    li{
      padding: 10px 0;
    }
    #promotionType-select{
      display: none;
    }

    .c-select__wrapper{
      padding: 10px 0;
      padding-bottom: 0;
      margin-left: 30px;
    }

    .c-select__wrapper::after{
      top: 24px;
    }

    .o-button-anchor{
      margin-left: 20px;
      color: $main-color;
      color: var(--main-color, $main-color);
    }
  }

  .calendar__wrapper{
    transition: .4s ease-out;
  }
  .person-number__wrapper{
    margin-bottom: 40px;
    transition: .4s ease-out;

      input{
        margin: 0 10px;
        width: 70px;
        text-align: center;
      }

      .o-button{
        padding: 0;
        width: 31px;
        height: 31px;

        span{
          font-size: 18px;
          font-weight: 400;
        }
      }
  }

  .adds__wrapper{
    padding-left: 140px;
    transition: .4s ease-out;

    @media(min-width: $mobile+1) and (max-width: $desktop){
      padding-left: 0;
    }
  }

  .sum__wrapper{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    margin-top: 30px;

    .sum__cost {
      font-size: remy(18);
      color: $main-color;
      color: var(--main-color, $main-color);
      margin-right: 25px;

      span {
        font-weight: bold;
      }
    }
    .o-button{
      position: relative;
      border: .5px solid var(--main-color, $main-color);
      margin-left: 20px;

      .spinner__wrapper{
        height: 90%;
        width: 30px;
        top: 0;
        .double-bounce1, .double-bounce2{
          background-color: rgba(228, 161, 245, 0.99);
        }
      }
    }

  }



  .cur-month, .cur-year{
    color: $main-color;
    color: var(--main-color, $main-color);
  }
}

.time-table__wrapper{
  max-width: 650px;
  transition: .4s ease-out;
  .section__title{
    padding-left: 140px;
  }
  @media(min-width: $mobile+1) and (max-width: $desktop){
    max-width: 675px;
  }

}

#time-table{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  .table__zones{
    width: 140px;
    padding-top: 14px;
    color:  $text-color;
    color: var(--font-color, $text-color);
    font-size: remy(14);

    @media(min-width: $mobile+1) and (max-width: $desktop){
      width: 20%;
    }

    &__item{
      margin: 20px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .table__content{
    max-width: 505px;
    overflow-x: auto;
    flex-grow: 1;
    overflow-y: hidden;

    @media(min-width: $mobile+1) and (max-width: $desktop){
      max-width: 80%;
    }
  }
  .content {
    &__hour__wrapper {
      font-size: remy(14);
      color:  $text-color;
      color: var(--font-color, $text-color);
      margin-bottom: 20px;
      margin-top: 0 !important;
      background-color: transparent !important;
    }
  }

  .content__item-background{


    .grid-item{
      //background-color: #E1DFE6;
      &:first-child{
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
      }
      &:last-child{
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
      }
    }
  }
  
  .table-grid__wrapper{
    display: flex;
    margin: 20px 0;
    height: 14px;
    //background-color: #E1DFE6;
    border-radius: 8px;
    transition: 0.25s ease-out;

    .grid-item{
      min-width: 42px;
      text-align: center;
      height: 14px;
      transition: .25s ease-out;

      .item{
        transition: .25s ease-out;
        &--free{
            cursor: pointer;
            &:hover{
              opacity: 0.5;

              @media(max-width: $desktop){
                opacity: 1;
              }

            }
          }

        &--low{
          cursor: pointer;
          &:hover{
            opacity: 0.5;

            @media(max-width: $mobile){
              opacity: 1;
            }
          }
        }

      }

      @media(min-width: $mobile+1) and (max-width: $desktop){
        width: 100%;
      }

    }

  }
  .table__legend{
    width: 100%;
    padding-left: 140px;
    color:  $text-color;
    color: var(--font-color, $text-color);
    display: flex;
    flex-wrap: wrap;

    .legend__item{
      width: 50%;
      display: flex;
      align-items: center;
      padding: 7px 0;
    }

    .item{
      width: 42px;
      height: 14px;
      margin-right: 8px;

    }
  }
  .content__places{
    &--full{
      background-color: transparent !important;
      pointer-events: none !important;
    }
  }
  .item{
    border-radius: 7px;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &--free{
      background-color: #98D794;
    }
    &--low{
      background-color: #F9B661;
    }
    &--full{
      background-color: #e1dfe6;
      pointer-events: none;
    }
    &--semifree{
      background-color: #f9ea68;
    }
    &--warning{
      background-color: #fd354b;
    }
    &--block{
      background-color: #6f6f6f;
      pointer-events: none !important;
    }

    &--checked{
      background-color: $main-color;
      background-color: var(--main-color, $main-color);
      opacity: 1;
    }

  }

}

.loginEtap__wrapper{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;

  .form__header__wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }
  .form__header{
    font-size: remy(22);
    color:  $text-color;
    color: var(--font-color, $text-color);
    max-width: 420px;
    text-align: center;
  }

  .register__wrapper, .login__wrapper{
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    justify-content: center;
    align-content: flex-start;
    margin: 20px 0;

    form{
      max-width: 420px;
      //display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .login__wrapper{
    form {
      justify-content: flex-end;
    }

    .form__header__wrapper{
      margin-bottom: 76px;
    }
  }

  .register__wrapper{
    border-right: 1px solid  $text-color;
    border-right: 1px solid  var(--font-color, $text-color);
    .o-button-anchor{
      margin-left: 20px;
      color: $main-color;
      color: var(--main-color, $main-color);
    }

    .c-select__list__wrapper{
      top: unset;
    }

    .account-inactive{
      padding: 10px 0;
    }

    .o-label-checkbox{
      display: block;

      div:first-child{
        margin-bottom: 5px;
      }
    }

    .c-checkbox-wrapper{
      &:not(:first-child){
        min-height: 32px;
      }

      &:first-child{
        margin-bottom: 10px;
      }

      .double-line-check{
        min-height: 48px;
      }
      .error{
        margin-left: 20px;
      }
    }

    @media(max-width: $tablet){
      border: unset;
    }
  }

  .person-info__wrapper{
    max-width: 400px;

    .o-button-anchor{
      padding: 5px 0 5px 25px;
      font-size: .875rem;
      color: $main-color;
      color: var(--main-color, $main-color);;

      span{
        text-decoration: underline;
      }

      &:focus{
        text-decoration: none;
      }
    }

    .c-select__list__wrapper{
      top: unset;
    }

    .error__wrapper{
      &:after{
        content: unset;
      }
    }


  }


  .c-input-group{
    width: 100%;
    justify-content: space-between;

    .c-input__wrapper{
      width: 49%;
    }
  }
  .c-input__wrapper{
    width: 100%;
    .o-input{
      width: 100%;
      box-shadow: none;
      background-color: transparent;
    }
  }

  .c-file__wrapper{
    display: flex;
    align-content: center;
    align-items: center;
    padding-bottom: 5px;

    .output-file{
      flex-shrink: 1;
      margin-right: 10px;
      white-space: nowrap;
      overflow: hidden;
    }
    input[type='file']{
      display: none;
    }

    .o-button{
      width: 190px;

      @media(max-width: $mobile){
        width: 133px;
        padding: 7px 12px;
      }

    }
  }
  .aggrement-inputs{
    margin-top: 5px;
  }

  .file-input__info{
    padding-bottom: 20px;
    padding-left: 25px;
    font-size: .875rem;
    //line-height: 0.7;
    color: $main-color;
    color: var(--main-color, $main-color);;
  }

  .input-date__wrapper{
    flex-wrap: wrap;
    .group-title{
      width: 100%;
      font-size: remy($header-regular);
      color: $text-color;
      color: var(--font-color, $text-color);
      margin: 5px 0 ;
    }
    .c-input__wrapper{
      width: 32%;
    }
  }

  .c-checkbox-wrapper{
    //margin-bottom: 10px;
    //margin-top: 10px;
    width: 100%;

    &.error__wrapper{
      &::after{
        display: none;
      }
    }

    &.error{

      .o-label-checkbox{
        color: $error-color;
      }
    }

  }
  .forgotPassword-info{
    text-decoration: none;
    background: 0 0;
    border: 0;
    padding: 18px 5px;
    font-size: 15px;
    font-family: Roboto,sans-serif;
    color: var(--main-color,rgba(111,54,129,.99));
    font-weight: bold;
  }

  .forgotPassword-form{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    display: none;

    button{
      justify-self: normal;
      width: 40%;
      align-self: flex-end;
      color: #fff !important;
    }
  }

  .account-inactive{
    font-size: .8125rem;
    color: $error-color;
    font-weight: 500;
    line-height: 16px;
    display: none;
  }

}

.paymentMethod__wrapper{
  //justify-content: center;
  //flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  .content__wrapper{
    max-width: 900px;
    width: 90%;

  }

  .methods{
    &__list{
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      justify-content: center;
    }
    &__item{
      width: 135px;
      height: 85px;
      border: 1px solid #DADADA;
      border-radius: 8px;
      margin: 5px;
      cursor: pointer;

      &.is-checked{
        border-color: $main-color;
        border-color: var(--main-color, $main-color);
      }
    }
  }

  .select__wrapper{
    li{
      margin: 40px 0;
    }

    .o-label-checkbox{
      color: $text-color;
      color: var(--font-color, $text-color);

      &::before{
        border-radius: 50%;
        width: 18px;
        height: 18px;
        top:50%;
        transform: translateY(-50%);
        box-shadow: inset 0 0 0 10px white;
        border-color: $text-color;
        border-color: var(--font-color, $text-color);
      }
    }
    .o-input-checkbox:checked+.o-label-checkbox::before{
      background-image: none;
      box-shadow: inset 0 0 0 4px white;
      border-color: $main-color;
      border-color: var(--main-color, $main-color);
    }
  }

  .c-select__wrapper{
    max-width: 250px;
    margin-left: 20px;
    margin-top:12px;
  }
}


.endOfReservation{
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
  color: $text-color;
  color: var(--font-color, $text-color);
  align-items: flex-start;
  align-content: flex-start;

  .success__wrapper, .error__wrapper, .loader__wrapper{
    width: 100%;
    height: 100%;
    text-align: center;

     .title{
      font-size: remy(21);
      font-weight: 500;
      line-height: 1.6;
      max-width: 630px;
      text-align: center;
       margin-bottom: 20px;
       left: 50%;
       position: relative;
       transform: translateX(-50%);
    }

    .info{
      font-size: remy(18);
      width: 100%;
      text-align: center;
      margin: 5px 0;

      span{
        color: $main-color;
        color: var(--main-color, $main-color);
      }
    }
  }

  .loader__wrapper{
    #wave {
      position:relative;
      .dot {
        display:inline-block;
        width:5px;
        height:5px;
        border-radius: 50%;
        background-color: var(--main-color, $text-color);
        animation: wave 1.3s linear infinite;

        &:nth-child(2) {
          animation-delay: -1.1s;
        }

        &:nth-child(3) {
          animation-delay: -0.9s;
        }
      }
    }

    @keyframes wave {
      0%, 60%, 100% {
        transform: initial;
      }

      30% {
        transform: translateY(-15px);
      }
    }
  }

  .success__wrapper{
    //background-image: url("../images/reservBG.svg");
    background-image: var(--resSuccessImg);
    background-repeat: no-repeat;
    background-position: 120px 90%;

    .title{
      color: $success-color;
    }
  }

  .error__wrapper{

    &::after{
      display: none;
    }
    .title{
      color: $error-color;
    }

    .sum__wrapper{
      justify-content: center !important;
    }
  }

  .button__wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .o-button{
    margin-top: 20px;
    font-size: remy(18);
    padding: 15px 50px;
  }

  .ticket__output{
    position: relative;
    margin-top: 40px;
    width: 216px;
    height: 216px;
    left: 50%;
    transform: translateX(-50%);
    background-size: contain;
    background-position: center;
  }
}

.modalWrapper{
  background-color: rgba(0,0,0, 0.3);
  width: 300px;
  height: 300px;
  position: absolute;
  transition: .3s ease-in-out;
  top: 50%;
  left: 50%;
  box-shadow: 0px 0px 8px 1px rgba(0,0,0, 0.4);
  opacity: 0;
  border-radius: 8px;
  z-index: 9999;

  .timeTableModal{
    background: #fff;
    width: 100%;
    height: 100%;
    padding: 15px;
    border-radius: 8px;

    #time-table{
      flex-wrap: nowrap;
    }
  }

  @media(max-width: $mobile) {
    display: none
  }
}

.onlyEndStep{
  .etap__item{
    &.is-active h2{
      &:after{
        border: 2px solid $main-color !important;
      }
    }
  }

  .endOfReservation{
    margin: 0 !important;
  }
}