@media (max-width: 363px){
  .calendar__wrapper{

    .dayContainer{
      max-width: 230px !important;

      .flatpickr-day{
        margin: 0 1px !important;
      }
    }
  }
}

@media (max-width: $mobile) {

.sum-fixed{
  display: block;

  &.is-hide2{
    display: none !important;
  }

  .sum__wrapper2{
    height: 48px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 111;
    width: 100%;
    display: flex;
    background: white;
    justify-content: flex-end;
    box-shadow: 0px 3px 14px #DDD7E7;

    .sum__cost2{
      white-space: nowrap;
      font-size: 14px;
      padding-left: 23px;
      display: flex;
      align-items: center;
      margin-right: auto;
      span{
        font-weight: bold;
        margin-left: 4px;
      }
    }
    button[etap-controll="next2"]{
      height: 100%;
      border-radius: 11px 0 0 0;
      margin-left: 0;

      &.disabled{
        opacity: .4;
        pointer-events: none;
      }

      &.disabled2{
        opacity: .4;
        pointer-events: none;
      }
    }

    button[etap-controll="prev2"]{
      border: none;

      &:active, &:focus, &:hover{
        background-color: transparent;
        border: none;
        color: $main-color;
      }
    }
  }
}

  #reservation-page {

    .c-content-header__wrapper {
      padding-top: 10px;
      padding-bottom: 20px;

      .etaps__wrapper {
        margin-left: 0;
        padding-top: 14px;

        .etaps {
          span {
            display: none;
          }
        }
      }

      .user__options {
        width: auto;
        align-content: flex-end;

        .o-button {
          padding: 0;
        }
      }
    }

    .section__title {
      font-size: 14px;
      margin-bottom: 10px;
    }

    .history__wrapper {
      margin: 10px;
      box-shadow: $light-box-shadow;

      .sum__wrapper{
        display: none;
      }

      .parkSelection__wrapper{
        height: 100%;
        grid-template-columns: auto auto;
        display: grid;
        grid-gap: 20px;
        padding: 25px;
        //border: 1px solid $border-light;
        border-radius: 9px;

        .park__item{
          margin: 0;
          width: 100%;
          height: 100%;
          justify-content: center;
          flex-direction: column;

          .park__thumbnail{
            width: 130px;
            height: 130px;
          }

          .park__name{
            font-size: 14px;
            line-height: 23px;

          }

          .o-button{
            border-radius: 4px;
            width: 130px;
          }
        }
      }

      .reservation-system__wrapper {
        flex-direction: column;
        padding: 0;
        justify-content: flex-start;
        //border: 1px solid $border-light;
        border-radius: 9px;
        height: 100%;

      .section__wrapper {
        width: 100% !important;
        padding: 0 25px;
        margin: 0;

        .sum__wrapper{
          display: none;
        }
      }

        //.login-panel{
        //  padding: 25px 0 0 25px;
        //}

      .entry-option__wrapper {
        margin: 0 25px 0 25px;
        align-items: flex-start;

        .o-label-checkbox {
          text-decoration: none;
        }
      }

      .person-number__wrapper, .calendar__wrapper {
        .section__title {
          margin-top: 17px;
        }

        .o-input {
          border-radius: 11px;
        }

        .o-button {
          border-radius: 50%;
        }
      }

        .person-number__wrapper{
          .u-flex{
            //display: none;
            justify-content: center;
            .o-input{
              width: 50%;
            }
          }

          .person-count{
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #E7E3EE;
            border-radius: 22px;
            padding: 0 20px;
            overflow-y: hidden;
            overflow-x: auto;
            max-width: 100%;

            &::-webkit-scrollbar{
              display: none;
            }

            .count-item{
              width: 24px;
              height: 23px;
              min-width: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 300;
              margin: 0 4px;
              line-height: 18px;

              &.active{
                background: #6D3680;
                border-radius: 50%;
                color: white;
              }
            }
          }
        }

      .time-table__wrapper {
        .table__zones {
          display: none;
        }

        .table__content{
          max-width: 100%;
        }

        .section__title {
          margin-top: 20px;
        }

        #time-table {
          flex-direction: column-reverse;

          .content__hour__wrapper {
            display: none;
          }

          .temp {
            .table-grid__wrapper {
              flex-wrap: wrap;
              width: 100%;
              height: auto;
              background-color: transparent;

              .grid-item {
                width: 47px;
                height: 30px;
                margin-right: 3px;
                margin-bottom: 4px;
                background-color: transparent;

                .item {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: $text-light-color;
                  font-size: 12px;

                }

              }
            }
          }
        }

        .table__legend, .section__title {
          padding-left: 0;
        }

        .table__legend {
          flex-direction: column;
          align-items: flex-start;

          .legend__item {
            width: 100%;

            .item {
              width: 14px;
            }
          }
        }
      }

      .adds__wrapper {
        padding-left: 0;
        width: 100%;
        margin-bottom: 60px;

        .c-input__wrapper{
          height: 100%;
        }
      }
    }

      .loginEtap__wrapper{
        flex-direction: column;
        justify-content: flex-start;
        margin: 0px 20px;
        height: 100%;
        flex-direction: column-reverse;
        //border: 1px solid $border-light;
        border-radius: 9px;

        .form__header__wrapper {
          margin-bottom: 30px;

          .form__header {
            font-size: 16px;
            line-height: 25px;
          }
        }

        .register__wrapper, .login__wrapper {
          width: 100%;
          margin: 10px 0;

          .c-input__wrapper{
            //height: 80px;
            padding-bottom: 10px;
          }
        }

        .register__wrapper{
          width: 100%;
          margin: 10px 0;

          .form__header__wrapper{
            position: relative;
            max-width: 420px;

            &:after{
              position: absolute;
              display: flex;
              width: 12px;
              height: 8px;
              top: 8px;
              right: 0;
              transition: 0.4s ease-out;
              font-family: 'future-fonts';
              content: $icon-arrow;
              font-size: 9px;
              color: $text-color;
              color: var(--font-color, $text-color);
              transform-origin: center;
              background-image: none;
            }

            &.active{
              &:after{
                transform: rotate(180deg);
              }
            }
          }

          form{
            //display: none;
          }

          .mobile-password{
            flex-direction: column;

            .c-input__wrapper{
              width: 100%;
            }
          }

          .c-select__wrapper:after{
            top: 18px;
          }
        }

        .person-info__wrapper{
          margin: auto;
          .c-select__wrapper:after{
            top: 31%;
          }
          .c-checkbox-wrapper{
            .o-label-checkbox{
              text-decoration: none;
            }
            .error{
              padding-left: 25px;
            }
          }
        }

        .sum__wrapper{
          display: none;
        }
      }

      .paymentMethod__wrapper{
        //border: 1px solid $border-light;
        border-radius: 9px;
      }

      .endOfReservation{
        flex-direction: column;
        justify-content: flex-start;
        margin: 0 20px;
        height: 100%;
        //border: 1px solid $border-light;
        border-radius: 9px;

        .title{
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          padding: 6px;
        }
        .info{
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 6px;

          span{
            display: block;
          }
        }

        .button__wrapper{
          .o-button{
            border: 0.5px solid $border-light;
            box-sizing: border-box;
            border-radius: 9px;
            font-size: 12px;
            line-height: 9px;
            margin-top: 10px;
          }
        }
        .ticket__output{
          margin-top: 0;

          .ticket-number{
            margin-top: 20px;
          }
        }
      }


    }

    .calendar__wrapper{
      .flatpickr-calendar{
        width: 100%;
        box-shadow: none;
      }
      .flatpickr-innerContainer{
        display: flex;
        justify-content: center;

        .flatpickr-rContainer{
          //width: 100%;

          .flatpickr-weekdays{
            max-width: 305px;
          }

          .flatpickr-days{
            width: 100%;

            .dayContainer{
              width: 100%;
              max-width: 305px;

              .flatpickr-day{
                margin: 0 6px;
              }
            }
          }
        }
      }
    }
  }
}

@media(min-width: $mobile){
  .sum-fixed{
    display: none;
  }
  .table-grid__wrapper{
    .item{
      color: transparent;
    }
  }
  .person-number__wrapper{
    .u-flex{
      display: flex;
    }
    .person-count{
      display: none;
    }
  }
}

@media(min-width: 768px) and (max-width: 1280px){

  .sum-fixed{
    display: block;

    &.is-hide2{
      display: none !important;
    }

    .sum__wrapper2{
      height: 48px;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 111;
      width: 100%;
      display: flex;
      background: white;
      justify-content: flex-end;
      box-shadow: 0 3px 14px #DDD7E7;

      .sum__cost2{
        white-space: nowrap;
        font-size: 14px;
        padding-left: 23px;
        display: flex;
        align-items: center;
        margin-left: auto;
        span{
          font-weight: bold;
          margin-left: 4px;
        }
      }
      button[etap-controll="next2"]{
        height: 100%;
        border-radius: 11px 0 0 0;
        margin-left: 0;

        &.disabled{
          opacity: .4;
          pointer-events: none;
        }

        &.disabled2{
          opacity: .4;
          pointer-events: none;
        }
      }

      button[etap-controll="prev2"]{
        border: none;

        &:active, &:focus, &:hover{
          background-color: transparent;
          border: none;
          color: $main-color;
        }
      }
    }
  }

  #reservation-page{

    padding: 10px;
    .c-content__wrapper{
      border: 1px solid $border-light;
    }
    .etaps__wrapper {
      margin-left: 0;
      //padding-top: 0;

      .etap__item{
        font-size: 13px;
      }
    }

    .user__options{
      width: auto;
      align-content: center;

      .login__wrapper{
        padding: 0;
      }
    }

    .parkSelection__wrapper{
      height: 100%;
      grid-template-columns: auto auto auto auto;
      display: grid;
      grid-gap: 20px;
      padding: 25px;
      //border: 1px solid $border-light;
      border-radius: 9px;

      .park__item{
        margin: 0;
        width: 100%;
        height: 100%;
        justify-content: center;
        flex-direction: column;

        .park__thumbnail{
          width: 130px;
          height: 130px;
        }

        .park__name{
          font-size: 14px;
          line-height: 23px;
        }

        .o-button{
          border-radius: 4px;
          width: 130px;
        }
      }
    }

    .reservation-system__wrapper{
      //flex-wrap: wrap; tu
      justify-content: space-around;

      .entry-option__wrapper{
        //width: 40%; tu

        .o-label-checkbox{
          text-decoration: none;
        }

      }

      .sum__wrapper{
        display: none;
      }

      .section__wrapper:nth-child(4){
        width: 100%;
        justify-content: center;
      }
      //

      .person-number__wrapper{
        margin-top: 40px;
        margin-bottom: 0;
      }

      .time-table__wrapper{
        margin-top: 30px;
      }

      .adds__wrapper{
        width: 100%;
        max-width: 650px;

        @media(min-width: $mobile+1) and (max-width: $desktop){
          max-width: 675px;
        }
      }

      .section__wrapper:nth-child(2){
        width: 50%;
      }
      .section__wrapper:nth-child(3){

        .section__title{
          padding-left: 0;
        }

        .adds__wrapper{
          width: 75%;
        }
      }

    }

    .loginEtap__wrapper{
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      border-radius: 9px;
      margin: 0 20px;

      .sum__wrapper{
        display: none;
      }

      .form__header__wrapper {
        margin-bottom: 30px;

        .form__header {
          font-size: 16px;
          line-height: 25px;
        }
      }

      .register__wrapper, .login__wrapper {
        width: 100%;
        margin: 10px 0;

        .c-input__wrapper{
          //height: 80px;
          padding-bottom: 10px;
        }
      }

      .register__wrapper{
        width: 100%;
        margin: 10px 0;
        order: 2;
        border-right: none;

        .form__header__wrapper{
          position: relative;
        }

        .mobile-password{
          flex-direction: column;

          .c-input__wrapper{
            width: 100%;
          }
        }

        .c-select__wrapper:after{
          top: 24%;
        }
      }

      .login__wrapper{
        order: 1;
      }

      .person-info__wrapper{
        margin: auto;

        .c-select__wrapper:after{
          top: 31%;
        }
        .c-checkbox-wrapper{
          .o-label-checkbox{
            text-decoration: none;
          }
          .error{
            padding-left: 25px;
          }
        }
      }

      .sum__wrapper{
        order: 3;
      }
    }

    .paymentMethod__wrapper{
      .sum__wrapper{
        display: none;
      }
    }

    .endOfReservation{
      flex-direction: column;
      justify-content: flex-start;
      margin: 0 20px;
      height: 100%;
      //border: 1px solid $border-light;
      border-radius: 9px;

      .sum__wrapper{
        display: none;
      }

      .title{
        font-size: 16px;
        line-height: 28px;
        font-weight: 400;
        padding: 6px;
      }
      .info{
        font-size: 11px;
        line-height: 10px;
        margin-bottom: 20px;

      }

      .button__wrapper{
        .o-button{
          border: 0.5px solid $border-light;
          box-sizing: border-box;
          border-radius: 9px;
          font-size: 12px;
          line-height: 10px;
        }
      }
      .ticket__output{
        margin-top: 0;
      }
    }
  }
}

@media (min-width: 1000px) and (max-width: 1280px){
  .reservation-system__wrapper{
    //justify-content: center !important;

    .section__wrapper:nth-child(2){
      width: auto !important;
    }
    .section__wrapper:nth-child(3){
      //width: 65% !important; tu
    }
  }
}

@media(max-width: $desktop){
  .mobile-global{
    position: relative !important;
    top: unset !important;
    transform: unset !important;
  }
}

@media (min-width: $desktop+1){
  .desktop-global{
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
  }
}