.c-checkbox__wrapper{
    position: relative;

    &.error__wrapper{
        &::after{
            display: none;
        }
    }

    span.error{
        position: absolute;
        top: 17px;
    }


}