.c-input-file__wrapper{
    flex-wrap: wrap;

    input[type='file']{
        display: none;
    }

    .image-zoom{
        position: absolute;
        height: auto;
        width: auto;
        left: 69%;
        top: 35%;
        font-size: 20px;
    }

    .image-zoom::after{
        content: '';
        position: absolute;
        top: -10px;
        bottom: 0;
        left: -10px;
        right: 0;
        padding: 20px;
        border-radius: 50%;
        border: 1px solid #FFF;
    }

    .image-zoom p{
        color: #FFF;
        position: absolute;
        font-size: 13px;
        margin-left: 4.5px;
        margin-top: -4px;
    }
}