.o-backButton{
    background-color: transparent;
    width: 20px;
    height: 16px;
    padding: 0;

    .ico{
        width: 100%;
        height: 100%;
    }


    &:hover,
    &:focus{
        background-color: transparent;

        .ico-arrow-back{
            background-image: ico-generator(arrowBack, $main-color);
            background-image: ico-generator(arrowBack, var(--main-color));
        }
    }

    @media print {
        display: none;
    }
}