.timer{
  order: 2;
  margin-left: auto;
  display: flex;
  align-items: center;
  color: var(--font-color, $text-color);
  cursor: default;
  //background-color: #fff;
  //color: transparent;

  #countdown{
    margin-left: 4px;
    font-weight: bold;
  }

  &.color-danger{
    color: $error-color;
  }

  @media(min-width: $mobile+1) and (max-width: $desktop){
    padding-top: 19px;
    order: unset;
  }

  @media(max-width: $mobile){
    margin-left: auto;
    padding-top: 22px;
    margin-right: 13px;
    z-index: 1111;
    order: unset;
    position: fixed;
    right: 0;
  }

  @media print {
    display: none;
  }
}