.c-ulti-chart__container{

    .ulti-chart__header{
        display: flex;
        justify-content: space-between;
        align-content: center;
        padding-left: 10px;
        flex-wrap: wrap;

        .content__header{
            margin-right: 10px;
        }
    }
    .ulti-chart__legend__wrapper{
        display: flex;
        justify-content: space-around;
        font-size: remy($content-regular);
        color: $text-color;
        color:  var(--font-color, $text-color);
        flex:  0 1 65%;
        margin: 7px 0;
    }

    .ulti-chart__legend{
        display: flex;
        align-items: center;
        padding-right: 10px;
    }

    .ulti-chart__legend__color{
        width: 20px;
        height: 20px;
        border-radius: 5px;
        background-color: $main-color;
        background-color: var(--main-color, $main-color);
        margin-right: 10px;
    }



    .ulti-char__content__wrapper{
        position: relative;
        height: 100%;
        margin-top: 15px;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .ulti-char__axis{
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 0;
        top: -5px;
        height: calc(100% - 85px);
        width: 50px;
        border-right: 1px solid $text-color;
        border-right: 1px solid var(--font-color, $text-color);
        padding-right: 5px;
        background-color: $background-content;
        z-index: 500;
        padding-top: 10px;
        transition: opacity 1s ease-out;

        &__item{
            text-align: right;
            height: 10%;
            color: $text-color;
            color:  var(--font-color, $text-color);
        }
    }

    .ulti-char__background{
        display: flex;
        flex-direction: column;
        height: calc(100% - 90px);
        position: absolute;
        left: 0px;
        top: 0;
        min-width: 100%;
        z-index: 0;

        &__item{
            height: 10%;
            border-top: 1px solid rgba($text-color, .29);
        }
    }
    .ulti-char__bar__container{
        display: flex;
        align-content: flex-end;
        flex-wrap: nowrap;
        position: absolute;
        height: calc(100% - 90px);
        min-width: 100%;
        padding-left: 50px;
        border-right: 1px solid $text-color;
        border-bottom: 1px solid  $text-color;
        border-bottom: 1px solid  var(--font-color, $text-color);
        z-index: 200;
    }
    .ulti-char__bar__wrapper{
        position: relative;
        height: 100%;
        width: 30px;
        min-width: 30px;
        margin-left: 25px;
        margin-right: 25px;
    }
    .ulti-char__bar{
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 150px;
        background-color:  $text-color;
        background-color:  var(--font-color, $text-color);
        transition: .6s ease-out;
        outline: none;

        &:last-child{
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
        }
        &:first-child{
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
        }

    }
    .ulti-char__bar-text{
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        color: $text-light-color;
        font-weight: bold;
        font-size: remy($content-small);
        text-align: center;
    }

    .ulti-char__desc__wrapper{
        position: absolute;
        padding-left: 50px;
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        height: 80px;
        bottom: 10px;
        transition:  1s ease-out;
    }

    .ulti-char__desc{
        font-size: remy($content-regular);
        color: $text-color;
        color: var(--font-color, $text-color);
        width: 80px;
        transform: rotate(-45deg);
        transform-origin: left bottom;
        text-align: right;
        margin-top: 56px;

    }


    &--horizontal{
        position: relative;

        .ulti-char__content__wrapper{
            display: flex;
            overflow-x: hidden;
            overflow-y: auto;
            height: calc(100% - 33px);
        }

        .ulti-char__axis{
            width: calc(100% - 125px);
            flex-direction: row-reverse;
            height: auto;
            justify-content: space-between;
            border-top: 1px solid #685e81;
            border-right: 0;
            bottom: 0;
            top: initial;
            left: initial;
            right: 0;
        }
        .ulti-char__desc__wrapper{
            order: 1;
            height: 100%;
            min-height: 100%;
            padding-left: 0;
            flex-direction: column;
            padding-top: 10px;
            padding-bottom: 30px;

        }
        .ulti-char__desc{
            transform: rotate(0);
            width: 120px;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 30px;
            margin-top: 12px;
            margin-bottom: 12px;
        }
        .ulti-char__background{
            flex-direction: row;
            flex-wrap: wrap;
            padding-left: 125px;
            padding-top: 0;
            width: calc(100% - 125px);
            height: 100%;
        }
        .ulti-char__background__item{
            height: 100%;
            width: 20%;
            border-top: 0;
            border-right: 1px solid rgba(104,94,129,.29);
        }
        .ulti-char__bar__container{
            border-bottom: 0;
            border-left: 1px solid #685e81;
            width: calc(100% - 125px);
            min-width: auto;
            height: calc(100% - 25px);
            min-height: calc(100% - 25px);
            padding-left: 0;
            left: 125px;
            padding-bottom: 30px;
            flex-direction: column;
        }
        .ulti-char__bar__wrapper{
            height: 20px;
            width: 100%;
            margin-left: 0;
            margin-top: 9px;
            margin-bottom: 9px;
            flex: 0 0 20px;
        }
        .ulti-char__bar{
            height: 20px;
        }
    }

    &--stacked{
        .ulti-char__bar__wrapper{
            display: flex;
            flex-wrap: wrap;
            align-content: flex-end;
        }
    }

}


#mainPage-clientObjects{
    .ulti-char__content__wrapper{
        height: 400px;
    }
}

#mainPage-userCount, #entriesSource-byMonth--chart, #influence-byMonth--chart, #entries-byMonth--chart, #averageAge--chart, #firstEntry--chart, #faultNumber--chart, #faultMost--chart{
    .ulti-char__content__wrapper{
        height: 340px;
    }
}

#mainPage-logged{
    .ulti-chart__header{
        padding-left: 0;
    }
}

#mainPage-logged{
    height: 100%;
    padding-bottom: 20px;
}

#entriesSource-byMonth--chart{

    .ulti-chart__legend__wrapper{
        width: 100%;
        flex: 1 1 100%;
        justify-content: flex-start;
        margin-top: 0;
    }
}