#infoPanelList{
  @media (max-width: $mobile) {
    .filters{
      flex-wrap: unset;

      .filter__wrapper:nth-child(1){
        width: 100%;
        min-width: auto;

        .c-input-search__wrapper{
          width: 100%;
        }
      }
    }

    .grid-item {
      &--1 {
        &:before {
          content: "Nazwa artykułu";
        }
      }

      &--2 {
        &:before {
          content: "Data dodania";
        }
      }

      &--3 {
        &:before {
          content: "Data publikacji";
        }
      }

      &--4 {
        &:before {
          content: "Park";
        }
      }
    }
  }
}