.c-list{ // <- list elementów, np widok główny KLIENCI KTÓRYM KOŃCZY SIĘ ABONAMENT
    font-size: remy($content-regular);
    color: $content-header-color;

    .list-item{
        margin: 30px 0;
        &.u-flex{
            justify-content: space-between;
        }
    }
}
.c-list{
    width: 100%;
    display: block;

    &__wrapper{
        overflow-y: auto;

        @media (max-width: $mobile) {
            overflow-y: unset;
        }

        @media (min-width: $mobile+1) and (max-width: $desktop) {
            border: 1px solid $border-light;
            //margin: 10px;
            border-radius: 9px;
            background-color: $background-content;
            box-shadow: $light-box-shadow;

        }

    }

    .c-content{
        padding: 5px 0;

        @media(max-width: $desktop){
            padding: 0;
        }
    }

    &__header{
        border-bottom: 1px solid #B4AFC1;
        padding: 5px 30px;
        padding-bottom: 25px;

        &-info{
            font-size: remy($content-small);
            color: $text-color;
            color: var(--font-color, $text-color);
            font-weight: 500;
            margin-bottom: 11px;

            @media(max-width: $mobile){
                margin-bottom: 0;
            }
        }

        &-options__wrapper{
            display: flex;
            justify-content: space-between;
            align-items: center;

            .o-label{
                font-size: remy($content-regular);
                color: $text-color;
                color:  var(--font-color, $text-color);
            }

            @media(max-width: $mobile){
                .button__wrapper {
                    button[data-option="add"] {
                        z-index: 1;
                        position: fixed;
                        bottom: 20px;
                        right: 20px;
                        border-radius: 50%;
                        max-width: 45px;
                        max-height: 45px;
                        min-width: 45px;
                        min-height: 45px;
                        color: transparent;
                        z-index: 111;

                        .ico-plus{
                            margin-left: 5px;
                            margin-top: 3px;
                            font-size: 15px;
                        }
                    }
                }
            }
        }

        .c-input__wrapper{
            padding:  0;
            margin-bottom: 0;

        }
        .c-input-calendar__wrapper{
            &::after{
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .c-select__wrapper{
            display: flex;
            align-items: center;

            &::after{
                top: 44%;
                //transform: translateY(-50%);
            }

            .o-input__list{
                width: 180px;
            }
            .c-select__list__wrapper{
                width: 218px;
                min-width: auto;
                left: 100%;
                transform: translateX(-100%);
            }
            @media only screen and (max-width: 1600px) {
                .o-input__list{
                  //width: 150px;
                    width: 100%;
                }
                .c-select__list__wrapper{
                  width: 150px;
                }
            }

        }

        .o-input-search{
            width: 300px;
            //@media(max-width: 1366px){
            //    width: 240px;
            //}
            @media (max-width: $desktop){
                width: 100%;
            }
        }

        .o-button{
            margin-left: 20px;


        }

        .button__wrapper{

          .o-button{
              min-width: 150px;
              margin: 10px 0;
              white-space: nowrap;
              margin-left: 10px;

              @media (min-width: $mobile) and (max-width: $desktop){
                min-width: 100px;
              }
          }

          @media (min-width: $mobile) and (max-width: $desktop){
            display: flex;
            align-items: center;
          }
        }

        @media (max-width: $mobile){
          background-color: $background-menu;
        }

        @media (max-width: $desktop-l){
            padding: 10px 20px;
        }

        @media (min-width: $mobile+1) and (max-width: $desktop){
            margin-top: 10px;
        }


    }

    &__filters__wrapper{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex: 1 1 auto;

        .filters{
            width: 100%;
            display: flex;
            //flex-wrap: wrap;

            .filter__wrapper{
                //width: 24%;
                min-width: 275px;
                display: flex;
                align-items: center;

                @media (max-width: 1800px){
                    //width: 26%;
                }
                @media (max-width: $mobile){
                    margin-left: 0;
                }


            }
            .c-input__wrapper{
                margin: 10px 0;
                display: flex;
                align-items: center;
            }
            .o-label{
                width: 115px;
                text-align: right;
                padding-right: 10px;

                &[for="accept-zone"]{
                    white-space: nowrap;
                }
            }


            @media(max-width: $desktop) {
                .filter__button {
                    border-radius: 4px;
                    padding: 4px 10px;
                    background-color: $main-color;
                    margin: 0 10px;

                    &.active{
                      background-color: lighten($main-color , 10%);
                    }

                    .button--text {
                        font-size: 14px;
                        line-height: 21px;
                        color: $text-light-color;
                        margin: 0 10px;
                    }
                }
            }

            @media(max-width: $desktop){
                //justify-content: space-between;
                align-items: center;
            }
        }

        .c-input__wrapper{
            margin: 10px 15px;
        }

        .c-checkbox-wrapper{
            width: auto !important;
        }

        .c-input-search__wrapper{
            @media(max-width: $mobile){
                width: 100%;
            }
        }


    }


    &__content{

        &-header{
            padding: 10px 30px 10px 20px;

            .option-title{
                display: inline-block;
                font-weight: 500;
                font-size: remy($content-small);
                position: relative;
                color: $text-color;
                cursor: default;
            }

            .o-backButton{
                margin-left: 30px;
                .ico:before {
                    content: $icon-arrowBack;
                    color: $text-color;
                }
            }
            @media(min-width:$mobile) and (max-width: $desktop){
                padding-right: 10px;
            }
        }

        &__wrapper{
            overflow: hidden;
            min-height: 250px;

            .o-label-checkbox{
                height: 100%;
                padding-left: 0;

                &::before{
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            .o-input-checkbox{
                margin: 0;
            }
            .grid-item__options__wrapper{
                width: 145px;
                display: flex;
                justify-content: center;
            }

            @media(max-width: $desktop){
                overflow: unset;
            }

            @media(min-width:$desktop) and (max-width: $desktop-l){
                //padding-left: 20px;
            }
        }

        &-item{
            padding-top: 20px;
            padding-right: 30px;
            padding-left: 20px;
            max-height: 55px;
            position: relative;
            //transition: .2s ease-out, opacity .4s ease-out, transform .4s ease-out;

            &:hover{
                background-color: #FAFAFA;
            }
            &.is-active{
                background-color: #EDE9FD;

                @media(max-width: $desktop){
                    background-color: $background-content;
                }
            }
            &.is-open{
              .o-button--show-more .ico{
                  transform: rotate(0deg);
              }
            }
            &.is-disabled{
                //background-color: rgba(#FAFAFA, .5);
                background-color: #f2f3f4;
                .c-list__grid-item{
                    opacity: 0.3;
                }
                .c-content-item-options__wrapper{
                    display: none;
                }

            }
            &.is-unread{
                background-color: $background-unread;
            }
            .c-list__grid-item{
                padding-bottom: 20px;
                border-bottom: 1px solid #D8D6DE;

                .ico-warning, .ico-warning-orange{
                    width: 14px;
                    height: 14px;
                    margin: 0 5px;
                }
            }

            .content-title{
                color: $content-header-color;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: default;

                &[data-sortitem="email"]{
                    cursor: pointer;
                }

                p{
                    color: $content-header-color;
                }
            }

            .o-button--option{
                position: relative;

                .ico{
                    margin-right: 0;
                    width: 14px;
                    height: 14px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    &:before {
                        width: 100%;
                        font-size: 14px;
                    }
                }
                .ico{
                    &:before {
                        color: $text-color;
                        color: var(--font-color, $text-color);
                    }
                }
                &:hover, &:focus{
                    .ico{
                        &:before {
                            color: white;
                            color: var(--menu-color, white);
                        }
                    }



                }
            }

            .o-button--show-more{
                //margin-left: -17px;
                margin-left: 4px;
                margin-right: 4px;

            }

            .o-avatar{
                margin-right: 10px;
                width: 35px;
                height: 35px;
            }

            &__wrapper{
                &:last-child{
                    .c-list__grid-item{
                        border: none;
                    }
                }
            }
            @media(min-width: $mobile) and (max-width: $desktop){
                padding-right: 10px;
            }
        }
    }

    .c-list__content-item__wrapper{
        &.lvl2{
            display: none;
            .c-list__content-item{
                margin-left: 2%;
            }

            .grid-item--1{
                width: 23.46%;
            }

            .c-list__grid--col-seven{
                .grid-item--1{
                }
            }
        }
        &.lvl3{
            display: none;
            .c-list__content-item{
                margin-left: 4%;
                border-bottom: 1px solid transparent;
                margin-right: 30px;
                padding-right: 0;


                &.is-open{
                    border-bottom: 1px solid #d8d6de;
                }
            }

            .grid-item--1{
                width: 21.87%;
            }

            .c-list__grid-item {
                border-bottom: 0;
            }
        }
        &.lvl4{
            display: none;
            .c-list__content-item{
                margin-left: 6%;
                border-bottom: 1px solid transparent;

                &.is-open{
                    border-bottom: 1px solid #d8d6de;
                }
            }
            .lvl2{
                .c-list__content-item{
                    margin-left: 8%;
                }
            }

            .grid-item--1{
                width: 20.21%;
                flex: 0 0 20.21%;
            }
            .c-list__grid--col-four{
                .grid-item--2{
                    width: 36%;
                }
            }

            .c-list__grid-item {
                border-bottom: 0;
            }

            .c-list__grid--col-eight{
                .grid-item--3{
                    flex-grow: 0;
                    width: 10%;
                }
                .grid-item--4{
                    flex-grow: 0;
                    flex-shrink: 1;
                    flex-basis: 10%;
                    width: 10%;
                    min-width: 30px;

                }
                .grid-item--6{
                    flex-grow: 1;
                    flex: 1 1 30%;
                }
                .grid-item--8{
                    min-width: 110px;
                    width: 10%;
                    flex-basis: 10%;
                }
            }
        }

        &.lvl5{
            display: none;
            .c-list__content-item{
                margin-left: 8%;
            }

            .grid-item--1{
                width: 18.47%;
                flex: 0 0 18.47%;
            }
            .c-list__grid--col-three{
                .grid-item--2{
                    width: 36%;
                }
            }

            .c-list__grid-item {
                border-bottom: 0;
            }
        }


    }

    .confirmRemove{
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        align-items: center;

        .o-button{
            margin: 0 10px;

            &--light{
                background-color: white;

                &:hover{
                    background-color: $main-color;
                    background-color: var(--main-color, $main-color);
                }
            }
            &--danger{
                margin-right: 155px;
            }
        }
    }
    .confirmRemove-mobile{
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: $button-delete-mobile !important;
        border-radius: 0 0 9px 9px;
        min-height: 36px;

        .o-button{
            width: 50%;
            font-weight: 500;

                &--light{
                    border: none;
                    border-right: 1px solid $border-light;
                    border-radius: 0 0 0 20px;
                    color: $button-light-text;

                    &:active, &:hover, &:focus{
                        background-color: transparent;
                    }
                }

                &--danger{
                    background-color: $button-delete-mobile;
                    color: $button-delete-text-mobile;
                    border-radius: 0 0 20px 0;
                }

        }

    }

    .aside-button{
        cursor: pointer;
    }

    .c-checkbox-wrapper{
        width: 14px;
        height: 14px;
        margin-right: 25px;
        display: none;
    }

    .c-thumbnail__wrapper{
        margin: 0;
        margin-right: 7px;
    }
}

