.o-input {
	border: 0.5px solid rgba($text-color, .6);
	border-radius: 4px;
	padding: 8px 10px;
	font-size: remy($login-placeholder);
	color: $main-color;
	color: var(--main-color, $main-color);
	font-weight: 500;
	font-family: Roboto;

	&::placeholder{
		color: $placeholder-color;
	}

	&:active,
	&:focus,
	&:hover {
		border-color: $main-color;
		border-color: var(--main-color, $main-color);
		outline: none;
		cursor: pointer;
	}
	&__login {
		padding: 11px 10px;
		background-color: transparent;
		z-index: 20;
		position: relative;

		&:active,
		&:focus {
			+ .o-label {
				color: $main-color;
				color: var(--main-color, $main-color);
				font-size: remy($login-placeholder--active);
				top: 0;
				transform: translateY(0);
				background-color: white;
				padding: 1px 2px;
				z-index: 100;
			}
		}
	}

	&:-webkit-autofill {
		-webkit-box-shadow: 0 0 0 30px white inset;
	}
	&:-webkit-autofill {
		-webkit-text-fill-color: $main-color !important;
		-webkit-text-fill-color: var(--main-color, $main-color) !important;
	}

	&.error{
		border-color: $error-color;
	}

	&.flatpickr-input, &.o-input--range{
		z-index: 111;
		background-color: transparent;

		@media(min-width: $mobile+1) and (max-width: $desktop-l){
			max-width: 150px;
		}
	}

}
.o-label {
	font-size: remy($login-placeholder);
	cursor: pointer;

	&__login {
		position: absolute;
		left: 10px;
		top:18px;
		transition: 0.2s ease-out;
		color: $placeholder-color;
		z-index: 1;

		&.is-active {
			color: $main-color;
			color: var(--main-color, $main-color);
			font-size: remy($login-placeholder--active);
			top: 0;
			background-color: white;
			padding: 1px 2px;
			z-index: 100;
		}
	}
}

span.error{
	display: inline-block;
	padding-top: 5px;
}

.error__wrapper{
	&::after{
		position: absolute;
		content: "";
		display: block;
		top: 15px;
		right: 10px;
		width: 17px;
		height: 17px;
		background-image: url(../images/warning-ico.svg);
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}
}
.ui-float-label>input:focus~label,
.ui-float-label>input.ui-state-filled~label,
.ui-float-label>.ui-inputwrapper-focus~label,
.ui-float-label>.ui-inputwrapper-filled~label {
	top: -.75em;
	font-size: 12px;
}