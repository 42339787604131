body,html{
    font-family: $main-font;
    font-size: 16px;

    @media (max-width: 1600px) {
        font-size: 15px;
    }
}





/* Main menu */
.main__menu__item{
    font-size: remy($main-menu-regular);
}

/* Main content */
