
/* login page */
.l-login-page{
    padding: 40px 5px;
    color: $text-color;
    color: var(--font-color, $text-color);

    .main-logo__wrapper{
        width: 193px;
        height: 55px;
        margin: 0 auto;
    }

    .c-content__wrapper{
        max-width: 620px;
        padding: 58px 20px;
        padding-bottom: 31px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin: 46px 0;
        flex-wrap: wrap;

        @media(max-width: $mobile){
            padding: 17px 18px;
            padding-bottom: 0;
            margin: 15px 0;
        }
    }

    .o-button-anchor{
        font-size: remy($login-button-anchor);
    }

    &__header{
        font-size: remy($login-header);
        width: 100%;
        text-align: center;
        margin-bottom: 20px;

        &--small{
            width: 100%;
            text-align: center;
        }
    }

    .auto-logout{
        border-radius: 9px;
        max-width: 620px;
        margin: 0 auto;
        text-align: center;
        padding: 20px;
        background-color: $background-menu;
        color: $main-color;
        margin-top: 46px;
    }

    @media(max-width: $mobile){
        padding: 20px 5px;
    }
}

.login-form{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 370px;
    margin-top: 41px;
    margin-bottom: 27px;
    z-index: 200;

    .o-input__login{
        display: block;
        width: 100%;
    }
    .o-button{
        width: 100%;
        margin-top: 27px;
        padding: 11px 30px;
    }
    &__additional{
        justify-content: space-between;
        align-items: center;
    }

    p.error, p.success{
        padding: 5px 0;
    }

    p.success{
        font-weight: 500;
    }
}

.login__rigister-wrapper{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 230px;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;

    .o-button{
        padding: 7px 10px;
    }
}


/* Lost pass */
.last-pass__additional__wrapper{
    position: relative;
    width: 370px;
    left: 50%;
    transform: translateX(-50%);
    justify-content: flex-end;
}
.l-login-page--lost-pass{
    .o-button{
        margin-top: 0;
    }
}

/* Register */

.l-login-page--register{
    .c-input-group{
        justify-content: space-between;
        .c-input__wrapper{
            width: 48%;
        }
    }

    .input-date__wrapper{
        flex-wrap: wrap;
        .group-title{
            width: 100%;
            margin-bottom: 18px;
        }

        .c-input__wrapper{
            width: 31%;
        }

        .o-input__login{
            padding: 7px 10px;
        }
    }
}