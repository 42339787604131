.c-person-settings{
    margin: 0 13px;
    position: relative;
    z-index: 600;

    .person-name{
        font-size: remy($content-bigger);
        font-weight: 500;
        margin-right: 5px;
        color: var(--font-color, $text-color);
    }
    .ico-arrow{
        width: 10px;
        height: 10px;
        &::before{
            width: 10px;
            height: 10px;
            transition: transform .3s ease-in-out;
            font-size: 9px;
        }

    }

    &.is-active{
        .ico-arrow{
            &::before{
                transform: rotate(180deg);
            }

        }
    }

    .o-dropdown__wrapper{
        padding: 7px 0;
        margin-top: 11px;
        width: auto;
        right: 0;

        li{
            padding: 0 9px;
        }
        a{
            display: inline-flex;
            align-items: center;
            margin: 5px 0;
            font-size: remy($button-regular);
            padding: 5px 0;
        }
        .ico{
            width: 17px;
            height: 17px;
            margin-right: 10px;
            font-size: 17px;
        }
    }

}